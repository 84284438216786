import dayjs from 'dayjs';

export const getDays = (): { value: string; label: string }[] => {
  const days: { value: string; label: string }[] = [{ value: '01', label: '01' }];
  for (let i = 2; i < 32; i += 1) {
    if (i < 10) {
      days.push({ value: `0${i.toString()}`, label: `0${i.toString()}` });
    } else {
      days.push({ value: i.toString(), label: i.toString() });
    }
  }
  return days;
};

export const getMonths = (): { value: string; label: string }[] => {
  const months: { value: string; label: string }[] = [{ value: '01', label: '01' }];
  for (let i = 2; i < 13; i += 1) {
    if (i < 10) {
      months.push({ value: `0${i.toString()}`, label: `0${i.toString()}` });
    } else {
      months.push({ value: i.toString(), label: i.toString() });
    }
  }
  return months;
};

export const getYears = (): { value: string; label: string }[] => {
  const years: { value: string; label: string }[] = [
    {
      value: dayjs().year().toString(),
      label: dayjs().year().toString()
    }
  ];
  for (let i = dayjs().year() - 1; i >= 1900; i -= 1) {
    years.push({ value: i.toString(), label: i.toString() });
  }
  return years;
};
