import * as ReactHabitat from 'react-habitat';
import IbeCheckoutWidget from '../widgets/IbeCheckoutWidget';

class Habitat extends ReactHabitat.Bootstrapper {
  constructor() {
    super();

    const builder = new ReactHabitat.ContainerBuilder();
    builder.register(IbeCheckoutWidget).as('CmbIbeCheckout');
    this.setContainer(builder.build());
  }
}

export default new Habitat();
