import {
  appService,
  authService,
  initBookingService,
  SessionData,
  Tenant,
  useBookingService
} from '@ibe/components';
import {
  ConfigModel,
  ConfigService,
  defaultConfig,
  fetchJSON,
  LoggerFactory,
  SessionPersistence
} from '@ibe/services';
import { ApiGender, ApiPaymentOption, ApiUpdateAffiliateRequest } from '@ibe/api';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import dayjs from 'dayjs';
import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import localeDe from 'dayjs/locale/de';
import localeEn from 'dayjs/locale/en-gb';
import localeData from 'dayjs/plugin/localeData';
import trackingSubscriptions from '../Tracking/trackingSubscriptions';
import { Language } from '../Translations';
import PageUrl from '../Models/PageUrl';
import PaymentService from '../Services/PaymentService';
import { createContext } from 'react';
import NewsletterService from '../Services/NewsletterService';
import ApiService from '../Services/ApiService';
import { CmbConfigModel } from './CmbConfigModel';
import TrackingService from '../Tracking/TrackingService';
import i18next from 'i18next';

// ATTENTION - there is a useConfig() hook - you probably do not need to import anything from here :-)

dayjs.extend(localeData);

const logger = LoggerFactory.get('config');
const customConfig: Partial<ConfigModel> = {
  defaultLanguage: 'de',
  defaultCurrency: 'EUR',
  apiUrl: '/api',
  configUrl: '/config.json',
  displayFormatDate: {
    de_DE: 'DD.MM.YYYY',
    'de-DE': 'DD.MM.YYYY',
    en_EN: 'DD/MM/YYYY',
    'en-EN': 'DD/MM/YYYY'
  },
  displayFormatDateTime: {
    de_DE: 'DD.MM.YYYY HH:mm',
    'de-DE': 'DD.MM.YYYY HH:mm',
    en_EN: 'DD/MM/YYYY HH:mm',
    'en-EN': 'DD/MM/YYYY HH:mm'
  },
  theme: 'DER',
  session: {
    expirationTimeNotificationOffset: 5
  },
  traveler: {
    genderValues: [ApiGender.FEMALE, ApiGender.MALE],
    maxChildAge: 11,
    maxInfantAge: 1,
    mockAvailable: false
  },
  modalContainerClass: 'cmb'
};

export const insuranceOfferCountries = ['BE', 'DK', 'DE', 'FR', 'IT', 'LX', 'NL', 'AT', 'PL', 'CZ'];

export const NO_SUPPLIER_COMPARISON_COUNTRY_CODES = ['DE', 'GB', 'IE', 'NA'];

export const CMP_SERVICES = [
  { id: 'S1pcEj_jZX', name: 'Google Maps' },
  { id: 'BJz7qNsdj-7', name: 'YouTube Video' }
];

export const CMP_SERVICES_CUSTOM = [
  { id: 'Google Maps', name: 'Google Maps' },
  { id: 'YouTube Video', name: 'YouTube Video' }
];

export const getCmpServices = (useCustomCMP?: boolean): { id: string; name: string }[] => {
  return useCustomCMP ? CMP_SERVICES_CUSTOM : CMP_SERVICES;
};

export const YOUTUBE_NOCOOKIE_HOST = 'https://www.youtube-nocookie.com';

export const configService = new ConfigService<ConfigModel>(ConfigModel, defaultConfig);
configService.mergeIntoConfig(customConfig);

function initLanguage(tenant: Tenant | undefined): void {
  switch (tenant) {
    case Tenant.MB: {
      registerLocale('en-EN', enGB);
      appService.setLang(Language.EN_EN);
      break;
    }
    default: {
      registerLocale('de-DE', de);
      appService.setLang(Language.DE_DE);
      break;
    }
  }

  // now set dayjs and datepicker without region
  const langOnly = appService.lang.split('-')[0];
  dayjs.locale(langOnly === 'de' ? localeDe : localeEn);

  setDefaultLocale(langOnly);
  i18next.changeLanguage(langOnly);
}

export const extendedApiService = new ApiService(authService, appService);
export const extendedApiContext = createContext<ApiService>(extendedApiService);
export const paymentService = new PaymentService(authService, appService);
export const paymentContext = createContext<PaymentService>(paymentService);
export const newsletterService = new NewsletterService(authService, appService);
export const newsletterContext = createContext<NewsletterService>(newsletterService);
export const extendedConfigContext = createContext<CmbConfigModel>({
  payoneUrl: 'https://test.soap.bs-card-service.com/web-ifs/assets/1.1/integration.js',
  tenant: Tenant.CB,
  insuranceOfferCountries: insuranceOfferCountries,
  isSDPLoaded: false
});
export const trackingService = new TrackingService(appService);
export const trackingContext = createContext<TrackingService>(trackingService);

export const paymentExcludedCurrencies: { [paymentOption: string]: string[] } = {
  [ApiPaymentOption.DIRECTDEBIT]: ['CHF']
};

export const boot = async (
  configOverrides?: Partial<ConfigModel>,
  skipInitBooking?: boolean,
  cmbConfig?: CmbConfigModel,
  subscribeToTrackingEvents?: boolean,
  affiliateNumber?: string
): Promise<void> => {
  const bookingService = useBookingService();
  if (subscribeToTrackingEvents) {
    logger.log('TrackingService => trackingSubscriptions initialized');
    trackingSubscriptions();
  }
  if (configOverrides) {
    configService.mergeIntoConfig(configOverrides);
  }
  const url = configService.get().configUrl;
  if (url) {
    const externalConfig = (await fetchJSON(url)) as Partial<ConfigModel>;
    configService.mergeIntoConfig(externalConfig);
  }
  if (cmbConfig) {
    configService.mergeIntoConfig(cmbConfig);
  }
  const config = configService.get();
  logger.log('booting...');
  await appService.hydrate(config);

  initLanguage(config.tenant as Tenant);

  if (cmbConfig && cmbConfig.currency) {
    await appService.setCurrency(cmbConfig.currency);
  }
  if (cmbConfig && cmbConfig.tenant) {
    await appService.setTenant(cmbConfig.tenant);
  }

  if (affiliateNumber) {
    await appService.setAffiliate(affiliateNumber);
  }

  if (!window.location.pathname.includes(PageUrl.CHECKOUT)) {
    const session = new SessionPersistence<SessionData>(SessionData);
    session.useKey(config.sessionKeyCart);
    session.clear();
  }
  if (!skipInitBooking) {
    await initBookingService(config, appService.api);
    if (
      affiliateNumber &&
      bookingService.booking &&
      bookingService.booking.affiliateNumber !== affiliateNumber
    ) {
      await bookingService.updateAffiliate({
        affiliateNumber: affiliateNumber
      } as ApiUpdateAffiliateRequest);
    }
  }
  logger.log('config', config);
  logger.log('App hydrated');
};
