import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button, Col, Collapse, Row } from 'reactstrap';
import dayjs from 'dayjs';
import {
  ApiBaseData,
  ApiClientType,
  ApiCustomerToJSON,
  ApiGender,
  ApiPaymentOption
} from '@ibe/api';
import {
  AcceptableTermsAndConditionsList,
  CamperSummary,
  CheckoutAddressBuilder,
  CollapseHeadline,
  ContentContainer,
  CustomerBuilderRef,
  FixedElementScroll,
  LoadingOverlay,
  PriceDetails,
  useConfig,
  useTranslation,
  CamperInsurance,
  InsuranceItem,
  CamperInsurances,
  useFooterPadding
} from '@ibe/components';
import { scroller } from 'react-scroll';
import useFormConfig, { getCustomerMockData } from '../../../Config/useCustomerFormConfig';
import { CheckoutStepComponentProps } from '../../../Models/CheckoutStep';
import ProgressBar from './ProgressBar';
import fallback from '../../../Translations/generated/customer.de.json';
import builderFallback from '../../../Translations/generated/traveller-builder.de.json';
import cif from '../../../Translations/generated/camper-insurance.de.json';
import Keys from '../../../Translations/generated/customer.de.json.keys';
import fallbackConsent from '../../../Translations/generated/cmp-consent-layer.de.json';
import KeysConsent from '../../../Translations/generated/cmp-consent-layer.de.json.keys';
import CheckoutPageUrl from '../CheckoutPageUrl';
import PaymentSection, { Payment } from './payment/Payment';
import ErrorDisplay from '../../../Components/ErrorDisplay';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import terms from './payment/terms';
import { withCamperInsurances } from '../hooks/withCamperInsurances';
import {
  CamperInsuranceData,
  CamperInsuranceUnit
} from '../../../Models/insurance/CamperInsuranceData';
import { useMount } from 'react-use';
import CamperLoading from '../../../Components/CamperLoading';
import { routeBookingEvent, sanitize } from '@ibe/services';
import getCountries from '../../../utils/getCountries';
import CaptureFlightData from './captureFlightData/CaptureFlightData';
import { CaptureFlightDataRef } from './captureFlightData/types';
import useAssetPath from '../../../Hooks/useAssetPath';
import { getCmpServices, YOUTUBE_NOCOOKIE_HOST } from '../../../Config/config';
import useCmbConfig from '../../../Hooks/useCmbConfig';
import useCMPConsentContext from '../hooks/useCMPConsent';
import CMPConsentLayer from '../../../Components/CMPConsentLayer';

const validatePayment = (payment: Payment): boolean | undefined => {
  if (!payment) {
    return false;
  }
  return (
    payment.paymentOption === ApiPaymentOption.INVOICE ||
    (payment.paymentOption === ApiPaymentOption.CREDITCARD && !!payment.ccToken) ||
    (payment.paymentOption === ApiPaymentOption.DIRECTDEBIT &&
      !!payment.iban &&
      !!payment.holder &&
      payment.isDirectDebitBoxChecked)
  );
};

const getAge = (date: string): number => {
  const birthDate = new Date(date);
  const monthDifference = Date.now() - birthDate.getTime();
  const ageDateTime = new Date(monthDifference);
  const year = ageDateTime.getUTCFullYear();
  return Math.abs(year - 1970);
};

const getAgeOnGivenDate = (birthDateStr: string, startDateStr: string | undefined): number => {
  if (!startDateStr) {
    return -1;
  }
  const birthDate = new Date(birthDateStr);
  const startDate = new Date(startDateStr);
  const difference = startDate.getTime() - birthDate.getTime();
  const ageDateTime = new Date(difference);
  const year = ageDateTime.getUTCFullYear();
  return Math.abs(year - 1970);
};

const newInvoiceRecipientCode = 'new-invoice-recipient';

const defaultPayment = {
  paymentOption: ApiPaymentOption.CREDITCARD,
  ccToken: null
} as Payment;

const Customer = observer(function Customer(props: CheckoutStepComponentProps): JSX.Element {
  const { store, tcsUrl, useCustomCMP } = props;
  const { t } = useTranslation('customer', fallback);
  const cit = useTranslation('camper-insurance', cif);
  const { t: builderT } = useTranslation('traveller-builder', builderFallback);
  const { t: consentT } = useTranslation('cmp-consent-layer', fallbackConsent);
  const config = useConfig();
  const cmbConfig = useCmbConfig();
  const cmpConsent = useCMPConsentContext();
  const googleMapsService = getCmpServices(useCustomCMP).find(
    service => service.name === 'Google Maps'
  );
  const youtubeService = getCmpServices(useCustomCMP).find(
    service => service.name === 'YouTube Video'
  );
  const history = useHistory();

  const [payment, setPayment] = useState<Payment>({ ...defaultPayment });
  const [initialValues, setInitialValues] = useState<Record<string, unknown> | undefined>(
    undefined
  );
  const builderRef = useRef<CustomerBuilderRef<Record<string, string>>>(null);
  const [tcsAccepted, setTcsAccepted] = useState<boolean>(false);
  const [insuranceTermsAccepted, setInsuranceTermsAccepted] = useState(false);
  const [countryCode, setCountryCode] = useState<string>('');
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isNewInvoiceRecipient, setIsNewInvoiceRecipient] = useState<boolean>(false);
  const [insurances, setInsurances] = useState<Array<CamperInsuranceData> | undefined>([]);
  const [selectedInsuranceUnits, setSelectedInsuranceUnits] = useState<
    CamperInsuranceUnit[] | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const captureFlightDataRef = useRef<CaptureFlightDataRef>(null);
  const hasCaptureData = !!store?.booking?.captureData && store.booking.captureData.length > 0;
  const customerField = useRef<string>('');
  const emailField = useRef<string>('');
  const minDriverAge = store?.camper?.minDriverAge || config.traveler.maxChildAge + 1;
  const assetBasePath = useAssetPath();

  useFooterPadding({
    identifierClass: 'footer',
    footerClass: 'padding-bottom'
  });

  useMount(() => {
    routeBookingEvent.broadcast({
      pathname: CheckoutPageUrl.CUSTOMER,
      search: '',
      booking: store.booking || undefined
    });
  });

  useEffect(() => {
    (async () => {
      if (!!countryCode) {
        setInsurances(undefined);
        const ins = await store.getInsurances(countryCode);
        if (ins) {
          setInsurances(ins);
        }
        setPayment({ ...defaultPayment });
      }
    })();
  }, [countryCode]);

  useEffect(() => {
    if (!insurances || insurances.length === 0) {
      setSelectedInsuranceUnits([]);
    }
  }, [insurances]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onCheckoutAddressChange = (values: Record<string, string>): void => {
    setCountryCode(values.country);
    if (values.customer !== customerField.current) {
      customerField.current = values.customer;
      setIsNewInvoiceRecipient(values.customer === newInvoiceRecipientCode);
    }
    if (values.email !== emailField.current) {
      emailField.current = values.email;
      if (!!values.confirmEmail && values.confirmEmail.length > 0)
        builderRef?.current?.triggerFieldValidation('confirmEmail');
    }
  };

  useEffect(() => {
    if (store.customer && store.customer.address) {
      setInitialValues({
        id: store.customer.id,
        customer: customerField.current,
        gender: store.customer.gender,
        firstname: store.customer.firstName,
        lastname: store.customer.lastName,
        birthDate: store.customer.birthDate,
        street: store.customer.address.street,
        houseNumber: store.customer.address.houseNumber,
        city: store.customer.address.city,
        zipCode: store.customer.address.postalCode,
        country: store.customer.address.countryCode,
        phone: store.customer.communicationDetails.phone,
        email: store.customer.communicationDetails.email,
        confirmEmail: store.customer.communicationDetails.email
      });
    }
  }, [store.customer]);

  const scrollToFirstError = (): void => {
    const firstError = document.querySelectorAll('.form__error__top')[0];
    if (firstError) {
      firstError.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (!tcsAccepted && isDirty) {
      setTimeout(() => {
        scrollToFirstError();
      }, 500);
    }
  }, [tcsAccepted, isDirty, payment]);

  const scrollToError = (): void => {
    scroller.scrollTo(store.scrollElementGlobalError, {
      smooth: true,
      delay: 100,
      offset: -200
    });
  };

  const handleSubmit = async (): Promise<void> => {
    store.setError('');
    setIsDirty(true);
    if (builderRef.current) {
      setIsLoading(true);
      const value = await builderRef.current.handleSubmit();
      const customerTraveller = store.travellers.find(
        traveller => traveller.id === value?.customer
      );
      const capturedData = captureFlightDataRef.current?.getState();

      if (
        !value ||
        (!!value && Object.keys(value).length === 0) ||
        (!isNewInvoiceRecipient && !customerTraveller) ||
        !validatePayment(payment) ||
        !tcsAccepted ||
        (selectedInsuranceUnits && selectedInsuranceUnits.length > 0 && !insuranceTermsAccepted) ||
        (hasCaptureData && capturedData?.hasErrors)
      ) {
        setIsLoading(false);
        scrollToFirstError();
        return;
      }
      const customer = ApiCustomerToJSON({
        id: !!customerTraveller ? customerTraveller.id : Math.random().toFixed(16).split('.')[1],
        salutation: !!customerTraveller ? customerTraveller.gender : (value.gender as ApiGender),
        firstName: !!customerTraveller ? customerTraveller.firstName : value.firstname,
        lastName: !!customerTraveller ? customerTraveller.lastName : value.lastname,
        title: !!customerTraveller ? customerTraveller.title : '',
        birthDate: !!customerTraveller ? customerTraveller.birthDate : value.birthDate,
        age: !!customerTraveller ? customerTraveller.age : getAge(value.birthDate),
        documentNumber: !!customerTraveller ? customerTraveller.documentNumber : '',
        gender: !!customerTraveller ? customerTraveller.gender : (value.gender as ApiGender),
        type: ApiClientType.Customer,
        communicationDetails: {
          phone: value.phone,
          email: value.email
        },
        address: {
          street: value.street,
          houseNumber: value.houseNumber,
          city: value.city,
          postalCode: value.zipCode,
          countryCode: value.country
        }
      });
      store.setCustomer(customer);

      try {
        await store.addPaymentData(payment);
        if (selectedInsuranceUnits && selectedInsuranceUnits.length > 0) {
          await store.addInsuranceToBooking(selectedInsuranceUnits);
        }
        if (hasCaptureData && !!capturedData?.data) {
          await store.updateCaptureData(capturedData.data, store?.booking?.id);
        }
        await store.book();
        if (store.error) {
          scrollToError();
          await store.doInitPayment();
        } else {
          history.push(CheckoutPageUrl.CONFIRMATION);
        }
      } catch {
        store.setError('bookingAttemptFailed');
        await store.doInitPayment();
        scrollToError();
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getTravellersForSelect = (): ApiBaseData[] => {
    return [
      ...store.travellers
        ?.filter(traveller => {
          const birthDateAdded = dayjs(traveller.birthDate).add(
            config.traveler.maxChildAge + 1,
            'year'
          );
          return birthDateAdded.isBefore(dayjs(store.camper.pickupDate).add(1, 'day'), 'day');
        })
        .map(traveller => ({
          code: traveller.id,
          description: `${traveller.firstName} ${traveller.lastName}`
        })),
      { code: newInvoiceRecipientCode, description: t(Keys.newInvoiceRecipient) }
    ];
  };

  const handlePrev = (): void => {
    store.setError('');
    history.push(CheckoutPageUrl.TRAVELLER);
  };

  const handleInsuranceSelection = (selected: Array<InsuranceItem>) => {
    const units: CamperInsuranceUnit[] = [];
    selected.forEach(s => {
      const flatMap = insurances?.flatMap(i => i.units);
      const camperInsuranceUnit = flatMap?.find(u => u.inventoryCode === s.code);
      if (camperInsuranceUnit) {
        units.push(camperInsuranceUnit);
      }
    });
    setSelectedInsuranceUnits(units);
  };

  return (
    <div className="checkout__customer pb-5">
      <ProgressBar store={store} />
      <ErrorDisplay store={store} />
      <LoadingOverlay
        isLoading={
          (!store.camper && !store.travellers && !store.error) || store.isLoading || isLoading
        }
        positionFixed
        backgroundColor="#000"
        customSpinner={<CamperLoading />}
      >
        <Row>
          <Col xs={12} lg={9}>
            {store.camper && (
              <ContentContainer borderRadius>
                <CollapseHeadline headerText={t(Keys.summary)} initialState>
                  <div className="pt-4">
                    <CamperSummary
                      camper={store.camper}
                      youtubeOpts={{ host: YOUTUBE_NOCOOKIE_HOST }}
                      MapReplacement={
                        !!googleMapsService && (cmbConfig.isSDPLoaded || useCustomCMP) ? (
                          <div className="position-relative py-3">
                            <CMPConsentLayer
                              useCustomCMP={useCustomCMP}
                              serviceId={googleMapsService.id}
                              title={
                                useCustomCMP
                                  ? consentT(KeysConsent.customTitle, {
                                      service: googleMapsService.name
                                    })
                                  : window.uc?.translations?.translations?.DEFAULT_TITLE?.replace(
                                      '%TECHNOLOGY_NAME%',
                                      googleMapsService.name
                                    )
                              }
                              description={
                                useCustomCMP
                                  ? consentT(KeysConsent.customMapDescription)
                                  : window.uc?.translations?.translations?.MAP_DESCRIPTION
                              }
                            />
                          </div>
                        ) : (
                          <></>
                        )
                      }
                      showMapReplacement={!cmpConsent[googleMapsService?.id || '']}
                      VideoReplacement={
                        !!youtubeService && useCustomCMP ? (
                          <div className="position-relative py-3">
                            <CMPConsentLayer
                              useCustomCMP
                              serviceId={youtubeService.id}
                              title={consentT(KeysConsent.customTitle, {
                                service: youtubeService.name
                              })}
                              description={consentT(KeysConsent.customVideoDescription)}
                            />
                          </div>
                        ) : (
                          <></>
                        )
                      }
                      showVideoReplacement={!cmpConsent[youtubeService?.id || '']}
                      tcsUrl={tcsUrl}
                    />
                  </div>
                </CollapseHeadline>
              </ContentContainer>
            )}
            <ContentContainer borderRadius>
              <CollapseHeadline
                className="checkout-address-form"
                headerText={t(Keys.billingInfo)}
                infoText={t(Keys.mandatoryFields)}
                noCollapse
              >
                <div className="pt-4">
                  {config.traveler.mockAvailable && (
                    <div className="d-flex justify-content-end">
                      <Button
                        color="secondary"
                        className="mb-4 mr-3"
                        onClick={(): void => {
                          setInitialValues({
                            ...getCustomerMockData(),
                            customer: getTravellersForSelect()[0]
                              ? getTravellersForSelect()[0].code
                              : ''
                          });
                        }}
                      >
                        Fill with mock data
                      </Button>
                    </div>
                  )}
                  <CheckoutAddressBuilder<
                    {
                      travellers: ApiBaseData[];
                      yupContext: { emailProp: string; adultMinAge: number; startDate: string };
                      countries: (ApiBaseData & { isDisabled?: boolean })[];
                    },
                    Record<string, unknown>
                  >
                    builderRef={builderRef}
                    formConfig={useFormConfig(isNewInvoiceRecipient, minDriverAge, builderT)}
                    initialValues={initialValues}
                    externalData={{
                      travellers: getTravellersForSelect(),
                      yupContext: {
                        emailProp: 'email',
                        adultMinAge: minDriverAge,
                        startDate: store?.camper?.pickupDate || ''
                      },
                      countries: getCountries()
                    }}
                    mode="onChange"
                    reValidateMode="onChange"
                    onFormFieldsChange={onCheckoutAddressChange}
                  />
                </div>
              </CollapseHeadline>
            </ContentContainer>
            {countryCode && (
              <ContentContainer borderRadius>
                <LoadingOverlay isLoading={!insurances}>
                  <CollapseHeadline
                    className="checkout-insurance"
                    headerText={t(Keys.insurance)}
                    noCollapse
                  >
                    <Collapse isOpen={!!insurances}>
                      {insurances && (
                        <div className="pt-4">
                          <CamperInsurance
                            insurances={
                              withCamperInsurances(
                                insurances,
                                store.getMaxTravelerAge(),
                                cit.t,
                                store.travellers.map(traveler =>
                                  getAgeOnGivenDate(
                                    traveler.birthDate,
                                    store.booking?.travelStartDate
                                  )
                                ),
                                store.booking?.travelStartDate,
                                store.camper.status
                              ) as CamperInsurances
                            }
                            change={handleInsuranceSelection}
                            showErrors={!insuranceTermsAccepted && isDirty}
                            onTermsAccepted={setInsuranceTermsAccepted}
                            renderSubHeading={
                              <div>
                                <img
                                  src={`${assetBasePath}/ergo.png`}
                                  alt="ERGO Reiseversicherung"
                                />
                              </div>
                            }
                          />
                        </div>
                      )}
                    </Collapse>
                  </CollapseHeadline>
                </LoadingOverlay>
              </ContentContainer>
            )}
            <ContentContainer borderRadius>
              <section className="payment--section">
                <CollapseHeadline headerText={t(Keys.payment)} initialState noCollapse>
                  {!countryCode ? (
                    <p className="mt-4">{t(Keys.paymentHiddenText)}</p>
                  ) : (
                    <>
                      <div className="camper-insurance__sub-header d-flex">
                        <p
                          className="mt-md-4 mb-4"
                          dangerouslySetInnerHTML={{ __html: sanitize(t(Keys.paymentVisibleText)) }}
                        />
                        <div className="mt-4">
                          <img
                            className="camper-insurance__logo"
                            src={`${assetBasePath}/thawte_secured.png`}
                            alt="Thawte Secured"
                          />
                        </div>
                      </div>
                      <PaymentSection
                        key={countryCode}
                        store={store}
                        payment={[payment, setPayment]}
                        showErrors={isDirty}
                        countryCode={countryCode}
                      />
                    </>
                  )}
                </CollapseHeadline>
              </section>
            </ContentContainer>
            {!!store?.booking?.captureData && store.booking.captureData.length > 0 && (
              <ContentContainer>
                <section className="flight-data">
                  <CollapseHeadline
                    headerText={t(Keys.additionalInformation)}
                    initialState
                    noCollapse
                  >
                    <CaptureFlightData
                      ref={captureFlightDataRef}
                      captureData={store.booking.captureData}
                      isFormDirty={isDirty}
                    />
                  </CollapseHeadline>
                </section>
              </ContentContainer>
            )}
            <ContentContainer className="terms-and-conditions" borderRadius>
              <AcceptableTermsAndConditionsList
                icon={faExclamationTriangle}
                title={t(Keys.tcsHeadline)}
                onChange={(valid: boolean) => {
                  setTcsAccepted(valid);
                }}
                terms={terms(t, tcsUrl)}
                showErrors={!tcsAccepted && isDirty}
              />
              <div className="checkout__traveller__button-container mt-5">
                <Button
                  color="secondary btn-secondary--stroked"
                  className="checkout__traveller__submit px-4 mb-2"
                  onClick={handlePrev}
                >
                  {t(Keys.prev)}
                </Button>
                <Button
                  color="primary"
                  className="checkout__traveller__submit px-4 mb-2"
                  onClick={handleSubmit}
                >
                  {t(Keys.next)}
                </Button>
              </div>
            </ContentContainer>
          </Col>
          <FixedElementScroll offset={20}>
            <div className="fixed-scroll-element-container">
              <PriceDetails
                camper={store.camper}
                items={selectedInsuranceUnits?.map(i => {
                  return { price: i.price, label: i.name };
                })}
                discounts={store.booking?.discounts}
                submitDiscount={(discountCode: string) =>
                  store.submitDiscountCode(store.booking?.id, discountCode)
                }
              />
            </div>
          </FixedElementScroll>
        </Row>
      </LoadingOverlay>
    </div>
  );
});

export default Customer;
