import React from 'react';
import { observer } from 'mobx-react';
import { Error, useTranslation, Warning } from '@ibe/components';
import { Element as ScrollTo } from 'react-scroll';
import CheckoutStore from '../Pages/Checkout/CheckoutStore';
import fallback from '../Translations/generated/camper.de.json';

const ErrorDisplay = observer(
  ({
    store: { error: storeError, warning: storeWarning, scrollElementGlobalError },
    error,
    warning
  }: {
    store: CheckoutStore;
    error?: string;
    warning?: string;
  }) => {
    const { t } = useTranslation('camper', fallback);

    return (
      <>
        <ScrollTo name={scrollElementGlobalError}>
          {storeError && <Error message={error || t(storeError)} />}
          {!storeError && storeWarning && <Warning message={warning || t(storeWarning)} />}
        </ScrollTo>
      </>
    );
  }
);

export default ErrorDisplay;
