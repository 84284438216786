import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from '@ibe/components';
import { CheckoutStepComponentProps } from '../../../Models/CheckoutStep';
import fallback from '../../../Translations/generated/checkout.de.json';
import workflowCheckout from '../../../steps';
import { useHistory } from 'react-router-dom';

const ProgressBar = observer(
  (props: CheckoutStepComponentProps): JSX.Element => {
    const { store } = props;
    const history = useHistory();
    const { t } = useTranslation('checkout', fallback);

    const getActiveStepIndex = (): number => {
      const index = workflowCheckout.findIndex(item => item.type === store.activeStep);
      return index === -1 ? 0 : index;
    };

    const isBeforeActiveStep = (idx: number): Boolean => {
      return idx < getActiveStepIndex() && getActiveStepIndex() < store.steps.length - 1;
    };

    return (
      <div className="progress-wrapper">
        <div className="camper-progress">
          <div className="camper-progress__item__list">
            {store.steps
              .map(step => ({ ...step, description: t(step.description) }))
              .map((step, idx: number) => (
                <div
                  key={step.description}
                  style={{ flex: ` 1 1 calc(1 / ${store.steps.length} * 100%)` }}
                  className={`camper-progress__item${
                    step.selected ? ' camper-progress__item--active' : ''
                  }${isBeforeActiveStep(idx) ? ' camper-progress__item__clickable' : ''}`}
                  onClick={
                    isBeforeActiveStep(idx) && !!workflowCheckout[idx]
                      ? () => history.push(workflowCheckout[idx].slug)
                      : undefined
                  }
                >
                  <FontAwesomeIcon icon={step.image} />
                  <span>{step.description}</span>
                </div>
              ))}
          </div>
          <div className="camper-progress__bar">
            <span
              style={{
                width: `calc(${store.steps.findIndex(step => step.selected) + 1} / ${
                  store.steps.length
                } * 100%)`
              }}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default ProgressBar;
