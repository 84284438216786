enum CreditCardType {
  VISA = 'visa',
  MASTER_CARD = 'mastercard',
  AMERICAN_EXPRESS = 'amex',
  MAESTRO = 'maestro',
  DINERS = 'diners',
  CARTE_BLEUE = 'cartebleue',
  JCB = 'jbc'
}
export default CreditCardType;
