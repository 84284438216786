// eslint-disable
import { action, makeObservable, observable } from 'mobx';
import {
  AppService,
  bookingItemAddedEvent,
  BookingService,
  ConfigModel,
  errorEvent,
  LoggerFactory,
  SessionPersistence,
  StepType
} from '@ibe/services';
import { faCreditCard, faIdCard, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ImageProgressbarStep, initBookingService, SessionData, Tenant } from '@ibe/components';
import {
  ApiBookedItem,
  ApiBooking,
  ApiBookingState,
  ApiCamper,
  ApiCamperExtra,
  ApiCamperRate,
  ApiCamperSpecialOffer,
  ApiCreditCardData,
  ApiCustomer,
  ApiDirectDebitData,
  ApiItem,
  ApiItemType,
  ApiPaymentDataType,
  ApiPaymentFromJSON,
  ApiPaymentState,
  ApiTraveler,
  ApiUpdateCustomerRequest,
  ApiPaymentOption,
  ApiGetAdditionalInsuranceListRequestFromJSON,
  ApiUpdateCaptureDataRequest,
  ApiClientType,
  ApiUpdateAffiliateRequest
} from '@ibe/api';
import Keys from '../../Translations/generated/checkout.de.json.keys';
import { InitPaymentResponse } from '../../Models/payment/api/InitPaymentResponse';
import { Payment } from './components/payment/Payment';
import CreditCardType from '../../Models/payment/CreditCardType';
import { ExtendedApiService } from '../../Services/ExtendedApiService';
import {
  CamperInsuranceData,
  CamperInsuranceUnit
} from '../../Models/insurance/CamperInsuranceData';
import { CmbConfigModel } from '../../Config/CmbConfigModel';
import { TFunction } from 'react-i18next';
import PaymentService from '../../Services/PaymentService';

class CheckoutStore {
  logger = LoggerFactory.get('Checkoutstore.tsx');

  AGENCY_NUMBER_KEY = 'affiliate-agency-number';

  _travellers: ApiTraveler[] = [];

  _customer: ApiCustomer;

  _activeStep: StepType = StepType.CAMP_CHECKOUT_TRAVELLERS;

  _camper: ApiCamper;

  _insurances: Array<CamperInsuranceData> | null = null;

  _initPaymentResponse: InitPaymentResponse | null = null;

  error: string | null = null;

  warning: string | null = null;

  _bookingService: BookingService;

  _appService: AppService;

  _paymentService: PaymentService;

  _booking: ApiBooking | null;

  _isLoading = false;

  _isInitializingPayment = false;

  scrollElementGlobalError = 'camperGlobalErrorScroller';

  _session = new SessionPersistence<SessionData>(SessionData);

  _config: ConfigModel;

  _cmbConfig: CmbConfigModel;

  _api: ExtendedApiService;

  _creditCardTypes: Array<CreditCardType> | null = null;

  _affiliateNumber: string | undefined = undefined;

  constructor(
    config: ConfigModel,
    cmbConfig: CmbConfigModel,
    api: ExtendedApiService,
    bookingService: BookingService,
    appService: AppService,
    paymentService: PaymentService,
    affiliateNumber?: string
  ) {
    makeObservable(this, {
      _travellers: observable,
      _customer: observable,
      _activeStep: observable,
      _camper: observable,
      _insurances: observable,
      _initPaymentResponse: observable,
      _booking: observable,
      _isLoading: observable,
      _session: observable,
      _cmbConfig: observable,
      error: observable,
      warning: observable,
      setCamper: action,
      setActiveStep: action,
      setTravellers: action,
      setCustomer: action,
      setIsLoading: action,
      setError: action,
      resetBooking: action,
      handleRemoveTraveller: action,
      setInitPaymentResponse: action,
      setInsurances: action,
      clearSearchParams: action,
      submitDiscountCode: action,
      setBooking: action
    });
    this._config = config;
    this._cmbConfig = cmbConfig;
    this._api = api;
    this._appService = appService;
    this._paymentService = paymentService;
    this._bookingService = bookingService;
    this._affiliateNumber = affiliateNumber;
  }

  public get tenant(): Tenant | null {
    return this._cmbConfig.tenant || null;
  }

  public setBooking(booking: ApiBooking | null): void {
    this._booking = booking;
  }

  public clearSearchParams(): void {
    sessionStorage.removeItem('camper-session-search');
  }

  public setInitPaymentResponse(ipr: InitPaymentResponse | null | undefined) {
    this._initPaymentResponse = ipr || null;
  }

  public setInsurances(ins: CamperInsuranceData[] | null | undefined) {
    this._insurances = ins || null;
  }

  public get activeStep(): StepType {
    return this._activeStep;
  }

  public setActiveStep(activeStep: StepType): void {
    this._activeStep = activeStep;
  }

  public get travellers(): ApiTraveler[] {
    return this._travellers && this._travellers.length > 0
      ? this._travellers
      : this._bookingService.booking?.travelers || [];
  }

  public setTravellers(travellers: ApiTraveler[]): void {
    this._travellers = [...travellers];
  }

  public get customer(): ApiCustomer | undefined {
    return this._customer;
  }

  public setCustomer(customer: ApiCustomer): void {
    this._customer = customer;
  }

  public setIsLoading(isLoading: boolean): void {
    this._isLoading = isLoading;
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public get initPaymentResponse(): InitPaymentResponse | null {
    return this._initPaymentResponse;
  }

  get steps(): ImageProgressbarStep[] {
    return [
      {
        description: Keys.personalData,
        selected: this._activeStep === StepType.CAMP_CHECKOUT_TRAVELLERS,
        image: faIdCard
      },
      {
        description: Keys.summaryPayment,
        selected: this._activeStep === StepType.CAMP_CHECKOUT_CUSTOMER,
        image: faCreditCard
      },
      {
        description: Keys.confirmation,
        selected: this._activeStep === StepType.CAMP_CHECKOUT_CONFIRMATION,
        image: faCheckCircle
      }
    ];
  }

  public get booking(): ApiBooking | null {
    return this._booking || this._bookingService.booking;
  }

  private reset = (): void => {
    this.setInsurances(null);
    this.setInitPaymentResponse(null);
  };

  public get camper(): ApiCamper {
    return (
      this._camper ||
      this._bookingService.booking?.items?.find(
        (item: ApiItem) => item.itemType === ApiItemType.CAMPER
      ) ||
      this._bookingService.bookedItems?.find(
        (item: ApiItem) => item.itemType === ApiItemType.CAMPER
      )
    );
  }

  termsAndConditions = async (camper?: ApiCamper): Promise<string | undefined> => {
    const camperItem = camper || this._camper;
    if (
      !!camperItem &&
      !!this.booking?.promoCodes &&
      this.booking?.promoCodes.length > 0 &&
      !!this.booking?.brand
    ) {
      return (
        await this._api.getCamperTermsAndConditions({
          camper: camperItem,
          promotion: this.booking.promoCodes[0],
          brand: this.booking.brand
        })
      )?.url;
    }
    if (!!camperItem) {
      return (await this._api.getCamperTermsAndConditions({ camper: camperItem }))?.url;
    }
    return undefined;
  };

  public get bookedItems(): ApiBookedItem[] {
    return this._bookingService.bookedItems || [];
  }

  public setCamper(camper: ApiCamper): void {
    this.reset();
    this._camper = camper;
  }

  public setError(value: string, t?: TFunction): void {
    if (value) {
      errorEvent.broadcast({ id: !!t ? t(value) : value });
    }
    this.logger.log(value);
    this.error = value;
  }

  private updateAffiliate = async (): Promise<void> => {
    this.logger.log('update affiliate');
    this.setIsLoading(true);
    return this._bookingService
      .updateAffiliate({
        affiliateNumber: this._affiliateNumber
      } as ApiUpdateAffiliateRequest)
      .then(this.handleResponse)
      .catch(this.handleException);
  };

  private updateCustomer = async (): Promise<void> => {
    this.logger.log('update customer');
    this.setIsLoading(true);
    return this._bookingService
      .updateCustomer({
        customer:
          sessionStorage.getItem(this.AGENCY_NUMBER_KEY) || this._affiliateNumber
            ? {
                ...this._customer,
                type: ApiClientType.Customer,
                clientNumber: this._affiliateNumber
                  ? this._affiliateNumber
                  : sessionStorage.getItem(this.AGENCY_NUMBER_KEY)
              }
            : this._customer
      } as ApiUpdateCustomerRequest)
      .then(this.handleResponse)
      .catch(this.handleException);
  };

  public updateTravellers = async (): Promise<void> => {
    this.logger.log('update travellers');
    this.setIsLoading(true);
    return this._bookingService
      .updateTravelers(this._travellers)
      .then(this.handleResponse)
      .catch(this.handleException);
  };

  public attemptBooking = async (): Promise<void> => {
    this.setIsLoading(true);
    return await this.onAddToBooking()
      .then(this.updateCustomer)
      .then(this.doBookingAttempt)
      .then(this.handleResponse)
      .catch((e?: { status?: number; message?: string }) => {
        this.setError(Keys.bookingAttemptFailed);
        this.handleException(e);
      });
  };

  public prepareBooking = async (): Promise<void> => {
    this.setError('');
    this.setIsLoading(true);
    await this.updateCustomer()
      .then(this.doBookingAttempt)
      .then(this.handleResponse)
      .finally(() => this.setIsLoading(false));
  };

  public handleAddTraveller = async (traveller: ApiTraveler): Promise<void> => {
    if (!this.travellers.find(innerTraveller => innerTraveller.id === traveller.id)) {
      this.setTravellers([...this.travellers, traveller]);
      await this._bookingService.addTraveler(traveller);
    }
  };

  public handleRemoveTraveller = async (traveller: ApiTraveler): Promise<void> => {
    this.setTravellers([
      ...this.travellers.filter(localTraveller => localTraveller.id !== traveller.id)
    ]);
    await this._bookingService
      .removeTraveler(traveller)
      .then(this.handleResponse)
      .catch(this.handleException);
  };

  public resetBooking = async (): Promise<void> => {
    this._session.useKey(this._config.sessionKeyCart);
    this._session.clear();
    this._bookingService.clearBooking();
    await initBookingService(this._config, this._api);
  };

  public forceBookingInit = async (): Promise<void> => {
    const session = new SessionPersistence<SessionData>(SessionData);
    session.useKey(this._config.sessionKeyCart);

    try {
      await this._bookingService.init();
      if (this._bookingService?.booking?.id) {
        await session.set({ bookingId: this._bookingService.booking.id });
      }
    } catch (err) {
      this.logger.error(err);
    }
  };

  public book = async (): Promise<void> => {
    if (this.error) {
      return await this.doBookingAttempt().then(this.doBooking);
    } else {
      return await this.prepareBooking()
        .then(this.doBooking)
        .finally(() => this.setIsLoading(false));
    }
  };

  private handleResponse = (): void => {
    this.setBooking(this._bookingService.booking);
    const bookingCamper = this._booking?.items.find(
      item => item.itemType === ApiItemType.CAMPER
    ) as ApiCamper;
    if (!!bookingCamper?.status) {
      this.camper.status = bookingCamper.status;
    }
    this.setTravellers(this._booking?.travelers || []);
    this.setCustomer(this._booking?.client as ApiCustomer);
    this.logger.log('handleResponse', this._booking);
    this.setIsLoading(false);
  };

  private handleBookResponse = (): void => {
    this.setBooking(this._bookingService.booking);
    const foundCamper = this.booking?.items?.find(
      (item: ApiItem) => item.itemType === ApiItemType.CAMPER
    ) as ApiCamper | undefined;
    if (!!foundCamper) {
      this.setCamper(foundCamper);
    }
    this.logger.log('handleBookResponse', this._booking);
    if (!this._booking?.bookingNumber || this._booking?.bookingState === ApiBookingState.OPEN) {
      this.setError(Keys.bookingAttemptFailed);
    }
    this.setIsLoading(false);
  };

  private handleException = async (e?: { status?: number; message?: string }): Promise<void> => {
    this.logger.log('exception ', e);
    if (e?.status === 422) {
      this.setError(Keys.sessionInvalid);
    }
    this.setIsLoading(false);
    throw e;
  };

  private handleAddItemException = async (e?: {
    status?: number;
    message?: string;
  }): Promise<void> => {
    this.logger.log('exception ', e);
    if (e?.status === 422) {
      this.setError(Keys.sessionInvalid);
    } else {
      this.setError(Keys.addItemsFailed);
    }
    this.setIsLoading(false);
    throw e;
  };

  private doBookingAttempt = async (): Promise<void> => {
    this.logger.log('do booking attempt');
    this.setError('');
    this.setIsLoading(true);
    return this._bookingService.executeBookingAttempt().then(this.handleResponse);
  };

  private doBooking = async (): Promise<void> => {
    await this._bookingService.cleanInvalidDiscounts();
    this.logger.log('do book');
    return this._bookingService
      .doBooking()
      .then(this.handleBookResponse)
      .catch(this.handleException)
      .finally(() => this.setIsLoading(false));
  };

  private onAddToBooking = async (): Promise<void> => {
    this.logger.log('add item to booking');

    function isExtra(
      item: ApiCamperRate | ApiCamperExtra | ApiCamperSpecialOffer
    ): item is ApiCamperExtra {
      return item.hasOwnProperty('selectedCount');
    }

    try {
      const rate = this.camper.rates?.find((r: ApiCamperRate) => r.isSelected);
      if (!rate) {
        return;
      }
      const extras = rate.extras?.filter(
        extra =>
          (!extra.mandatory && extra.selectedCount && extra.selectedCount > 0) || extra.mandatory
      );
      const itemsToAdd = [
        rate,
        ...(extras || []),
        ...(rate.specialOfferGroups?.reduce((total: ApiCamperSpecialOffer[], current) => {
          return [
            ...total,
            ...current.offers.reduce((innerTotal: ApiCamperSpecialOffer[], innerCurrent) => {
              return innerCurrent.isSelected ? [...innerTotal, innerCurrent] : [...innerTotal];
            }, [])
          ];
        }, []) || [])
      ];
      await this._bookingService.addItems(
        Object.fromEntries(
          itemsToAdd.map(item => [
            item.id,
            [
              {
                count: isExtra(item) ? item.selectedCount || 1 : 1,
                date: this.camper.pickupDate,
                travelers: this._travellers,
                subItemType: isExtra(item) ? ApiItemType.CAMPEREXTRA : undefined
              }
            ]
          ])
        )
      );
      if (!this._bookingService.hasError) {
        const addedItem: ApiBookedItem | undefined = this._bookingService.booking?.bookedItems.find(
          (it: ApiBookedItem) => it.idParent === rate.id
        );
        bookingItemAddedEvent.broadcast({
          booking: this._bookingService.booking,
          item: addedItem || null
        });
      } else {
        this.setError(Keys.addItemsFailed);
      }
    } catch (e: unknown) {
      this.handleAddItemException(e as { message: string });
    }
  };

  public getSupportedCreditCards(): Array<CreditCardType> {
    if (!this.booking) {
      return [];
    }

    if (this._creditCardTypes) {
      return this._creditCardTypes;
    }

    const ccTypes = this.booking.payments[0]?.paymentData?.paymentOptions?.map(
      (option: ApiPaymentOption) => {
        switch (option) {
          case ApiPaymentOption.M:
            return CreditCardType.MASTER_CARD;
          case ApiPaymentOption.V:
            return CreditCardType.VISA;
          case ApiPaymentOption.A:
            return CreditCardType.AMERICAN_EXPRESS;
          default:
            return undefined;
        }
      }
    );

    const result: CreditCardType[] = [];
    ccTypes?.forEach(ct => {
      if (ct) result.push(ct);
    });
    this._creditCardTypes = result;

    return this._creditCardTypes || [];
  }

  public addInsuranceToBooking = async (insurances: Array<CamperInsuranceUnit>): Promise<void> => {
    if (
      this.bookedItems.filter(bookedItem => bookedItem.itemType === ApiItemType.INSURANCE).length >
      0
    ) {
      await this._bookingService.removeItems(
        this.bookedItems
          .filter(bookedItem => bookedItem.itemType === ApiItemType.INSURANCE)
          .map(bookedItem => bookedItem.id)
      );
    }
    await this._bookingService.addItems(
      Object.fromEntries(
        insurances.map(item => [
          item.id,
          [
            {
              count: 1,
              date: this.camper.pickupDate,
              travelers: this._travellers,
              subItemType: ApiItemType.INSURANCE
            }
          ]
        ])
      )
    );
    if (!this._bookingService.hasError) {
      const added: ApiBookedItem[] | undefined = this._bookingService.booking?.bookedItems.filter(
        (it: ApiBookedItem) => it.id in insurances.flatMap(i => i.id)
      );
      added?.forEach(a => {
        bookingItemAddedEvent.broadcast({
          booking: this._bookingService.booking,
          item: a || null
        });
      });
    } else {
      this.setError(Keys.addItemsFailed);
    }
  };

  private createPaymentData(paymentData: Payment): ApiCreditCardData | ApiDirectDebitData {
    const json = {
      type:
        paymentData.paymentOption === ApiPaymentOption.CREDITCARD
          ? ApiPaymentDataType.CreditCardData
          : ApiPaymentDataType.DirectDebitData,
      holder: paymentData.holder,
      bic: paymentData.bic,
      iban: paymentData.iban,
      token: paymentData.ccToken,
      expiryDate: paymentData.expiryDate,
      paddedPan: paymentData.paddedPan,
      option: paymentData.option,
      panAlias: ''
    };
    return paymentData.paymentOption === ApiPaymentOption.CREDITCARD
      ? (json as ApiCreditCardData)
      : (json as ApiDirectDebitData);
  }

  private isValidCountry(country: string): boolean {
    return this._cmbConfig.insuranceOfferCountries.includes(country);
  }

  public showInsurance(countryCode: string): boolean {
    return !!countryCode && this._appService.currency === 'EUR' && this.isValidCountry(countryCode);
  }

  public async addPaymentData(paymentData: Payment): Promise<ApiBooking> {
    if (this.booking?.id) {
      return await this._paymentService.api.addPayment(this.booking?.id, {
        ...ApiPaymentFromJSON({
          id: 'payment_' + this.camper.id,
          idParent: this.camper.id,
          paymentOption: paymentData.paymentOption,
          state: ApiPaymentState.OPEN
        }),
        ...{ paymentData: this.createPaymentData(paymentData) }
      });
    }
    return new Promise((resolve, reject) => reject(null));
  }

  public async updateCaptureData(
    captureData: ApiUpdateCaptureDataRequest,
    bookingId?: string
  ): Promise<ApiBooking> {
    if (bookingId) {
      return await this._api.updateCaptureData(bookingId, captureData);
    }
    return new Promise((resolve, reject) => reject(null));
  }

  public async doInitPayment(): Promise<InitPaymentResponse | undefined> {
    if (this.booking?.id && !this._isInitializingPayment) {
      this._isInitializingPayment = true;
      return await this._paymentService.api.initPayment(this.booking?.id).then(r => {
        this.setInitPaymentResponse(r);
        this._isInitializingPayment = false;
        setTimeout(() => {
          this.setInitPaymentResponse(null);
        }, 1200000); // clear after 20 min
        return this._initPaymentResponse || undefined;
      });
    }
    return new Promise(resolve => resolve(this._initPaymentResponse || undefined));
  }

  public async loadInsurances(): Promise<void> {
    if (!!this.booking) {
      try {
        const camperInsuranceData = await this._api.getCamperCmsInsuranceList(
          ApiGetAdditionalInsuranceListRequestFromJSON({
            bookingId: this.booking.id,
            bookedItemId: this.booking.bookedItems.find(i => {
              return i.itemType === ApiItemType.CAMPER;
            })?.id
          })
        );
        if (Array.isArray(camperInsuranceData)) {
          this.setInsurances(camperInsuranceData);
        }
      } catch {
        this.setInsurances(null);
      }
    }
  }

  public async submitDiscountCode(
    bookingId: string | undefined,
    discountCode: string
  ): Promise<void> {
    if (bookingId) {
      this.setIsLoading(true);
      return await this._api
        .submitDiscount(bookingId, { discountCode: discountCode })
        .then(booking => this.setBooking(booking))
        .finally(() => this.setIsLoading(false));
    }
    return new Promise((resolve, reject) => reject(null));
  }

  public async getInsurances(countryCode: string): Promise<Array<CamperInsuranceData> | undefined> {
    if (!this.showInsurance(countryCode)) {
      return [];
    }
    await this.loadInsurances();
    return this._insurances || [];
  }

  public getMaxTravelerAge(): number {
    let max = 0;
    this.travellers.forEach(t => {
      if (t.age > max) {
        max = t.age;
      }
    });
    return max;
  }
}

export default CheckoutStore;
