/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-12-23 12:21:24 */

const Keys = {
  modalText1: 'modalText1',
  modalText2: 'modalText2',
  modalText3: 'modalText3',
  modalText4: 'modalText4',
  modalText5: 'modalText5',
  modalSubmit: 'modalSubmit'
}; export default Keys;