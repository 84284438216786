import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { CMP_SERVICES, CMP_SERVICES_CUSTOM } from '../../../Config/config';

type CMPServicesType = { id: string; name: string };

const CMP_EVENT_SETTER = 'cmbSetCustomCmpEvent';
export const CMP_EVENT_GETTER = 'cmbGetCustomCmpEvent';

const getInitialCMPConsent = (useCustomCMP?: boolean): CMPConsent =>
  (useCustomCMP ? CMP_SERVICES_CUSTOM : CMP_SERVICES).reduce(
    (total: CMPConsent, current: CMPServicesType) => {
      return {
        ...total,
        [current.id]: null
      };
    },
    {}
  );

export type CMPConsent = { [key: string]: boolean | null };

const CMPConsentContext = createContext<CMPConsent>(getInitialCMPConsent());

const useCMP = (useCustomCMP?: boolean) => {
  const UC_UI_initialized = window.UC_UI?.isInitialized();
  const [isInitialized, setInitialized] = useState(UC_UI_initialized);

  useEffect(() => {
    const onInitialize = () => {
      setInitialized(true);
    };

    window.addEventListener('UC_UI_INITIALIZED', onInitialize);
    if (UC_UI_initialized || useCustomCMP) {
      setInitialized(true);
    }

    return () => {
      window.removeEventListener('UC_UI_INITIALIZED', onInitialize);
    };
  }, [UC_UI_initialized, useCustomCMP]);
  return { cmp: window.UC_UI, isInitialized };
};

const useCMPConsent = (useCustomCMP?: boolean, initialCmpConsent?: CMPConsent): CMPConsent => {
  const { cmp, isInitialized } = useCMP(useCustomCMP);
  const [cmpConsent, setCMPConsent] = useState<CMPConsent>(getInitialCMPConsent(useCustomCMP));
  const eventName = useCustomCMP ? CMP_EVENT_SETTER : 'cmpEvent';

  useEffect(() => {
    if (!isInitialized) return;
    if (!useCustomCMP) {
      setCMPConsent(
        CMP_SERVICES.reduce(
          (total: CMPConsent, current: CMPServicesType) => {
            const service = cmp
              ?.getServicesBaseInfo()
              .find((s: { id: string }) => s.id === current.id);
            return !!service && (total[current.id] === null || total[current.id] === undefined)
              ? { ...total, [current.id]: service.consent.status }
              : { ...total };
          },
          { ...cmpConsent }
        )
      );
    } else {
      setCMPConsent(currentConsent =>
        !!initialCmpConsent && !Object.entries(currentConsent).some(([, value]) => value !== null)
          ? { ...initialCmpConsent }
          : CMP_SERVICES_CUSTOM.reduce((total: CMPConsent, current: CMPServicesType) => {
              return { ...total, [current.id]: null };
            }, {})
      );
    }

    const onCMPEvent = (e: Event) => {
      const evt = e as Event & { detail: { [key: string]: string | boolean; event: string } };
      if (!!evt.detail && evt.detail.event === 'consent_status' && !useCustomCMP) {
        setCMPConsent(
          CMP_SERVICES.reduce(
            (total: CMPConsent, current: CMPServicesType) => {
              return evt.detail[current.name] !== undefined
                ? { ...total, [current.id]: evt.detail[current.name] as boolean }
                : { ...total };
            },
            { ...cmpConsent }
          )
        );
      } else if (useCustomCMP) {
        const customEvt = e as Event & { detail: { [key: string]: boolean } };
        if (!!customEvt.detail) {
          setCMPConsent(customEvt.detail);
        }
      }
    };

    window.removeEventListener(eventName, onCMPEvent);
    window.addEventListener(eventName, onCMPEvent);

    return () => {
      window.removeEventListener(eventName, onCMPEvent);
    };
  }, [cmp, isInitialized, initialCmpConsent]);

  return cmpConsent;
};

export const CMPConsentContextProvider = ({
  children,
  useCustomCMP,
  initialCmpConsent
}: PropsWithChildren<{ useCustomCMP?: boolean; initialCmpConsent?: CMPConsent }>): JSX.Element => {
  const cmpConsent = useCMPConsent(useCustomCMP, initialCmpConsent);
  return <CMPConsentContext.Provider value={cmpConsent}>{children}</CMPConsentContext.Provider>;
};

const useCMPConsentContext = (): CMPConsent => {
  return useContext<CMPConsent>(CMPConsentContext);
};

export default useCMPConsentContext;
