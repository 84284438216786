/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-12-23 12:21:25 */

const Keys = {
  next: 'next',
  previous: 'previous',
  summary: 'summary',
  participant: 'participant',
  mandatoryFields: 'mandatoryFields',
  detailPage: 'detailPage',
  bookingAttemptFailed: 'bookingAttemptFailed',
  confirmGoBack: 'confirmGoBack',
  yes: 'yes',
  no: 'no',
  cancel: 'cancel',
  traveller: 'traveller',
  travellerNote: 'travellerNote',
  travellerWarning: 'travellerWarning',
  travellerWarningAge: 'travellerWarningAge',
  travellerWarningMinMax: 'travellerWarningMinMax',
  travellerWarningMaxChild: 'travellerWarningMaxChild'
}; export default Keys;