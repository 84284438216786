import React from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';

interface Props {
  children: React.ReactNode;
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`;

const Center = styled.div`
  flex: 1 1 auto;
`;

const BlankPageLayout = observer((props: Props) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <Center>{props.children}</Center>
    </Layout>
  );
});

export default BlankPageLayout;
