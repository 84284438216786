import React from 'react';

const Camper = ({ className }: { className: string }): JSX.Element => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="207.251"
      height="67.151"
      viewBox="0 0 207.251 67.151"
    >
      <g id="Gruppe_2413" data-name="Gruppe 2413" transform="translate(-1200 -920)">
        <path
          id="Differenzmenge_3"
          data-name="Differenzmenge 3"
          d="M36.621,24.045h-23.1a4.2,4.2,0,1,0-8.4,0H0V7.279A5.629,5.629,0,0,1,1.141,4.1,5.447,5.447,0,0,1,4.254,2.894L37.2-.642h5.3l1.988,7.788H39.116L42.5,12.712l3.752,1.334,2.527,8.473-3,1.526h-.754a4.2,4.2,0,1,0-8.4,0Z"
          transform="translate(1225.309 951.134)"
          fill="#e8ae31"
          stroke="#282e39"
          strokeWidth="2"
        />
        <path
          id="Pfad_565"
          data-name="Pfad 565"
          d="M1257.467,958.269v6.088l11.328-.4-3.535-5.689Z"
          transform="translate(-2)"
          fill="#fff"
        />
        <path
          id="Pfad_378"
          data-name="Pfad 378"
          d="M1254.145,959.274h-14.291"
          transform="translate(15.243 -1.149)"
          fill="none"
          stroke="#282e39"
          strokeWidth="2"
        />
        <path
          id="Pfad_379"
          data-name="Pfad 379"
          d="M1251.9,965.131h-13.48"
          transform="translate(16.809 -1)"
          fill="none"
          stroke="#282e39"
          strokeWidth="2"
        />
        <path
          id="Pfad_566"
          data-name="Pfad 566"
          d="M1241.652,972.084v-14.53h7.52v14.53"
          transform="translate(2 0.571)"
          fill="#fff"
          stroke="#282e39"
          strokeWidth="2"
        />
        <g
          id="Ellipse_65"
          data-name="Ellipse 65"
          transform="translate(1260.497 969.68)"
          fill="#fff"
          stroke="#282e39"
          strokeWidth="2"
        >
          <circle cx="5.5" cy="5.5" r="5.5" stroke="none" />
          <circle cx="5.5" cy="5.5" r="4.5" fill="none" />
        </g>
        <g
          id="Ellipse_66"
          data-name="Ellipse 66"
          transform="translate(1229.172 969.68)"
          fill="#fff"
          stroke="#282e39"
          strokeWidth="2"
        >
          <circle cx="5.5" cy="5.5" r="5.5" stroke="none" />
          <circle cx="5.5" cy="5.5" r="4.5" fill="none" />
        </g>
      </g>
    </svg>
  );
};

const CamperLoading = (): JSX.Element => {
  return (
    <div className="camper-loading">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="227"
        height="87"
        viewBox="0 0 227 87"
      >
        <defs>
          <clipPath id="clip-camper2">
            <rect width="227" height="87" />
          </clipPath>
        </defs>
        <g id="camper2" clipPath="url(#clip-camper2)" transform="translate(0 0)">
          <g id="Gruppe_2423" data-name="Gruppe 2423" transform="translate(-779.16 -1735.791)">
            <path
              id="Pfad_575"
              data-name="Pfad 575"
              d="M-215.414,96.088l9.432-8.03,6.373,5.353,5.863-3.059,22.854,42.146l-78.414,0z"
              transform="translate(1028.051 1665.301)"
              fill="#f1f7f9"
              stroke="#264653"
              strokeWidth="2"
            />
            <g id="Gruppe_2412" data-name="Gruppe 2412" transform="translate(-454.611 828.1)">
              <path
                id="Pfad_333"
                data-name="Pfad 333"
                d="M5139.558-3200.631v-2.842h8.069l-4.312-6.424h1.893l-3.168-5.325h1.715l-3.917-6.337-1.3-2.11-5.755,8.447h2.64l-3.32,5.325h3.7l-4.98,6.424h7.718"
                transform="translate(-3831 4169)"
                fill="#93bfbe"
                stroke="#264653"
                strokeWidth="2"
              />
            </g>
            <path
              id="Differenzmenge_13"
              data-name="Differenzmenge 13"
              d="M18550.773,300.33h0l-2.883-4.7-5.859,3.063-3.309-2.778a7.622,7.622,0,0,1-.137-1.436,7.5,7.5,0,1,1,12.188,5.857Z"
              transform="translate(-17713.588 1459.797)"
              fill="#e8ae31"
              stroke="#264653"
              strokeWidth="2"
            />
          </g>
        </g>
        <line
          id="Linie_171"
          data-name="Linie 171"
          x2="204.25"
          y2="0.064"
          transform="translate(0 62)"
          fill="none"
          stroke="#264653"
          strokeLinecap="round"
          strokeWidth="3"
        />
      </svg>
      <Camper className="camper-loading__camper camper-loading__camper" />
    </div>
  );
};

export default CamperLoading;
