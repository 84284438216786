import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Collapse, CustomInput } from 'reactstrap';
import { ContentContainer, Tenant, useAppService, useTranslation } from '@ibe/components';
import CreditCardType from '../../../../Models/payment/CreditCardType';
import fallback from '../../../../Translations/generated/customer.de.json';
import Keys from '../../../../Translations/generated/customer.de.json.keys';
import { PayonePaymentValidation } from './PayonePaymentValidation';
import CheckoutStore from '../../CheckoutStore';
import { DebitChargeForm } from './DebitChargeForm';
import { ApiPaymentOption } from '@ibe/api';
import useCmbConfig from '../../../../Hooks/useCmbConfig';
import getCountries from '../../../../utils/getCountries';
import { paymentExcludedCurrencies } from '../../../../Config/config';

export interface PaymentProps {
  payment: [Payment, Dispatch<SetStateAction<Payment>>];
  store: CheckoutStore;
  showErrors?: boolean;
  countryCode: string;
}

export interface Payment {
  paymentOption: ApiPaymentOption;
  ccToken: string | null;
  expiryDate: string | null;
  paddedPan: string | null;
  holder: string | null;
  iban: string | null;
  bic: string | null;
  option: ApiPaymentOption | null;
  isDirectDebitBoxChecked?: boolean;
}

const PaymentSection = observer(function PaymentSection(props: PaymentProps): JSX.Element {
  const {
    store,
    payment: [payment, setPayment],
    showErrors,
    countryCode
  } = props;
  const { t } = useTranslation('customer', fallback);
  const appService = useAppService();
  const { tenant } = useCmbConfig();
  const [token, setToken] = useState<string | null>(null);
  const [ccPaddedPan, setCcPaddedPan] = useState<string | null>(null);
  const [ccExpiryDate, setCcExpiryDate] = useState<string | null>(null);
  const [option, setOption] = useState<ApiPaymentOption | null>(null);
  const [debit, setDebit] = useState<Partial<Payment> | null>(null);

  const supportedCreditCards = store.getSupportedCreditCards();

  useEffect(() => {
    (async () => {
      await store.doInitPayment();
    })();
  }, [store]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;
    const checked = target.checked || false;
    if (checked) {
      const type = target.id as ApiPaymentOption;
      if (type === ApiPaymentOption.CREDITCARD) {
        setPayment({
          ...payment,
          ...{
            paymentOption: target.id as ApiPaymentOption,
            ccToken: token,
            expiryDate: ccExpiryDate,
            paddedPan: ccPaddedPan,
            holder: null,
            iban: null,
            bic: null,
            option: option
          }
        } as Payment);
      } else if (type === ApiPaymentOption.DIRECTDEBIT) {
        setPayment({
          ...payment,
          ...{
            ...{ paymentOption: target.id as ApiPaymentOption },
            ...debit,
            ...{
              ccToken: null,
              expiryDate: null,
              paddedPan: null,
              option: ApiPaymentOption.DebitDE
            }
          }
        } as Payment);
      } else {
        setPayment({
          ...payment,
          ...{
            paymentOption: target.id as ApiPaymentOption,
            option: ApiPaymentOption.INVOICE,
            ccToken: null,
            expiryDate: null,
            paddedPan: null,
            holder: null,
            iban: null,
            bic: null
          }
        } as Payment);
      }
    }
  };

  const onCCValid = (
    ccToken: string | null,
    ccType: CreditCardType | null,
    paddedPan: string | null,
    expiryDate: string | null
  ): void => {
    const toOption = ccTypeToOption(ccType);
    setToken(ccToken);
    setOption(toOption);
    setCcExpiryDate(expiryDate);
    setCcPaddedPan(paddedPan);
    setPayment({ ...payment, ...{ ccToken, option: toOption, expiryDate, paddedPan } });
  };

  const onCCError = () => {
    (async () => {
      await store.doInitPayment();
    })();
  };

  const ccTypeToOption = (ccType: CreditCardType | null): ApiPaymentOption | null => {
    switch (ccType) {
      case CreditCardType.AMERICAN_EXPRESS:
        return ApiPaymentOption.A;
      case CreditCardType.MASTER_CARD:
        return ApiPaymentOption.M;
      case CreditCardType.VISA:
        return ApiPaymentOption.V;
      default:
        return null;
    }
  };

  const onSubmitDebitCharge = (val: Payment | null): void => {
    setDebit(val);
    setPayment({ ...payment, ...val });
  };

  const handleDirectDebitBoxChecked = (): void => {
    setPayment({
      ...payment,
      isDirectDebitBoxChecked:
        payment?.isDirectDebitBoxChecked === undefined ? true : !payment.isDirectDebitBoxChecked
    });
  };

  const showPayments = (paymentOption: ApiPaymentOption): boolean | undefined => {
    return (
      (!tenant ||
        (!!tenant &&
          tenant !== Tenant.MB &&
          getCountries().find(country => country.code === countryCode)?.isEuEeaCountry)) &&
      (!paymentExcludedCurrencies[paymentOption] ||
        (!!paymentExcludedCurrencies[paymentOption] &&
          !paymentExcludedCurrencies[paymentOption].includes(appService.currency)))
    );
  };

  return (
    <div className="payment_selection pt-4">
      {store.initPaymentResponse && store.initPaymentResponse.clientSession && (
        <ContentContainer borderRadius>
          <CustomInput
            type="radio"
            className={`camper__checkbox ${
              payment.paymentOption === ApiPaymentOption.CREDITCARD && payment.ccToken
                ? 'payment__auccess'
                : payment.paymentOption === ApiPaymentOption.CREDITCARD &&
                  showErrors &&
                  !payment.ccToken
                ? 'form__error__top'
                : ''
            }`}
            name={ApiPaymentOption.CREDITCARD.toString()}
            id={ApiPaymentOption.CREDITCARD.toString()}
            onChange={handleChange}
            checked={payment.paymentOption === ApiPaymentOption.CREDITCARD}
            label={
              <>
                <b className="font-weight-boldest">{t(Keys.credit_card)}</b>
                <div className="payment__sub-label">{t(Keys.creditCardText)}</div>
              </>
            }
          />
          <Collapse isOpen={payment.paymentOption === ApiPaymentOption.CREDITCARD}>
            <PayonePaymentValidation
              key={store.initPaymentResponse.clientSession}
              initPaymentResponse={store.initPaymentResponse}
              paymentOption={ApiPaymentOption.CREDITCARD}
              supportedCreditCards={supportedCreditCards}
              onValid={onCCValid}
              onError={onCCError}
              isActive={payment.paymentOption === ApiPaymentOption.CREDITCARD}
            />
          </Collapse>
        </ContentContainer>
      )}
      {showPayments(ApiPaymentOption.DIRECTDEBIT) && (
        <ContentContainer borderRadius>
          <CustomInput
            type="radio"
            className={`camper__checkbox ${
              payment.paymentOption === ApiPaymentOption.DIRECTDEBIT &&
              payment.iban &&
              payment.holder
                ? 'payment__success'
                : payment.paymentOption === ApiPaymentOption.DIRECTDEBIT &&
                  showErrors &&
                  (!payment.iban || !payment.holder)
                ? 'form__error__top'
                : ''
            }`}
            name={ApiPaymentOption.DIRECTDEBIT.toString()}
            id={ApiPaymentOption.DIRECTDEBIT.toString()}
            onChange={handleChange}
            checked={payment.paymentOption === ApiPaymentOption.DIRECTDEBIT}
            label={
              <>
                <b className="font-weight-boldest">{t(Keys.debit_charge)}</b>
                <div className="payment__sub-label">{t(Keys.directDebit)}</div>
              </>
            }
          />
          <Collapse isOpen={payment.paymentOption === ApiPaymentOption.DIRECTDEBIT}>
            <div className="payment__direct-debit-checked">
              <CustomInput
                type="checkbox"
                className="camper__checkbox"
                id={`${ApiPaymentOption.DIRECTDEBIT.toString()}-checkbox`}
                checked={payment?.isDirectDebitBoxChecked || false}
                onChange={handleDirectDebitBoxChecked}
                label={t(Keys.directDebitCollapse1)}
              />
              <Collapse isOpen={payment?.isDirectDebitBoxChecked}>
                <div className="payment__direct-debit-checked__content">
                  <p>{t(Keys.directDebitCollapse2)}</p>
                  <p className="font-weight-boldest">{t(Keys.directDebitCollapse3)}</p>
                  <p>{t(Keys.directDebitCollapse4)}</p>
                  <p className="font-weight-boldest">{t(Keys.directDebitCollapse5)}</p>
                  <ul>
                    <li>
                      <div>{t(Keys.directDebitCollapse6)}</div>
                    </li>
                    <li>
                      <div>{t(Keys.directDebitCollapse7)}</div>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </div>
            <DebitChargeForm
              isActive={payment.paymentOption === ApiPaymentOption.DIRECTDEBIT}
              onSubmit={onSubmitDebitCharge}
              isDirectDebitBoxChecked={payment?.isDirectDebitBoxChecked || false}
            />
          </Collapse>
        </ContentContainer>
      )}
      {showPayments(ApiPaymentOption.INVOICE) && (
        <ContentContainer borderRadius>
          <CustomInput
            type="radio"
            className="camper__checkbox"
            name={ApiPaymentOption.INVOICE.toString()}
            id={ApiPaymentOption.INVOICE.toString()}
            onChange={handleChange}
            checked={payment.paymentOption === ApiPaymentOption.INVOICE}
            label={
              <>
                <b className="font-weight-boldest">{t(Keys.invoice)}</b>
                <div className="payment__sub-label">{t(Keys.invoiceText)}</div>
              </>
            }
          />
        </ContentContainer>
      )}
    </div>
  );
});

export default PaymentSection;
