import { CmbConfigModel } from './Config/CmbConfigModel';

export const TRAVELERS_ADULTS_MIN_AGE = 12;

const cmbDefaultConfig: CmbConfigModel = {
  baseUrl: '',
  payoneUrl: '',
  tenant: undefined,
  insuranceOfferCountries: [],
  isSDPLoaded: false
};

export default cmbDefaultConfig;
