import React, {
  Reducer,
  ReducerAction,
  ReducerState,
  useReducer,
  useMemo,
  forwardRef,
  ForwardedRef,
  useImperativeHandle,
  useState
} from 'react';
import {
  ApiCaptureData,
  ApiCaptureDataValue,
  ApiCaptureDataValueFromJSON,
  ApiUpdateCaptureDataRequest,
  ApiUpdateCaptureDataRequestFromJSON
} from '@ibe/api';
import fallback from '../../../../Translations/generated/customer.de.json';
import Keys from '../../../../Translations/generated/customer.de.json.keys';
import { useConfig, useTranslation } from '@ibe/components';
import { TFunction } from 'react-i18next';
import dayjs from 'dayjs';
import { CaptureDate, CaptureFlightTime, CaptureTime } from './CaptureComponents';
import {
  Action,
  CaptureFlightDataRef,
  CaptureFlightReducer,
  CaptureProps,
  ComponentMapping,
  Props,
  State,
  StateItem,
  ValueType
} from './types';
import { ConfigModel } from '@ibe/services';

export const transformToCaptureDataRequest = (
  state: State,
  config: ConfigModel
): ApiUpdateCaptureDataRequest => {
  function transform(item: StateItem): ApiCaptureDataValue {
    const { isUnknown, value } = item;

    if (value instanceof Date) {
      return ApiCaptureDataValueFromJSON({
        isUnknown,
        date: dayjs(value).format(config.formatDate.toUpperCase())
      });
    } else if (Array.isArray(value)) {
      return ApiCaptureDataValueFromJSON({
        isUnknown,
        flightNumber: value[0],
        time: value[1]
      });
    } else if (typeof value === 'string') {
      return ApiCaptureDataValueFromJSON({
        isUnknown,
        time: value
      });
    } else {
      return ApiCaptureDataValueFromJSON({
        isUnknown
      });
    }
  }

  return ApiUpdateCaptureDataRequestFromJSON({
    data: Object.entries(state).reduce(
      (total: ApiUpdateCaptureDataRequest, [id, item]: [string, StateItem]) => {
        return { ...total, [id]: transform(item) };
      },
      {}
    )
  });
};

const getComponentMapping = (code: string, t: TFunction): ComponentMapping | undefined => {
  return {
    '130': { component: CaptureDate, labels: [t(Keys.arrivalDate)] },
    '252': { component: CaptureTime, labels: [t(Keys.dropOffTime)] },
    '254': { component: CaptureTime, labels: [t(Keys.pickUpTime)] },
    '366': {
      component: CaptureFlightTime,
      labels: [t(Keys.arrivalFlightNumber), t(Keys.timeOfArrival)]
    },
    '367': {
      component: CaptureFlightTime,
      labels: [t(Keys.departureFlightNumber), t(Keys.timeOfDeparture)]
    }
  }[code];
};

const validateValue = (type: Action['type'], value: ValueType): boolean => {
  switch (type) {
    case 'DATE': {
      if (!(value instanceof Date)) return true;
      return !value;
    }
    case 'TIME': {
      if (typeof value !== 'string') return true;
      return !value;
    }
    case 'FLIGHT_TIME': {
      if (!Array.isArray(value)) return true;
      return !value[0] || !value[1];
    }
    default:
      return true;
  }
};

const getInitialState = (captureData: ApiCaptureData[], t: TFunction): State => {
  return captureData.reduce((total: State, current: ApiCaptureData) => {
    return !!getComponentMapping(current?.code, t)
      ? {
          ...total,
          [current.id]: {
            code: current.code,
            value: undefined,
            isUnknown: false,
            hasError: true
          }
        }
      : { ...total };
  }, {});
};

const reducer: Reducer<ReducerState<CaptureFlightReducer>, ReducerAction<CaptureFlightReducer>> = (
  state,
  action
): State => {
  const { id, code, value, isUnknown } = action.payload;
  return {
    ...state,
    [id]: {
      code,
      value,
      isUnknown,
      hasError: isUnknown ? false : validateValue(action.type, value)
    }
  };
};

const ComponentMapper = (props: Omit<CaptureProps, 'labels'>): JSX.Element => {
  const {
    captureDataItem: { code, id }
  } = props;
  const { t } = useTranslation('customer', fallback);
  const [keyCounter, setKeyCounter] = useState<number>(0);
  const Mapping = useMemo(() => getComponentMapping(code?.toString(), t), []);

  const remount = (): void => setKeyCounter(value => ++value);

  return !!Mapping ? (
    <Mapping.component
      key={`${id}-${keyCounter}`}
      {...props}
      labels={Mapping.labels}
      remount={remount}
    />
  ) : (
    <></>
  );
};

const CaptureFlightData = forwardRef(function CaptureFlightData(
  props: Props,
  ref: ForwardedRef<CaptureFlightDataRef>
): JSX.Element {
  const { captureData, isFormDirty } = props;
  const config = useConfig();
  const { t } = useTranslation('customer', fallback);

  const [state, dispatch] = useReducer(reducer, getInitialState(captureData, t));

  useImperativeHandle(
    ref,
    () => ({
      getState: () => ({
        data: transformToCaptureDataRequest(state, config),
        hasErrors: Object.entries(state).some(([, item]) => item.hasError)
      })
    }),
    [state]
  );

  return (
    <div className="capture-data">
      <div className="my-4">{t(Keys.captureDataSubHeadline)}</div>
      {captureData.map(captureDataItem =>
        !!captureDataItem.code ? (
          <div key={captureDataItem.id}>
            <ComponentMapper
              captureDataItem={captureDataItem}
              dispatch={dispatch}
              value={state[captureDataItem.id]?.value}
              isUnknown={state[captureDataItem.id]?.isUnknown}
              isFormDirty={isFormDirty}
              hasError={state[captureDataItem.id]?.hasError}
            />
          </div>
        ) : (
          <></>
        )
      )}
    </div>
  );
});

export default CaptureFlightData;
