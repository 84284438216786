import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'reactstrap';
import { useBeforeLeavingWindow, useConfig, useTranslation } from '@ibe/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons/faPhoneAlt';
import fallback from '../Translations/generated/leaveModal.de.json';
import Keys from '../Translations/generated/leaveModal.de.json.keys';

const LeaveModal = (): JSX.Element => {
  const storageKey = 'der-cmb-leave-modal-set';

  const { t } = useTranslation('leaveModal', fallback);
  const { windowIsLeft, setWindowIsLeft } = useBeforeLeavingWindow(storageKey);
  const [showModal, setShowModal] = useState(false);
  const config = useConfig();

  useEffect(() => {
    if (windowIsLeft) {
      if (document.getElementsByClassName('camper-loading').length > 0) {
        sessionStorage.removeItem(storageKey);
        setWindowIsLeft(false);
      } else {
        setShowModal(true);
      }
    }
  }, [windowIsLeft]);

  return (
    <Modal className="leave-modal" isOpen={showModal} wrapClassName={config.modalContainerClass}>
      <h2>{t(Keys.modalText1)}</h2>
      <div>{t(Keys.modalText2)}</div>
      <div className="leave-modal__inner">
        <div className="leave-modal__phone">
          <FontAwesomeIcon icon={faPhoneAlt} />
          <div>
            <a href={`tel:${t(Keys.modalText3)}`}>{t(Keys.modalText3)}</a>
            <div>{t(Keys.modalText4)}</div>
            <div>{t(Keys.modalText5)}</div>
          </div>
        </div>
        <Button
          color="primary"
          onClick={(): void => {
            setShowModal(false);
            setWindowIsLeft(false);
          }}
        >
          {t(Keys.modalSubmit)}
        </Button>
      </div>
    </Modal>
  );
};

export default LeaveModal;
