import {
  ApiBooking,
  ApiPayment,
  Configuration,
  HTTPHeaders,
  HTTPQuery,
  JSONApiResponse,
  RequiredError
} from '@ibe/api';
import { InitPaymentResponse } from '../Models/payment/api/InitPaymentResponse';
import { ApiService } from '@ibe/services';

export class PayoneApiService extends ApiService {
  constructor(configuration = new Configuration()) {
    super(configuration);
  }

  async initPayment(bookingId: string): Promise<InitPaymentResponse> {
    if (bookingId === null || bookingId === undefined) {
      throw new RequiredError(
        'initPayment',
        'Required parameter bookingId was null or undefined when calling initPayment.'
      );
    }

    const queryParameters: HTTPQuery = {};
    const headerParameters: HTTPHeaders = {};
    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-IBE-Authorization'] = this.configuration.apiKey('X-IBE-Authorization'); // bearerAuth authentication
    }

    const response = await super.request({
      path: `/payone/preparePayment/{bookingId}`.replace(
        `{${'bookingId'}}`,
        encodeURIComponent(String(bookingId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters
    });

    const apiResponse = new JSONApiResponse(response, jsonValue => {
      return {
        clientSession: jsonValue.clientSession,
        clientConfiguration: jsonValue.clientConfiguration,
        messages: jsonValue.messages
      } as InitPaymentResponse;
    });

    return apiResponse.value();
  }

  async addPayment(bookingId: string, payment: ApiPayment): Promise<ApiBooking> {
    if (bookingId === null || bookingId === undefined) {
      throw new RequiredError(
        'addPayment',
        'Required parameter bookingId was null or undefined when calling initPayment.'
      );
    }

    const queryParameters: HTTPQuery = {};
    const headerParameters: HTTPHeaders = {};
    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-IBE-Authorization'] = this.configuration.apiKey('X-IBE-Authorization'); // bearerAuth authentication
    }

    const response = await super.request({
      path: `/payone/addPayment/{bookingId}`.replace(
        `{${'bookingId'}}`,
        encodeURIComponent(String(bookingId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: { ...payment, ...{ type: 'Payment' } }
    });

    const apiResponse = new JSONApiResponse(response, jsonValue => {
      return jsonValue as ApiBooking;
    });

    return apiResponse.value();
  }
}
