/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-12-23 12:21:24 */

const Keys = {
  moreInformation: 'moreInformation',
  accept: 'accept',
  customTitle: 'customTitle',
  customMapDescription: 'customMapDescription',
  customVideoDescription: 'customVideoDescription',
  cookieSettings: 'cookieSettings'
}; export default Keys;