import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Payment } from './Payment';
import { Button, Col, Row } from 'reactstrap';
import { Success, useTranslation } from '@ibe/components';
import fallback from '../../../../Translations/customer.de.json';
import Keys from '../../../../Translations/generated/customer.de.json';
import { ApiPaymentOption } from '@ibe/api';

export interface DebitChargeProps {
  isActive: boolean;
  onSubmit: (payment: Payment | null) => void;
  isDirectDebitBoxChecked: boolean;
}

export const DebitChargeForm = observer(
  (props: DebitChargeProps): JSX.Element => {
    const { isActive, onSubmit, isDirectDebitBoxChecked } = props;
    const [holderVal, setHolderVal] = useState<string>();
    const [ibanVal, setIbanVal] = useState<string>();
    const [bicVal, setBicVal] = useState<string>();
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [ibanValid, setIbanValid] = useState<boolean>(true);
    const [holderValid, setHolderValid] = useState<boolean>(true);
    const [bicValid, setBicValid] = useState<boolean>(true);

    const { t } = useTranslation('customer', fallback);

    const submit = () => {
      if (holderVal && ibanVal) {
        onSubmit({
          holder: holderVal,
          iban: ibanVal,
          bic: bicVal,
          option: ApiPaymentOption.DebitDE
        } as Payment);
        setSubmitted(true);
      }
    };

    const validateHolder = (value: string) => {
      setHolderValid(value.length > 1);
    };

    const validateIban = (value: string) => {
      const plain = value.replaceAll(' ', '');
      setIbanValid(plain.match(/[A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{11,35}/g)?.length === 1);
    };

    const validateBic = (value: string) => {
      setBicValid(value.length === 8);
    };

    const holder: JSX.Element = (
      <div className={holderValid ? '' : 'invalid'}>
        <input
          required={true}
          className="payone-input-mock payone-input-mock--holder"
          type="text"
          value={holderVal}
          placeholder={t(Keys.holder)}
          onChange={event => setHolderVal(event.target.value)}
          onBlur={event => validateHolder(event.target.value)}
        />
      </div>
    );

    const iban: JSX.Element = (
      <div className={ibanValid ? '' : 'invalid'}>
        <input
          required={true}
          className="payone-input-mock payone-input-mock--iban"
          type="text"
          value={ibanVal}
          placeholder={t(Keys.iban)}
          maxLength={48} // padded with whitespace -> plain 22
          minLength={27} // padded with whitespace -> plain 22
          onChange={event => {
            const plain = event.target.value.replaceAll(' ', '');
            const parts = plain.match(/.{1,4}/g);
            setIbanVal(parts?.join(' '));
          }}
          onBlur={event => validateIban(event.target.value)}
        />
      </div>
    );

    const bic: JSX.Element = (
      <div className={bicValid ? '' : 'invalid'}>
        <input
          className="payone-input-mock payone-input-mock--bic"
          type="text"
          value={bicVal}
          maxLength={8}
          minLength={8}
          placeholder={t(Keys.bic)}
          onChange={event => setBicVal(event.target.value)}
          onBlur={event => validateBic(event.target.value)}
        />
      </div>
    );

    return (
      <div className="payone-cc">
        <form id="debitCharge-form" className=" " action="#" method="post">
          <Row>
            <Col md={5}>
              <div className="form-group">{holder}</div>
            </Col>

            <Col md={5}>
              <div className="form-group">{bic}</div>
            </Col>

            <Col md={12}>
              <div className="form-group">{iban}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group d-flex justify-content-end">
                <Button
                  disabled={
                    !isActive ||
                    !holderVal ||
                    !ibanVal ||
                    !bicVal ||
                    !ibanValid ||
                    !bicValid ||
                    !holderValid ||
                    !isDirectDebitBoxChecked
                  }
                  color="secondary"
                  onClick={submit}
                >
                  {t(Keys.submit)}
                </Button>
              </div>
            </Col>
          </Row>
        </form>
        <div>
          <div>{submitted && <Success message={t(Keys.message_bankdata_submitted)} />}</div>
        </div>
      </div>
    );
  }
);
