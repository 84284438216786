import { Configuration } from '@ibe/api';
import { AppService, AuthService, removeTrailingSlashes } from '@ibe/services';
import { ExtendedApiService } from './ExtendedApiService';

class ApiService {
  constructor(private authService: AuthService, private appService: AppService) {}

  get api(): ExtendedApiService {
    return new ExtendedApiService(
      new Configuration({
        basePath: removeTrailingSlashes(this.appService.config.apiUrl),
        apiKey: this.authService.state.token || undefined,
        headers: {
          'Accept-Language': this.appService.lang,
          Currency: this.appService.currency,
          Tenant: this.appService.tenant || '',
          Affiliate: this.appService.affiliate || ''
        }
      })
    );
  }
}

export default ApiService;
