import { StepType } from '@ibe/services';
import Traveller from './Pages/Checkout/components/Traveller';
import Customer from './Pages/Checkout/components/Customer';
import { CheckoutStep } from './Models/CheckoutStep';
import Confirmation from './Pages/Checkout/components/Confirmation';

const workflowCheckout: CheckoutStep[] = [
  {
    type: StepType.CAMP_CHECKOUT_TRAVELLERS,
    slug: 'traveller',
    title: '',
    label: 'Traveller',
    component: Traveller,
    steps: null
  },
  {
    type: StepType.CAMP_CHECKOUT_CUSTOMER,
    slug: 'customer',
    title: '',
    label: 'Customer',
    component: Customer,
    steps: null
  },
  {
    type: StepType.CAMP_CHECKOUT_CONFIRMATION,
    slug: 'confirmation',
    title: '',
    label: 'Confirmation',
    component: Confirmation,
    steps: null
  }
];

export default workflowCheckout;
