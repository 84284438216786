import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import XHR from 'i18next-xhr-backend';

import { Language } from './index';

const order = ['querystring', 'localStorage', 'navigator'];

const initI18next = async (baseUrl?: string, version?: string): Promise<void> => {
  await i18next
    .use(initReactI18next)
    .use(Backend)
    .init({
      fallbackLng: Language.EN,
      whitelist: [Language.EN, Language.DE],
      lng: Language.DE,
      debug: false,
      cleanCode: true,
      saveMissing: false,
      fallbackNS: false,
      ns: [],
      interpolation: {
        escapeValue: false
      },
      react: {
        useSuspense: false
      },
      backend: {
        backends: [XHR],
        allowMultiLoading: true,
        backendOptions: [
          {
            loadPath: `${baseUrl || ''}/translations/generated/{{ns}}.{{lng}}.json`,
            queryStringParams: { v: `${version ? version : '1.0.0'}` }
          }
        ]
      },
      detection: {
        order
      }
    });
};

export default initI18next;
