import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Error, Success, useScript, useTranslation } from '@ibe/components';
import { Button, Row, Col } from 'reactstrap';
import CreditCardType from '../../../../Models/payment/CreditCardType';
import fallback from '../../../../Translations/generated/customer.de.json';
import Keys from '../../../../Translations/generated/customer.de.json.keys';
import { InitPaymentResponse } from '../../../../Models/payment/api/InitPaymentResponse';
import { ApiPaymentOption } from '@ibe/api';
import useCmbConfig from '../../../../Hooks/useCmbConfig';
import useAssetPath from '../../../../Hooks/useAssetPath';

export interface PayoneValidationProps {
  initPaymentResponse: InitPaymentResponse | null;
  paymentOption: ApiPaymentOption.CREDITCARD | ApiPaymentOption.DIRECTDEBIT;
  isActive: boolean;
  supportedCreditCards: CreditCardType[];
  onValid: (
    token: string | null,
    ccType: CreditCardType | null,
    paddedPan: string | null,
    expiryDate: string | null
  ) => void;
  onError: () => void;
  submitDebitLabel?: string;
  submitCCLabel?: string;
  triggerFormValidate?: boolean;
}

enum FormSelector {
  PAYONE_CC_PAN = 'payone-cc-pan',
  PAYONE_CC_CVC = 'payone-cc-cvc',
  PAYONE_CC_EXPIRE = 'payone-cc-expire',
  PAYONE_CC_HOLDER = 'payone-cc-holder',
  PAYONE_CC_IBAN = 'payone-cc-iban',
  PAYONE_CC_BIC = 'payone-cc-bic',
  PAYONE_CC_RESPONSE_WRAP = 'cc-check-response-wrap'
}

declare const window: {
  integration: Integration;
};

export const PayonePaymentValidation = observer(function PayonePaymentValidation(
  props: PayoneValidationProps
): JSX.Element {
  const {
    paymentOption,
    isActive,
    supportedCreditCards,
    onValid,
    onError,
    initPaymentResponse,
    submitCCLabel,
    submitDebitLabel
  } = props;

  const [accepted, setAccepted] = useState<boolean>(false);
  const [instance, setInstance] = useState<Instance | null>(null);

  const [activeCardType, setActiveCardType] = useState<CreditCardType | undefined>(undefined);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [payOneCheckResponse, setPayOneCheckResponse] = useState<
    CreateTokenError | CreateTokenSuccess | null
  >();

  const { t } = useTranslation('customer', fallback);

  const cmbConfig = useCmbConfig();
  const assetBasePath = useAssetPath();
  const scriptLoaded = useScript(cmbConfig.payoneUrl, true);

  const handleCardAutoDetect = (cardType: string): void => {
    setActiveCardType(
      Object.values(CreditCardType).includes(cardType as CreditCardType)
        ? (cardType as CreditCardType)
        : undefined
    );
  };

  const createOptions = (type: ApiPaymentOption): Options => {
    let opt;
    const statix = {
      clientSession: initPaymentResponse?.clientSession,
      clientConfiguration: initPaymentResponse?.clientConfiguration,
      acs_window: {
        width: 700,
        height: 600
      }
    };

    if (type === ApiPaymentOption.CREDITCARD) {
      opt = {
        type: 'creditCard',
        fields: {
          holder: {
            selector: '#' + FormSelector.PAYONE_CC_HOLDER + '_' + paymentOption,
            placeholder: t(Keys.holder)
          },
          number: {
            selector: '#' + FormSelector.PAYONE_CC_PAN,
            placeholder: t(Keys.card_number)
          },
          code: {
            selector: '#' + FormSelector.PAYONE_CC_CVC,
            placeholder: t(Keys.cvc)
          },
          expiry: {
            selector: '#' + FormSelector.PAYONE_CC_EXPIRE,
            placeholder: t(Keys.expire)
          }
        },
        styles: {
          input: {
            'font-family': 'Arial',
            'font-size': '17px',
            color: '#282e39'
          },
          'input::placeholder': {
            color: '#282e39'
          },
          '.ifs-invalid': {
            color: 'red'
          }
        }
      };
    } else {
      opt = {
        type: 'bankAccount',
        fields: {
          holder: {
            selector: '#' + FormSelector.PAYONE_CC_HOLDER + '_' + paymentOption,
            placeholder: t(Keys.holder)
          },
          iban: {
            selector: '#' + FormSelector.PAYONE_CC_IBAN,
            placeholder: t(Keys.iban)
          },
          bic: {
            selector: '#' + FormSelector.PAYONE_CC_BIC,
            placeholder: t(Keys.bic)
          }
        }
      };
    }
    return { ...statix, ...opt } as Options;
  };

  const addEvents = (instanceImpl: Instance): void => {
    instanceImpl.on(
      'formValidationChange',
      (event: { valid: boolean; completed: boolean; accepted: boolean }) => {
        setAccepted(event.completed && event.valid);
      }
    );
    instanceImpl.on('cardBrandChange', (event: { type: string }) => {
      handleCardAutoDetect(event.type);
    });
    // instanceImpl.on('activeStateChange', (event: { isActive: boolean }) => {});
    // instanceImpl.on('ibanTypeChange', (event: any) => {});
  };

  useEffect(() => {
    if (scriptLoaded && !initialized && initPaymentResponse) {
      const options: Options = createOptions(paymentOption);

      window.integration?.initialize(options).then(val => {
        if (val) {
          setInstance(val);
          setInitialized(true);
          addEvents(val);
        }
      });
    }
  }, [initPaymentResponse, scriptLoaded, initialized, paymentOption]);

  const handleCreateTokenResponse = (
    error: CreateTokenError,
    response: CreateTokenSuccess
  ): void => {
    setPayOneCheckResponse(error || response);
    if (error) {
      onError();
    } else if (response?.token) {
      onValid(
        response?.token,
        activeCardType || null,
        response?.info?.creditCard?.paddedPan,
        response?.info?.creditCard?.expiry
      );
    } else {
      onValid(null, null, null, null);
    }
  };

  const doCheck = () => {
    setPayOneCheckResponse(null);
    instance?.createToken(handleCreateTokenResponse);
  };

  const ccPan: JSX.Element = (
    <div className={`payone-input-mock payone-input-mock--pan`}>
      <span id={FormSelector.PAYONE_CC_PAN} />
    </div>
  );

  const ccCvc: JSX.Element = (
    <div className={`payone-input-mock payone-input-mock--cvc`}>
      <span id={FormSelector.PAYONE_CC_CVC} />
    </div>
  );

  const expire: JSX.Element = (
    <div className="payone-input-mock payone-input-mock--expire">
      <span id={FormSelector.PAYONE_CC_EXPIRE} />
    </div>
  );

  const holder: JSX.Element = (
    <div className="payone-input-mock payone-input-mock--holder">
      <span id={FormSelector.PAYONE_CC_HOLDER + '_' + paymentOption} />
    </div>
  );

  const iban: JSX.Element = (
    <div className="payone-input-mock payone-input-mock--iban">
      <span id={FormSelector.PAYONE_CC_IBAN} />
    </div>
  );

  const bic: JSX.Element = (
    <div className="payone-input-mock payone-input-mock--bic">
      <span id={FormSelector.PAYONE_CC_BIC} />
    </div>
  );

  const cardTypeSelection: JSX.Element = (
    <span className="payone-cc-icons-wrap d-flex flex-wrap" role="listbox">
      {supportedCreditCards.map(ccType => (
        <span
          key={ccType}
          aria-selected={activeCardType === ccType}
          tabIndex={0}
          className={`payone-cc-icon__container mr-5 mb-3 ${
            activeCardType === ccType ? 'payone-cc-icon__container--selected' : ''
          }`}
        >
          <img
            className="payone-cc-icon"
            src={`${assetBasePath}/cc/${ccType}.png`}
            alt={`${ccType} Icon`}
            data-cc-type={ccType}
            key={`img_${ccType}`}
          />
        </span>
      ))}
    </span>
  );

  return (
    <div className="payone-cc">
      {paymentOption === ApiPaymentOption.CREDITCARD && cardTypeSelection}

      <form id={'cc-form-' + paymentOption} className=" " action="#" method="post">
        <Row>
          <Col md={paymentOption === ApiPaymentOption.CREDITCARD ? 10 : 12}>
            <div className="form-group">
              {paymentOption === ApiPaymentOption.CREDITCARD ? ccPan : iban}
            </div>
          </Col>

          {paymentOption === ApiPaymentOption.CREDITCARD && (
            <Col md={2}>
              <div className="form-group">{ccCvc}</div>
            </Col>
          )}

          <Col md={5}>
            <div className="form-group">{holder}</div>
          </Col>

          <Col md={5}>
            <div className="form-group">
              {paymentOption === ApiPaymentOption.CREDITCARD ? expire : bic}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              className="payone__check"
              disabled={!isActive || !accepted}
              color="secondary"
              onClick={doCheck}
            >
              {paymentOption === ApiPaymentOption.CREDITCARD && (submitCCLabel || t(Keys.validate))}
              {paymentOption === ApiPaymentOption.DIRECTDEBIT &&
                (submitDebitLabel || t(Keys.validate))}
            </Button>
          </Col>
        </Row>
      </form>
      <div className="pr-3">
        <div id={FormSelector.PAYONE_CC_RESPONSE_WRAP}>
          {(payOneCheckResponse as CreateTokenError)?.detail && (
            <Error message={t(Keys.message_error_CREDIT_CARD)} />
          )}
          {(payOneCheckResponse as CreateTokenSuccess)?.token &&
            paymentOption === ApiPaymentOption.DIRECTDEBIT && (
              <Success message={t(Keys.message_success_DEBIT_CHARGE)} />
            )}
          {(payOneCheckResponse as CreateTokenSuccess)?.token &&
            paymentOption === ApiPaymentOption.CREDITCARD && (
              <Success message={t(Keys.message_success_CREDIT_CARD)} />
            )}
        </div>
      </div>
    </div>
  );
});

interface Integration {
  initialize: (options: Options) => Promise<Instance>;
  createToken: (callback: (error: never, response: never) => void) => void;
}

interface Instance {
  on: (event: string, callback: (event: never) => void) => void;
  createToken: (callback: (error: never, response: never) => void) => void;
}

interface Options {
  clientSession: string;
  clientConfiguration: string;
  type: 'creditCard' | 'bankAccount';
  fields:
    | {
        holder: { selector: FormSelector.PAYONE_CC_HOLDER; placeholder: string };
        number: { selector: string; placeholder: string };
        code: { selector: string; placeholder: string };
        expiry: { selector: string; placeholder: string };
      }
    | {
        holder: { selector: string; placeholder: string };
        iban: { selector: string; placeholder: string };
        bic: { selector: string; placeholder: string };
      };
  styles: {};
  acs_window: {
    width: 700;
    height: 600;
  };
}
interface CreateTokenError {
  category: string;
  type: string;
  detail: string;
}
interface CreateTokenSuccess {
  token: string;
  info: { creditCard: { paddedPan: string; expiry: string } };
}
