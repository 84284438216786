import React, { Suspense, useState } from 'react';
import { MemoryRouter } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { useMount } from 'react-use';
import { observer } from 'mobx-react';
import 'dayjs/locale/de';
import { Overlay, CamperSearchParams, useAppService } from '@ibe/components';
import { ConfigModel, LoggerFactory } from '@ibe/services';
import App from '../App';
import { boot, extendedConfigContext, insuranceOfferCountries } from '../Config/config';
import initI18next from '../Translations/setup';
import '../Theme/Styles/DER/dto/Fonts.scss';
import '../Theme/Styles/DER/dto/Styles.scss';
import theme from '../Theme/Styles/DER/dto/theme';
import CamperLoading from '../Components/CamperLoading';
import { CmbConfigModel } from '../Config/CmbConfigModel';
import { loadCMP, loadSDP } from '../utils/loadCMP';
import { CMPConsent, CMPConsentContextProvider } from '../Pages/Checkout/hooks/useCMPConsent';
import loadExternalWidgetConfig from '../utils/loadExternalWidgetConfig';
import { ApiAffiliateRequestFromJSON } from '@ibe/api';
import ApplyBranding from '../utils/ApplyBranding';

const logger = LoggerFactory.get('IbeCheckoutWidget');

const IbeCheckoutWidget = observer(function IbeCheckoutWidget({
  cmbConfig,
  rerouteUrl,
  searchParams,
  configOverrides = {},
  affiliateNumber,
  apiKey,
  initialCmpConsent,
  disableResultListLazyLoading,
  disableLeaveModal
}: {
  cmbConfig: CmbConfigModel;
  rerouteUrl?: string;
  configOverrides?: Partial<ConfigModel>;
  searchParams?: Partial<CamperSearchParams>;
  affiliateNumber: string;
  apiKey: string;
  initialCmpConsent: CMPConsent;
  disableResultListLazyLoading?: boolean;
  disableLeaveModal?: boolean;
}) {
  const [bootstrappingFinished, setBootstrappingFinished] = useState<boolean>(false);
  const [isSDPLoaded, setIsSDPLoaded] = useState<boolean>(false);
  const [useCustomCMP, setUseCustomCMP] = useState<boolean>(false);
  const [cssVars, setCssVars] = useState<{ [key: string]: string } | undefined>(undefined);
  const [isAffiliateInvalid, setIsAffiliateInvalid] = useState<boolean>(false);
  const appService = useAppService();

  const toggleSDPLoaded = (): void => {
    setIsSDPLoaded(true);
  };

  useMount(async () => {
    let baseUrlWithContext: string | undefined;
    const widgetConfig = await loadExternalWidgetConfig();
    if (widgetConfig) {
      baseUrlWithContext = widgetConfig.baseUrl;
      cmbConfig.baseUrl = baseUrlWithContext;
      cmbConfig.apiUrl = widgetConfig.apiUrl;
      if (widgetConfig.payoneUrl) {
        cmbConfig.payoneUrl = widgetConfig.payoneUrl;
      }
      configOverrides.configUrl = widgetConfig.configUrl;
      cmbConfig.version = widgetConfig.version;
      cmbConfig.affiliateConsentManagerId = widgetConfig.affiliateConsentManagerId;
      cmbConfig.consentManagerUrl = widgetConfig.consentManagerUrl;
    }

    await initI18next(baseUrlWithContext, cmbConfig.version);
    await boot(configOverrides, false, cmbConfig, true, affiliateNumber);

    if (affiliateNumber && apiKey) {
      try {
        const res = await appService.api.getAffiliateData(
          ApiAffiliateRequestFromJSON({
            number: affiliateNumber,
            apiKey: apiKey
          })
        );
        setCssVars(res.cssVars);
        if (
          !res.useCustomCMP &&
          !!cmbConfig.affiliateConsentManagerId &&
          !!cmbConfig.consentManagerUrl
        ) {
          await loadCMP(cmbConfig.consentManagerUrl, cmbConfig.affiliateConsentManagerId, () => {
            loadSDP(toggleSDPLoaded);
          });
        } else {
          setUseCustomCMP(true);
        }
        sessionStorage.setItem('affiliate-agency-number', affiliateNumber);
      } catch (err) {
        logger.error(err);
        setIsAffiliateInvalid(true);
      }
    }
    setBootstrappingFinished(true);
  });

  return bootstrappingFinished ? (
    <>
      {!isAffiliateInvalid ? (
        <>
          {!!cssVars && <ApplyBranding colors={cssVars} />}
          <ThemeProvider theme={theme}>
            <Suspense
              fallback={
                <div
                  className="transparent-overlay"
                  style={{ height: '95px', position: 'relative' }}
                >
                  <Overlay fullOpacity customSpinner={<CamperLoading />} />
                </div>
              }
            >
              <extendedConfigContext.Provider
                value={{ ...cmbConfig, insuranceOfferCountries, isSDPLoaded }}
              >
                <CMPConsentContextProvider
                  useCustomCMP={useCustomCMP}
                  initialCmpConsent={initialCmpConsent}
                >
                  <MemoryRouter>
                    <App
                      widgetType="CMB"
                      rerouteUrl={rerouteUrl}
                      searchParams={searchParams}
                      affiliateNumber={affiliateNumber}
                      apiKey={apiKey}
                      useCustomCMP={useCustomCMP}
                      disableResultListLazyLoading={disableResultListLazyLoading}
                      disableLeaveModal={disableLeaveModal}
                    />
                  </MemoryRouter>
                </CMPConsentContextProvider>
              </extendedConfigContext.Provider>
            </Suspense>
          </ThemeProvider>
        </>
      ) : (
        <div>Wrong widget configuration, please check.</div>
      )}
    </>
  ) : (
    <div className="transparent-overlay" style={{ height: '95px', position: 'relative' }}>
      <Overlay fullOpacity customSpinner={<CamperLoading />} />
    </div>
  );
});

export default IbeCheckoutWidget;
