import { observable, action, makeObservable } from 'mobx';
import { CheckoutStep as Step } from '../Models/CheckoutStep';

class WorkflowService {
  steps: Step[] = [];

  currentStep: Step | null;

  constructor() {
    makeObservable(this, {
      steps: observable,
      currentStep: observable,
      setSteps: action
    });
  }

  get currentStepIndex(): number {
    if (this.currentStep) {
      return this.steps.findIndex(s => s.slug === this.currentStep?.slug);
    }
    return 0;
  }

  public setSteps(steps: Step[]): void {
    this.steps = steps;
  }

  public setCurrentStep(step: Step | null): void {
    this.currentStep = step;
  }

  public get nextStepSlug(): string {
    const index = this.currentStepIndex;
    const nextIndex = index + 1;
    if (typeof this.steps[nextIndex] !== 'undefined') {
      return this.steps[nextIndex].slug;
    }
    return '';
  }

  public get prevStepSlug(): string {
    const index = this.currentStepIndex;
    const prevIndex = index - 1;
    if (typeof this.steps[prevIndex] !== 'undefined') {
      return this.steps[prevIndex].slug;
    }
    return '';
  }

  public nextStepJumpSlug(numberOfSteps: number): string {
    const index = this.currentStepIndex;
    const nextIndex = index + numberOfSteps;
    if (typeof this.steps[nextIndex] !== 'undefined') {
      return this.steps[nextIndex].slug;
    }
    return '';
  }

  public prevStepJumpSlug(numberOfSteps: number): string {
    const index = this.currentStepIndex;
    const prevIndex = index - numberOfSteps;
    if (typeof this.steps[prevIndex] !== 'undefined') {
      return this.steps[prevIndex].slug;
    }
    return '';
  }
}

export default WorkflowService;
