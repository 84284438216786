import { Age, CamperInsuranceData } from '../../../Models/insurance/CamperInsuranceData';
import { CamperInsurances } from '@ibe/components';
import Keys from '../../../Translations/generated/camper-insurance.de.json.keys';
import dayjs from 'dayjs';

export function withCamperInsurances(
  insuranceDataList: Array<CamperInsuranceData> | undefined,
  customerAge: number,
  t: Function,
  travellerAges: Array<number>,
  startDate: string | undefined,
  bookingType: string | undefined
): CamperInsurances | undefined {
  if (!insuranceDataList) {
    return undefined;
  }
  const res: CamperInsurances = {
    name: t(Keys.headline),
    description: t(Keys.description),
    insurances: []
  };

  const moreThen2over25 = travellerAges.filter(age => age > 25).length > 2;
  const lessThen15DaysToStart =
    dayjs(startDate).startOf('day').diff(dayjs().startOf('day'), 'day') < 15;

  if (lessThen15DaysToStart && bookingType === 'OnRequest') {
    insuranceDataList = [];
  }
  if (insuranceDataList.length === 0) {
    res.description = t(Keys.remark);
  } else {
    if (moreThen2over25) {
      res.description = t(Keys.remark2);
      insuranceDataList = [];
    }
  }

  insuranceDataList.map((data, i) => {
    const units = data.units
      .filter(unitData => {
        return (
          unitData.age === Age.without ||
          (unitData.age === Age.toSixtyFour && customerAge <= 64) ||
          (unitData.age === Age.fromSixyFive && customerAge >= 65)
        );
      })
      .map(unit => {
        return {
          id: unit.id,
          code: unit.inventoryCode,
          name: unit.excess ? t(Keys.excess) : t(Keys.no_excess),
          itemType: data.covidProtection ? 'SPECIAL' : 'TRAVEL_INSURANCE',
          isSelected: false,
          price: unit.price
        };
      });

    res.insurances.push({
      id: 'camper_insurance_' + i,
      code: 'camper_insurance_' + i,
      itemType: data.covidProtection ? 'SPECIAL' : 'TRAVEL_INSURANCE',
      name: data.name,
      recommended: data.recommended,
      benefits: data.content,
      pdf: data.pdf,
      info: data.info,
      items: units,
      covidProtection: data.covidProtection
    });
  });

  return res;
}
