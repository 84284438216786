/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-12-23 12:21:24 */

const Keys = {
  payingCustomer: 'payingCustomer',
  street: 'street',
  houseNumber: 'houseNumber',
  city: 'city',
  zipCode: 'zipCode',
  country: 'country',
  phone: 'phone',
  email: 'email',
  confirmEmail: 'confirmEmail',
  inputRequired: 'inputRequired',
  zipCodeError: 'zipCodeError',
  emailError: 'emailError',
  confirmEmailError: 'confirmEmailError',
  next: 'next',
  prev: 'prev',
  summary: 'summary',
  billingInfo: 'billingInfo',
  mandatoryFields: 'mandatoryFields',
  payment: 'payment',
  tcs: 'tcs',
  tcsHeadline: 'tcsHeadline',
  bookingAttemptFailed: 'bookingAttemptFailed',
  insurance: 'insurance',
  additionalInformation: 'additionalInformation',
  captureDataSubHeadline: 'captureDataSubHeadline',
  unknown: 'unknown',
  arrivalDate: 'arrivalDate',
  arrivalFlightNumber: 'arrivalFlightNumber',
  timeOfArrival: 'timeOfArrival',
  departureFlightNumber: 'departureFlightNumber',
  timeOfDeparture: 'timeOfDeparture',
  pickUpTime: 'pickUpTime',
  dropOffTime: 'dropOffTime',
  errorText: 'errorText',
  newInvoiceRecipient: 'newInvoiceRecipient',
  mostUsed: 'mostUsed',
  allCountries: 'allCountries',
  addressInfoText: 'addressInfoText',
  emailInfoText: 'emailInfoText',
  phoneNumberInfoText: 'phoneNumberInfoText',
  term: 'term',
  term2: 'term2',
  url: 'url',
  url2: 'url2',
  urlLabel: 'urlLabel',
  urlLabel2: 'urlLabel2',
  privacy: 'privacy',
  privacyUrl: 'privacyUrl',
  privacyUrlLabel: 'privacyUrlLabel',
  privacyAdditionalText: 'privacyAdditionalText',
  paymentHiddenText: 'paymentHiddenText',
  paymentVisibleText: 'paymentVisibleText',
  credit_card: 'credit_card',
  invoice: 'invoice',
  debit_charge: 'debit_charge',
  creditCardText: 'creditCardText',
  invoiceText: 'invoiceText',
  directDebit: 'directDebit',
  directDebitCollapse1: 'directDebitCollapse1',
  directDebitCollapse2: 'directDebitCollapse2',
  directDebitCollapse3: 'directDebitCollapse3',
  directDebitCollapse4: 'directDebitCollapse4',
  directDebitCollapse5: 'directDebitCollapse5',
  directDebitCollapse6: 'directDebitCollapse6',
  directDebitCollapse7: 'directDebitCollapse7',
  card_number: 'card_number',
  cvc: 'cvc',
  expire_month: 'expire_month',
  expire_year: 'expire_year',
  expire: 'expire',
  holder: 'holder',
  iban: 'iban',
  bic: 'bic',
  submit: 'submit',
  validate: 'validate',
  message_bankdata_submitted: 'message_bankdata_submitted',
  message_success_CREDIT_CARD: 'message_success_CREDIT_CARD',
  message_error_CREDIT_CARD: 'message_error_CREDIT_CARD',
  message_success_DEBIT_CHARGE: 'message_success_DEBIT_CHARGE',
  message_unsupported: 'message_unsupported',
  message_autodetect_failed: 'message_autodetect_failed',
  DE: 'DE',
  BE: 'BE',
  NL: 'NL',
  AT: 'AT',
  CH: 'CH',
  AD: 'AD',
  EG: 'EG',
  AL: 'AL',
  AI: 'AI',
  AG: 'AG',
  AR: 'AR',
  AM: 'AM',
  AW: 'AW',
  AU: 'AU',
  BS: 'BS',
  BB: 'BB',
  BZ: 'BZ',
  BM: 'BM',
  BT: 'BT',
  BQ: 'BQ',
  BW: 'BW',
  BR: 'BR',
  BG: 'BG',
  KY: 'KY',
  CL: 'CL',
  CN: 'CN',
  CK: 'CK',
  CR: 'CR',
  CW: 'CW',
  DK: 'DK',
  DM: 'DM',
  DO: 'DO',
  EC: 'EC',
  EE: 'EE',
  FJ: 'FJ',
  FI: 'FI',
  FR: 'FR',
  PF: 'PF',
  GM: 'GM',
  GE: 'GE',
  GD: 'GD',
  GR: 'GR',
  GL: 'GL',
  GB: 'GB',
  GP: 'GP',
  GT: 'GT',
  HK: 'HK',
  IN: 'IN',
  ID: 'ID',
  IE: 'IE',
  IS: 'IS',
  IL: 'IL',
  IT: 'IT',
  JM: 'JM',
  JP: 'JP',
  JO: 'JO',
  KH: 'KH',
  CA: 'CA',
  BQKN: 'BQKN',
  KE: 'KE',
  CO: 'CO',
  HR: 'HR',
  CU: 'CU',
  LA: 'LA',
  LV: 'LV',
  LI: 'LI',
  LT: 'LT',
  LU: 'LU',
  MG: 'MG',
  MY: 'MY',
  MV: 'MV',
  MT: 'MT',
  MA: 'MA',
  MQ: 'MQ',
  MU: 'MU',
  MX: 'MX',
  MC: 'MC',
  MN: 'MN',
  ME: 'ME',
  MZ: 'MZ',
  MM: 'MM',
  NA: 'NA',
  NP: 'NP',
  NZ: 'NZ',
  NO: 'NO',
  OM: 'OM',
  PA: 'PA',
  PE: 'PE',
  PL: 'PL',
  PT: 'PT',
  PR: 'PR',
  QA: 'QA',
  RE: 'RE',
  RW: 'RW',
  RO: 'RO',
  SM: 'SM',
  RU: 'RU',
  ST: 'ST',
  SE: 'SE',
  SC: 'SC',
  SG: 'SG',
  SK: 'SK',
  SI: 'SI',
  ES: 'ES',
  LK: 'LK',
  KN: 'KN',
  LC: 'LC',
  VC: 'VC',
  ZA: 'ZA',
  KR: 'KR',
  TJ: 'TJ',
  TW: 'TW',
  TZ: 'TZ',
  TH: 'TH',
  TT: 'TT',
  CZ: 'CZ',
  TN: 'TN',
  TR: 'TR',
  TC: 'TC',
  UG: 'UG',
  HU: 'HU',
  UY: 'UY',
  UZ: 'UZ',
  VA: 'VA',
  AE: 'AE',
  US: 'US',
  VN: 'VN',
  VG: 'VG',
  ZM: 'ZM',
  ZW: 'ZW',
  CY: 'CY'
}; export default Keys;