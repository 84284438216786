import { CMP_SERVICES } from '../Config/config';
import { CMP_EVENT_GETTER, CMPConsent } from '../Pages/Checkout/hooks/useCMPConsent';

export const loadCMP = async (
  consentManagerUrl: string,
  consentManagerId: string,
  callback: () => void
): Promise<void> => {
  const cmpScript = document.createElement('script');
  const oldCmpScript = document.getElementById('usercentrics-cmp');
  if (!!oldCmpScript) {
    oldCmpScript.remove();
  }

  const preConnectLink = document.createElement('link');
  preConnectLink.rel = 'preconnect';
  preConnectLink.href = 'https://app.usercentrics.eu';
  document.getElementsByTagName('head')[0].prepend(preConnectLink);

  cmpScript.type = 'application/javascript';
  cmpScript.id = 'usercentrics-cmp';
  cmpScript.setAttribute('data-settings-id', consentManagerId);
  cmpScript.async = true;
  document.getElementsByTagName('head')[0].appendChild(cmpScript);
  cmpScript.onload = function () {
    callback();
  };
  cmpScript.src = consentManagerUrl;
};

export const loadSDP = async (callback: () => void): Promise<void> => {
  const sdpScript = document.createElement('script');
  const oldScript = document.getElementById('sdp-script');
  if (!!oldScript) {
    oldScript.remove();
  }

  const preConnectLink = document.createElement('link');
  preConnectLink.rel = 'preconnect';
  preConnectLink.href = '//privacy-proxy.usercentrics.eu';
  const preloadLink = document.createElement('link');
  preloadLink.rel = 'preload';
  preloadLink.href = '//privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js';
  preloadLink.as = 'script';
  document.getElementsByTagName('head')[0].prepend(preConnectLink, preloadLink);

  sdpScript.type = 'application/javascript';
  sdpScript.id = 'sdp-script';
  document.getElementsByTagName('head')[0].appendChild(sdpScript);
  sdpScript.onload = function () {
    const servicesBlockScript = document.createElement('script');
    servicesBlockScript.type = 'text/javascript';
    servicesBlockScript.text = `
      uc.blockOnly([
        '${CMP_SERVICES.find(service => service.name === 'YouTube Video')?.id}'
      ]);`;
    document.getElementsByTagName('head')[0].appendChild(servicesBlockScript);
    callback();
  };
  sdpScript.src = 'https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js';
};

export const sendCustomCmpEvent = (cmpConsent: CMPConsent) => {
  const event = new CustomEvent(CMP_EVENT_GETTER, { detail: cmpConsent });
  window.dispatchEvent(event);
};
