import { Configuration } from '@ibe/api';
import { AppService, AuthService, removeTrailingSlashes } from '@ibe/services';
import { NewsletterApiService } from './NewsletterApiService';

class NewsletterService {
  constructor(private authService: AuthService, private appService: AppService) {}

  get api(): NewsletterApiService {
    return new NewsletterApiService(
      new Configuration({
        basePath: removeTrailingSlashes(this.appService.config.apiUrl),
        apiKey: this.authService.state.token || undefined,
        headers: {
          'Accept-Language': this.appService.lang,
          Currency: this.appService.currency,
          Tenant: this.appService.tenant || ''
        }
      })
    );
  }
}

export default NewsletterService;
