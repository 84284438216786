import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useTranslation } from '@ibe/components';
import fallback from '../../../../Translations/generated/myClimate.de.json';
import Keys from '../../../../Translations/generated/myClimate.de.json.keys';
import { sanitize } from '@ibe/services';
import useAssetPath from '../../../../Hooks/useAssetPath';

const MyClimate = (): JSX.Element => {
  const { t } = useTranslation('myClimate', fallback);
  const assetBasePath = useAssetPath();

  return (
    <div id="myClimate">
      <Row>
        <Col md={5}>
          <div className={'pt-4 pb-4 pr-4 pl-6'}>
            <img width={'90%'} src={`${assetBasePath}/myClimate.png`} alt={t(Keys.link)} />
          </div>
        </Col>
        <Col md={7}>
          <div className={'pt-4 pb-4 pr-4 pl-6'}>
            <div dangerouslySetInnerHTML={{ __html: sanitize(t(Keys.text)) }} />
            <Button
              className={'pt-2'}
              color="secondary"
              onClick={() => {
                window.open(t(Keys.link), '_blank');
              }}
            >
              {t(Keys.btnLabel)}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MyClimate;
