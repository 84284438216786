import React, { MouseEvent, useEffect } from 'react';
import { observer } from 'mobx-react';
import BlankPageLayout from '../../Layouts/BlankPageLayout';
import CheckoutWorkflow from '../../Layouts/CheckoutWorkflow';
import PageUrl from '../../Models/PageUrl';
import workflowCheckout from '../../steps';
import CheckoutStore from './CheckoutStore';
import { WidgetType } from '../../pages';
import { useHistory } from 'react-router-dom';
import fallback from '../../Translations/generated/cmp-consent-layer.de.json';
import Keys from '../../Translations/generated/cmp-consent-layer.de.json.keys';
import { useTranslation } from '@ibe/components';

const Checkout = observer(function Checkout(props: {
  store: CheckoutStore;
  widgetType?: WidgetType;
  rerouteUrl?: string;
  startPageUrl?: string;
  useCustomCMP?: boolean;
}) {
  const { store, widgetType, rerouteUrl, startPageUrl, useCustomCMP } = props;
  const history = useHistory();
  const { t } = useTranslation('cmp-consent-layer', fallback);

  useEffect(() => {
    if (widgetType === 'CHECKOUT') {
      history.push(PageUrl.CHECKOUT);
    }
  }, []);

  return (
    <BlankPageLayout>
      <div className={`module-container ${!widgetType ? 'container-fluid' : ''}`}>
        {widgetType === 'CMB' && !useCustomCMP && (
          <a
            className="affiliate-cmp-link"
            href="#"
            onClick={(e: MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              if (!!window.UC_UI) {
                window.UC_UI.showSecondLayer();
              }
              return false;
            }}
          >
            {t(Keys.cookieSettings)}
          </a>
        )}
        <CheckoutWorkflow
          baseUrl={PageUrl.CHECKOUT}
          steps={workflowCheckout}
          store={store}
          rerouteUrl={rerouteUrl}
          startPageUrl={startPageUrl}
          useCustomCMP={useCustomCMP}
        />
      </div>
    </BlankPageLayout>
  );
});
export default Checkout;
