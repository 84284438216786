import { ApiBaseData } from '@ibe/api';
import { useTranslation } from '@ibe/components';
import fallback from '../Translations/generated/customer.de.json';
import Keys from '../Translations/generated/customer.de.json.keys';

const getCountries = (): (ApiBaseData & { isDisabled?: boolean; isEuEeaCountry?: boolean })[] => {
  const { t } = useTranslation('customer', fallback);

  return [
    { description: t(Keys.mostUsed), code: t(Keys.mostUsed), isDisabled: true },
    {
      description: t(Keys.DE),
      code: 'DE',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.BE),
      code: 'BE',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.NL),
      code: 'NL',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.AT),
      code: 'AT',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.CH),
      code: 'CH',
      isEuEeaCountry: true
    },
    { description: '------------', code: '------------', isDisabled: true },
    { description: t(Keys.allCountries), code: t(Keys.allCountries), isDisabled: true },
    {
      description: t(Keys.EG),
      code: 'EG'
    },
    {
      description: t(Keys.AD),
      code: 'AD',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.AL),
      code: 'AL'
    },
    {
      description: t(Keys.AI),
      code: 'AI'
    },
    {
      description: t(Keys.AG),
      code: 'AG'
    },
    {
      description: t(Keys.AR),
      code: 'AR'
    },
    {
      description: t(Keys.AM),
      code: 'AM'
    },
    {
      description: t(Keys.AW),
      code: 'AW'
    },
    {
      description: t(Keys.AU),
      code: 'AU'
    },
    {
      description: t(Keys.BS),
      code: 'BS'
    },
    {
      description: t(Keys.BB),
      code: 'BB'
    },
    {
      description: t(Keys.BE),
      code: 'BE'
    },
    {
      description: t(Keys.BZ),
      code: 'BZ'
    },
    {
      description: t(Keys.BM),
      code: 'BM'
    },
    {
      description: t(Keys.BT),
      code: 'BT'
    },
    {
      description: t(Keys.BQ),
      code: 'BQ'
    },
    {
      description: t(Keys.BW),
      code: 'BW'
    },
    {
      description: t(Keys.BR),
      code: 'BR'
    },
    {
      description: t(Keys.BG),
      code: 'BG',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.KY),
      code: 'KY'
    },
    {
      description: t(Keys.CL),
      code: 'CL'
    },
    {
      description: t(Keys.CN),
      code: 'CN'
    },
    {
      description: t(Keys.CK),
      code: 'CK'
    },
    {
      description: t(Keys.CR),
      code: 'CR'
    },
    {
      description: t(Keys.CW),
      code: 'CW'
    },
    {
      description: t(Keys.DK),
      code: 'DK',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.DE),
      code: 'DE',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.DM),
      code: 'DM'
    },
    {
      description: t(Keys.DO),
      code: 'DO'
    },
    {
      description: t(Keys.EC),
      code: 'EC'
    },
    {
      description: t(Keys.EE),
      code: 'EE',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.FJ),
      code: 'FJ'
    },
    {
      description: t(Keys.FI),
      code: 'FI',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.FR),
      code: 'FR',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.PF),
      code: 'PF'
    },
    {
      description: t(Keys.GM),
      code: 'GM'
    },
    {
      description: t(Keys.GE),
      code: 'GE'
    },
    {
      description: t(Keys.GD),
      code: 'GD'
    },
    {
      description: t(Keys.GR),
      code: 'GR',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.GL),
      code: 'GL'
    },
    {
      description: t(Keys.GB),
      code: 'GB',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.GP),
      code: 'GP'
    },
    {
      description: t(Keys.GT),
      code: 'GT'
    },
    {
      description: t(Keys.HK),
      code: 'HK'
    },
    {
      description: t(Keys.IN),
      code: 'IN'
    },
    {
      description: t(Keys.ID),
      code: 'ID'
    },
    {
      description: t(Keys.IE),
      code: 'IE',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.IS),
      code: 'IS',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.IL),
      code: 'IL'
    },
    {
      description: t(Keys.IT),
      code: 'IT',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.JM),
      code: 'JM'
    },
    {
      description: t(Keys.JP),
      code: 'JP'
    },
    {
      description: t(Keys.JO),
      code: 'JO'
    },
    {
      description: t(Keys.KH),
      code: 'KH'
    },
    {
      description: t(Keys.CA),
      code: 'CA'
    },
    {
      description: t(Keys.BQKN),
      code: 'BQ'
    },
    {
      description: t(Keys.KE),
      code: 'KE'
    },
    {
      description: t(Keys.CO),
      code: 'CO'
    },
    {
      description: t(Keys.HR),
      code: 'HR',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.CU),
      code: 'CU'
    },
    {
      description: t(Keys.LA),
      code: 'LA'
    },
    {
      description: t(Keys.LI),
      code: 'LI',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.LV),
      code: 'LV',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.LT),
      code: 'LT',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.LU),
      code: 'LU',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.MG),
      code: 'MG'
    },
    {
      description: t(Keys.MY),
      code: 'MY'
    },
    {
      description: t(Keys.MV),
      code: 'MV'
    },
    {
      description: t(Keys.MT),
      code: 'MT',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.MA),
      code: 'MA'
    },
    {
      description: t(Keys.MQ),
      code: 'MQ'
    },
    {
      description: t(Keys.MU),
      code: 'MU'
    },
    {
      description: t(Keys.MX),
      code: 'MX'
    },
    {
      description: t(Keys.MC),
      code: 'MC',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.MN),
      code: 'MN'
    },
    {
      description: t(Keys.ME),
      code: 'ME'
    },
    {
      description: t(Keys.MZ),
      code: 'MZ'
    },
    {
      description: t(Keys.MM),
      code: 'MM'
    },
    {
      description: t(Keys.NA),
      code: 'NA'
    },
    {
      description: t(Keys.NP),
      code: 'NP'
    },
    {
      description: t(Keys.NZ),
      code: 'NZ'
    },
    {
      description: t(Keys.NL),
      code: 'NL',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.NO),
      code: 'NO',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.OM),
      code: 'OM'
    },
    {
      description: t(Keys.AT),
      code: 'AT',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.PA),
      code: 'PA'
    },
    {
      description: t(Keys.PE),
      code: 'PE'
    },
    {
      description: t(Keys.PL),
      code: 'PL',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.PT),
      code: 'PT',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.PR),
      code: 'PR'
    },
    {
      description: t(Keys.QA),
      code: 'QA'
    },
    {
      description: t(Keys.RE),
      code: 'RE'
    },
    {
      description: t(Keys.RW),
      code: 'RW'
    },
    {
      description: t(Keys.RO),
      code: 'RO',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.RU),
      code: 'RU'
    },
    {
      description: t(Keys.SM),
      code: 'SM',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.ST),
      code: 'ST'
    },
    {
      description: t(Keys.SE),
      code: 'SE',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.CH),
      code: 'CH',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.SC),
      code: 'SC'
    },
    {
      description: t(Keys.SG),
      code: 'SG'
    },
    {
      description: t(Keys.SK),
      code: 'SK',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.SI),
      code: 'SI',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.ES),
      code: 'ES',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.LK),
      code: 'LK'
    },
    {
      description: t(Keys.KN),
      code: 'KN'
    },
    {
      description: t(Keys.LC),
      code: 'LC'
    },
    {
      description: t(Keys.VC),
      code: 'VC'
    },
    {
      description: t(Keys.ZA),
      code: 'ZA'
    },
    {
      description: t(Keys.KR),
      code: 'KR'
    },
    {
      description: t(Keys.TJ),
      code: 'TJ'
    },
    {
      description: t(Keys.TW),
      code: 'TW'
    },
    {
      description: t(Keys.TZ),
      code: 'TZ'
    },
    {
      description: t(Keys.TH),
      code: 'TH'
    },
    {
      description: t(Keys.TT),
      code: 'TT'
    },
    {
      description: t(Keys.CZ),
      code: 'CZ',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.TN),
      code: 'TN'
    },
    {
      description: t(Keys.TR),
      code: 'TR'
    },
    {
      description: t(Keys.TC),
      code: 'TC'
    },
    {
      description: t(Keys.UG),
      code: 'UG'
    },
    {
      description: t(Keys.HU),
      code: 'HU',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.UY),
      code: 'UY'
    },
    {
      description: t(Keys.UZ),
      code: 'UZ'
    },
    {
      description: t(Keys.VA),
      code: 'VA',
      isEuEeaCountry: true
    },
    {
      description: t(Keys.AE),
      code: 'AE'
    },
    {
      description: t(Keys.US),
      code: 'US'
    },
    {
      description: t(Keys.VN),
      code: 'VN'
    },
    {
      description: t(Keys.VG),
      code: 'VG'
    },
    {
      description: t(Keys.ZM),
      code: 'ZM'
    },
    {
      description: t(Keys.ZW),
      code: 'ZW'
    },
    {
      description: t(Keys.CY),
      code: 'CY',
      isEuEeaCountry: true
    }
  ];
};

export default getCountries;
