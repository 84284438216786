import Keys from '../../../../Translations/generated/customer.de.json.keys';
import useCmbConfig from '../../../../Hooks/useCmbConfig';
import { TFunction } from 'react-i18next';
import { Terms } from '@ibe/components';

const terms = (t: TFunction, url?: string): Terms[] => {
  const baseUrl = useCmbConfig().baseUrl ? useCmbConfig().baseUrl : '';
  return [
    {
      id: 'term-0',
      term: t(Keys.term),
      term2: t(Keys.term2),
      isCheckable: true,
      url: baseUrl + t(Keys.url),
      url2: url ? url : baseUrl + t(Keys.url2),
      urlLabel: t(Keys.urlLabel),
      urlLabel2: t(Keys.urlLabel2)
    },
    {
      id: 'term-1',
      term: t(Keys.privacy),
      url: baseUrl + t(Keys.privacyUrl),
      urlLabel: t(Keys.privacyUrlLabel)
    },
    {
      id: 'term-2',
      innerHTMLText: t(Keys.privacyAdditionalText)
    }
  ];
};
export default terms;
