import { useTranslation } from '@ibe/components';
import React, { MouseEvent, TouchEvent } from 'react';
import fallback from '../Translations/generated/cmp-consent-layer.de.json';
import Keys from '../Translations/generated/cmp-consent-layer.de.json.keys';
import useCMPConsentContext from '../Pages/Checkout/hooks/useCMPConsent';
import { sendCustomCmpEvent } from '../utils/loadCMP';

const CMPConsentLayer = ({
  serviceId,
  title,
  description,
  useCustomCMP
}: {
  serviceId: string;
  title: string;
  description: string;
  useCustomCMP?: boolean;
}): JSX.Element => {
  const { t } = useTranslation('cmp-consent-layer', fallback);
  const noExistingServiceText = window.uc?.translations?.translations?.NOT_PERMITTED_TEXT;
  const cmpConsent = useCMPConsentContext();

  const onMoreInformation = (): boolean => {
    window.uc?.ucapi?.showInfoModal(serviceId);
    return false;
  };

  const onAccept = (e: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>): boolean => {
    e.stopPropagation();
    e.preventDefault();
    if (!useCustomCMP) {
      window.uc?.ucapi?.setConsents([{ templateId: serviceId, status: true }]);
    } else {
      sendCustomCmpEvent({ ...cmpConsent, [serviceId]: true });
    }
    return false;
  };

  return (
    <div className={`uc-embedding-wrapper${useCustomCMP ? ' uc-embedding-wrapper--custom' : ''}`}>
      <h3>{title}</h3>
      <p className="description-text">{description}</p>
      {!!noExistingServiceText && <p className="not-existing-service">{noExistingServiceText}</p>}
      <div className="uc-embedding-buttons">
        {!useCustomCMP && (
          <button
            onTouchStart={onMoreInformation}
            onClick={onMoreInformation}
            className="uc-embedding-more-info"
          >
            {t(Keys.moreInformation)}
          </button>
        )}
        <button onTouchStart={onAccept} onClick={onAccept} className="uc-embedding-accept">
          {t(Keys.accept)}
        </button>
      </div>
    </div>
  );
};

export default CMPConsentLayer;
