import React, { useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import {
  BuilderRef,
  TravellerBuilder,
  useTranslation,
  PriceDetails,
  FixedElementScroll,
  CollapseHeadline,
  CamperSummary,
  useConfig,
  LoadingOverlay,
  ContentContainer,
  useFooterPadding,
  useAppService
} from '@ibe/components';
import { scroller, Element as ScrollTo } from 'react-scroll';
import { ApiTraveler, ApiTravelerFromJSON } from '@ibe/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons/faCaretLeft';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { CheckoutStepComponentProps } from '../../../Models/CheckoutStep';
import useFormConfig, { getTravellerMockData } from '../../../Config/useTravellerFormConfig';
import { getDays, getMonths, getYears } from '../../../Config/dateParts';
import fallback from '../../../Translations/generated/traveller.de.json';
import Keys from '../../../Translations/generated/traveller.de.json.keys';
import builderFallback from '../../../Translations/generated/traveller-builder.de.json';
import fallbackConsent from '../../../Translations/generated/cmp-consent-layer.de.json';
import KeysConsent from '../../../Translations/generated/cmp-consent-layer.de.json.keys';
import ProgressBar from './ProgressBar';
import CheckoutPageUrl from '../CheckoutPageUrl';
import PageUrl from '../../../Models/PageUrl';
import ErrorDisplay from '../../../Components/ErrorDisplay';
import ServiceBox from '../../../Components/ServiceBox';
import CamperLoading from '../../../Components/CamperLoading';
import { useMount } from 'react-use';
import { routeBookingEvent, LoggerFactory, sanitize, ConfigModel, AppService } from '@ibe/services';
import getCountries from '../../../utils/getCountries';
import { getCmpServices, YOUTUBE_NOCOOKIE_HOST } from '../../../Config/config';
import useCmbConfig from '../../../Hooks/useCmbConfig';
import CMPConsentLayer from '../../../Components/CMPConsentLayer';
import useCMPConsentContext from '../hooks/useCMPConsent';
import { getAge } from '../../../globals';
import dayjs from 'dayjs';

const logger = LoggerFactory.get('Traveller.tsx');

const travellerProperties = [
  { from: 'id', to: 'id' },
  { from: 'gender', to: 'gender' },
  { from: 'firstName', to: 'firstname' },
  { from: 'lastName', to: 'lastname' },
  { from: 'birthDate', to: 'birthDate' }
];

const Traveller = observer(function Traveller(props: CheckoutStepComponentProps): JSX.Element {
  const { store, rerouteUrl, tcsUrl, useCustomCMP } = props;
  const { t } = useTranslation('traveller', fallback);
  const { t: builderT } = useTranslation('traveller-builder', builderFallback);
  const { t: consentT } = useTranslation('cmp-consent-layer', fallbackConsent);
  const minPax = store?.camper?.minPax;
  const maxPax = store?.camper?.maxPax;
  const maxAdults = store?.camper?.maxAdults;
  const minAdults = store?.camper?.minAdults;
  const maxChild = store?.camper?.maxChild;
  const minChild = store?.camper?.minChild;
  const builderRef = useRef<BuilderRef<Record<string, unknown>>>(null);
  const history = useHistory();
  const config: ConfigModel = useConfig();
  const app: AppService = useAppService();
  const cmbConfig = useCmbConfig();
  const cmpConsent = useCMPConsentContext();
  const googleMapsService = getCmpServices(useCustomCMP).find(
    service => service.name === 'Google Maps'
  );
  const youtubeService = getCmpServices(useCustomCMP).find(
    service => service.name === 'YouTube Video'
  );
  const [initialValues, setInitialValues] = useState<Record<string, unknown> | undefined>(
    undefined
  );
  const [initialTravellers, setInitialTravellers] = useState<Record<string, unknown>[] | undefined>(
    undefined
  );
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const minDriverAge = store?.camper?.minDriverAge || config.traveler.maxChildAge + 1;
  const oldValues = useRef(['']);
  const [error, setError] = useState<boolean>(false);

  useFooterPadding({
    identifierClass: 'footer',
    footerClass: 'padding-bottom'
  });

  logger.log('Traveller.tsx', store);

  useMount(() => {
    routeBookingEvent.broadcast({
      pathname: CheckoutPageUrl.TRAVELLER,
      search: '',
      booking: store.booking || undefined
    });
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (store.travellers && store.travellers.length > 0) {
      setInitialTravellers(
        store.travellers.map(traveller =>
          Object.fromEntries(
            travellerProperties.map(prop => [
              prop.to,
              (traveller as ApiTraveler & { [key: string]: string })[prop.from]
            ])
          )
        )
      );
    }
  }, [store.travellers]);

  const handleSubmit = async (): Promise<void> => {
    if (builderRef.current) {
      const values = (await builderRef.current.handleSubmit()) as Record<string, unknown>[];
      if (!values || (!!values && values.some(child => Object.keys(child).length === 0))) return;
      const travellers = values.map(value => ({
        ...ApiTravelerFromJSON({
          id: value.id,
          age: !!value.birthDate ? getAge(value.birthDate as string) : null,
          gender: value.gender,
          firstName: value.firstname,
          lastName: value.lastname,
          birthDate: value.birthDate
        })
      }));
      store.setTravellers(travellers);
      await store.updateTravellers();
      await store.doInitPayment();
      if (store.error) {
        scroller.scrollTo(store.scrollElementGlobalError, {
          smooth: true,
          delay: 100,
          offset: -200
        });
      } else if (!doValidation()) {
        scroller.scrollTo('travellerError', {
          smooth: true,
          delay: 100,
          offset: -200
        });
      } else {
        history.push(CheckoutPageUrl.CUSTOMER);
      }
    }
  };

  const backToHome = async (): Promise<void> => {
    setConfirmModalOpen(false);
    store.setIsLoading(true);
    store.setError('');
    await store.resetBooking();
    store.setIsLoading(false);
    if (!!rerouteUrl) {
      location.href = rerouteUrl;
    } else {
      history.push(PageUrl.HOME);
    }
  };

  const handleBack = (): void => {
    setConfirmModalOpen(true);
  };

  const onFormFieldsChange = (birthDate: string, idx: number | undefined): void => {
    if (idx != null && oldValues?.current && birthDate && oldValues.current[idx] !== birthDate) {
      oldValues.current[idx] = birthDate;
    }
  };

  const doValidation = (): boolean => {
    let childCount = 0;
    oldValues.current.map(birthDate => {
      const birthDateAdded = dayjs(birthDate).add(config.traveler.maxChildAge, 'year');
      if (birthDateAdded.isAfter(dayjs(new Date()).add(1, 'day'), 'day')) {
        childCount++;
      }
    });
    setError(
      childCount > maxChild ||
        childCount < minChild ||
        oldValues.current.length - childCount < minAdults
    );
    return (
      childCount <= maxChild &&
      childCount >= minChild &&
      oldValues.current.length - childCount >= minAdults
    );
  };

  return (
    <div className="checkout__traveller pb-5">
      <Button
        color="secondary"
        className="checkout__traveller__back mb-4 mt-2"
        onClick={handleBack}
      >
        <FontAwesomeIcon className="mr-3" icon={faCaretLeft} />
        {t(Keys.detailPage)}
      </Button>
      <ProgressBar store={store} />
      <ErrorDisplay store={store} />
      <LoadingOverlay
        isLoading={!!store.error || store.isLoading}
        positionFixed
        backgroundColor="#000"
        customSpinner={<CamperLoading />}
      >
        <Row>
          <Col xs={12} lg={9}>
            {store.camper && (
              <ContentContainer borderRadius>
                <CollapseHeadline headerText={t(Keys.summary)} initialState>
                  <div className="pt-4">
                    <CamperSummary
                      camper={store.camper}
                      youtubeOpts={{ host: YOUTUBE_NOCOOKIE_HOST }}
                      MapReplacement={
                        !!googleMapsService && (cmbConfig.isSDPLoaded || useCustomCMP) ? (
                          <div className="position-relative py-3">
                            <CMPConsentLayer
                              useCustomCMP={useCustomCMP}
                              serviceId={googleMapsService.id}
                              title={
                                useCustomCMP
                                  ? consentT(KeysConsent.customTitle, {
                                      service: googleMapsService.name
                                    })
                                  : window.uc?.translations?.translations?.DEFAULT_TITLE?.replace(
                                      '%TECHNOLOGY_NAME%',
                                      googleMapsService.name
                                    )
                              }
                              description={
                                useCustomCMP
                                  ? consentT(KeysConsent.customMapDescription)
                                  : window.uc?.translations?.translations?.MAP_DESCRIPTION
                              }
                            />
                          </div>
                        ) : (
                          <></>
                        )
                      }
                      showMapReplacement={!cmpConsent[googleMapsService?.id || '']}
                      VideoReplacement={
                        !!youtubeService && useCustomCMP ? (
                          <div className="position-relative py-3">
                            <CMPConsentLayer
                              useCustomCMP
                              serviceId={youtubeService.id}
                              title={consentT(KeysConsent.customTitle, {
                                service: youtubeService.name
                              })}
                              description={consentT(KeysConsent.customVideoDescription)}
                            />
                          </div>
                        ) : (
                          <></>
                        )
                      }
                      showVideoReplacement={!cmpConsent[youtubeService?.id || '']}
                      tcsUrl={tcsUrl}
                    />
                  </div>
                </CollapseHeadline>
              </ContentContainer>
            )}
            <ContentContainer borderRadius>
              <CollapseHeadline
                headerText={t(Keys.participant)}
                infoText={t(Keys.mandatoryFields)}
                noCollapse
              >
                <div className="pt-4">
                  {config.traveler.mockAvailable && (
                    <div className="d-flex justify-content-end">
                      <Button
                        color="secondary"
                        className="mb-4 mr-3"
                        onClick={(): void => {
                          setInitialValues(getTravellerMockData());
                        }}
                      >
                        Fill with mock data
                      </Button>
                    </div>
                  )}
                  <div className="checkout__traveller__warning">
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: sanitize(t(Keys.travellerWarning))
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html: sanitize(t(Keys.travellerWarningAge, { minDriverAge }))
                        }}
                      />
                      <li>
                        {t(Keys.travellerWarningMinMax, { minPax, maxPax, maxAdults, maxChild })}
                      </li>
                    </ul>
                  </div>
                  <TravellerBuilder<Record<string, unknown>, Record<string, unknown>>
                    builderRef={builderRef}
                    formConfig={(index: number) =>
                      useFormConfig(index, minDriverAge, builderT, undefined, config, app)
                    }
                    initialValues={initialValues}
                    initialTravellers={initialTravellers}
                    maxNumberOfParticipants={store?.camper?.maxPax}
                    onFormFieldsChange={(formFields, setValue, isValid, isSubmitted, idx): void =>
                      onFormFieldsChange(formFields.birthDate as string, idx)
                    }
                    externalData={{
                      years: getYears().map(year => ({
                        code: year.value,
                        description: year.label
                      })),
                      days: getDays().map(day => ({ code: day.value, description: day.label })),
                      months: getMonths().map(month => ({
                        code: month.value,
                        description: month.label
                      })),
                      countries: getCountries(),
                      yupContext: {
                        adultMinAge: minDriverAge,
                        startDate: store?.camper?.pickupDate || '',
                        minYearOfBirth: 1900
                      }
                    }}
                    onAddTraveller={store.handleAddTraveller}
                    onRemoveTraveller={store.handleRemoveTraveller}
                    mode="onChange"
                    revalidateMode="onChange"
                    autoAdd={store.travellers.length == 0}
                    firstParticipantIsStatic
                    firstParticipantLabel={t(Keys.traveller)}
                    firstParticipantNote={
                      <p className="checkout__traveller__traveller-note">{t(Keys.travellerNote)}</p>
                    }
                    minPax={minPax}
                  />
                  <div className="camper-item__header__separator lower-separator" />
                  <ScrollTo name="travellerError">
                    {error && (
                      <div className="pt-2 d-flex justify-content-center position-relative">
                        <div className="form__form-builder__general-error">
                          <div
                            className="mb-1"
                            dangerouslySetInnerHTML={{
                              __html: sanitize(
                                t(Keys.travellerWarningMaxChild, { maxChild: maxChild })
                              )
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </ScrollTo>
                  <div className="checkout__traveller__button-container mt-5">
                    <Button
                      color="secondary btn-secondary--stroked"
                      className="checkout__traveller__submit px-4 mb-2"
                      onClick={handleBack}
                    >
                      {t(Keys.previous)}
                    </Button>
                    <Button
                      color="primary"
                      className="checkout__traveller__submit px-4 mb-2"
                      onClick={handleSubmit}
                    >
                      {t(Keys.next)}
                    </Button>
                  </div>
                </div>
              </CollapseHeadline>
            </ContentContainer>
          </Col>
          <FixedElementScroll offset={20}>
            <div className="fixed-scroll-element-container">
              <PriceDetails camper={store.camper} />
              <ServiceBox />
            </div>
          </FixedElementScroll>
        </Row>
      </LoadingOverlay>
      <Modal
        className="traveller__go-back"
        isOpen={confirmModalOpen}
        wrapClassName={config.modalContainerClass}
      >
        <ModalHeader className="font-weight-bold">{t(Keys.cancel)}</ModalHeader>
        <ModalBody>
          <p>{t(Keys.confirmGoBack)}</p>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button color="secondary" onClick={(): void => setConfirmModalOpen(false)}>
            {t(Keys.no)}
          </Button>
          <Button color="primary" onClick={backToHome}>
            {t(Keys.yes)}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
});

export default Traveller;
