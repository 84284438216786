export const camperSearchResultsKey = 'camper-search-results';

export const EXCLUDED_EXTRA_CODES = ['11CA/ZD1_A'];

export type GroupName = { translated: string; original: string };

export const extraGroupsSorter = (first: GroupName, second: GroupName): number => {
  const a = first.original;
  const b = second.original;
  if (a === 'Equipment' || a === 'Ausstattung') {
    return -1;
  } else if (b === 'Equipment' || b === 'Ausstattung') {
    return 1;
  } else if (a === 'Additional Services' || a === 'Zusatzleistungen') {
    if (b === 'Equipment' || b === 'Ausstattung') {
      return 1;
    } else {
      return -1;
    }
  } else if (b === 'Additional Services' || b === 'Zusatzleistungen') {
    if (a === 'Equipment' || a === 'Ausstattung') {
      return -1;
    } else {
      return 1;
    }
  } else if (a === 'Refund of Deductible' || a === 'Rückerstattung der Selbstbeteiligung') {
    if (b === 'Insurance' || b === 'Versicherungen') {
      return -1;
    } else {
      return 1;
    }
  } else if (b === 'Refund of Deductible' || b === 'Rückerstattung der Selbstbeteiligung') {
    if (a === 'Insurance' || a === 'Versicherungen') {
      return 1;
    } else {
      return -1;
    }
  } else if (a === 'Insurance' || a === 'Versicherungen') {
    return 1;
  } else if (b === 'Insurance' || b === 'Versicherungen') {
    return -1;
  } else {
    return a.localeCompare(b);
  }
};

export const getAge = (date: string) => {
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
