/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-12-04 12:17:51 */

const Keys = {
  personalData: 'personalData',
  summaryPayment: 'summaryPayment',
  confirmation: 'confirmation',
  payment: 'payment',
  bookingAttemptFailed: 'bookingAttemptFailed',
  addItemsFailed: 'addItemsFailed',
  sessionInvalid: 'sessionInvalid'
}; export default Keys;