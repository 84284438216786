import {
  ApiGetAdditionalInsuranceListRequest,
  Configuration,
  HTTPHeaders,
  HTTPQuery,
  JSONApiResponse,
  RequiredError
} from '@ibe/api';
import { ApiService } from '@ibe/services';
import { CamperInsuranceData } from '../Models/insurance/CamperInsuranceData';

export class ExtendedApiService extends ApiService {
  constructor(configuration = new Configuration()) {
    super(configuration);
  }

  public async getCamperCmsInsuranceList(
    apiGetAdditionalInsuranceListRequest: ApiGetAdditionalInsuranceListRequest
  ): Promise<Array<CamperInsuranceData>> {
    if (
      apiGetAdditionalInsuranceListRequest === null ||
      apiGetAdditionalInsuranceListRequest === undefined
    ) {
      throw new RequiredError(
        'getCamperCmsInsuranceList',
        'Required parameter apiGetAdditionalInsuranceListRequest was null or undefined when calling getCamperCmsInsuranceList.'
      );
    }

    const queryParameters: HTTPQuery = {};
    const headerParameters: HTTPHeaders = {};
    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-IBE-Authorization'] = this.configuration.apiKey('X-IBE-Authorization'); // bearerAuth authentication
    }

    const response = await super.request({
      path: `/camper/cmsinsurance/list`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: apiGetAdditionalInsuranceListRequest
    });

    const apiResponse = new JSONApiResponse(response, jsonValue => {
      return jsonValue.message || (jsonValue.data as string);
    });
    return apiResponse.value();
  }
}
