import React from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Row } from 'reactstrap';
import { useMount } from 'react-use';
import {
  CamperSummary,
  CollapseHeadline,
  ContentContainer,
  CustomerSummary,
  FixedElementScroll,
  PriceDetails,
  TravellersSummary,
  useTranslation
} from '@ibe/components';
import { CheckoutStepComponentProps } from '../../../Models/CheckoutStep';
import ProgressBar from './ProgressBar';
import fallback from '../../../Translations/generated/confirmation.de.json';
import Keys from '../../../Translations/generated/confirmation.de.json.keys';
import fallbackConsent from '../../../Translations/generated/cmp-consent-layer.de.json';
import KeysConsent from '../../../Translations/generated/cmp-consent-layer.de.json.keys';
import TrustedBadge from '../../../Components/TrustedBadge';
import PageUrl from '../../../Models/PageUrl';
import MyClimate from './myClimate/MyClimate';
import { routeBookingEvent } from '@ibe/services';
import CheckoutPageUrl from '../CheckoutPageUrl';
import { useHistory } from 'react-router';
import { getCmpServices, YOUTUBE_NOCOOKIE_HOST } from '../../../Config/config';
import useCmbConfig from '../../../Hooks/useCmbConfig';
import useCMPConsentContext from '../hooks/useCMPConsent';
import CMPConsentLayer from '../../../Components/CMPConsentLayer';

const Confirmation = observer(function Confirmation(
  props: CheckoutStepComponentProps
): JSX.Element {
  const { store, startPageUrl, tcsUrl, useCustomCMP } = props;
  const { t } = useTranslation('confirmation', fallback);
  const { t: consentT } = useTranslation('cmp-consent-layer', fallbackConsent);
  const history = useHistory();
  const cmbConfig = useCmbConfig();
  const cmpConsent = useCMPConsentContext();
  const googleMapsService = getCmpServices(useCustomCMP).find(
    service => service.name === 'Google Maps'
  );
  const youtubeService = getCmpServices(useCustomCMP).find(
    service => service.name === 'YouTube Video'
  );

  useMount(async () => {
    routeBookingEvent.broadcast({
      pathname: CheckoutPageUrl.CONFIRMATION,
      search: '',
      booking: store.booking || undefined
    });
    await store.forceBookingInit();
  });

  if (!store.booking) return <></>;

  const backToHome = async (): Promise<void> => {
    if (!!startPageUrl) {
      location.href = startPageUrl;
    } else {
      history.push(PageUrl.HOME);
    }
  };

  return (
    <div className="checkout__confirmation pb-5">
      <ProgressBar store={store} />
      <Row>
        <Col xs={12} lg={9}>
          {store.camper && (
            <>
              <ContentContainer borderRadius>
                <CollapseHeadline
                  headerText={t(Keys.successHeadline)}
                  subHeader={
                    <div>
                      <span>{t(Keys.bookingNumber)}: </span>
                      <span className="booking-number">
                        <b>{store.booking.bookingNumber}</b>
                      </span>
                    </div>
                  }
                  initialState
                >
                  <div className="pt-4">
                    <CamperSummary
                      camper={store.camper}
                      tcsUrl={tcsUrl}
                      confirmationStep
                      youtubeOpts={{ host: YOUTUBE_NOCOOKIE_HOST }}
                      MapReplacement={
                        !!googleMapsService && (cmbConfig.isSDPLoaded || useCustomCMP) ? (
                          <div className="position-relative py-3">
                            <CMPConsentLayer
                              useCustomCMP={useCustomCMP}
                              serviceId={googleMapsService.id}
                              title={
                                useCustomCMP
                                  ? consentT(KeysConsent.customTitle, {
                                      service: googleMapsService.name
                                    })
                                  : window.uc?.translations?.translations?.DEFAULT_TITLE?.replace(
                                      '%TECHNOLOGY_NAME%',
                                      googleMapsService.name
                                    )
                              }
                              description={
                                useCustomCMP
                                  ? consentT(KeysConsent.customMapDescription)
                                  : window.uc?.translations?.translations?.MAP_DESCRIPTION
                              }
                            />
                          </div>
                        ) : (
                          <></>
                        )
                      }
                      showMapReplacement={!cmpConsent[googleMapsService?.id || '']}
                      VideoReplacement={
                        !!youtubeService && useCustomCMP ? (
                          <div className="position-relative py-3">
                            <CMPConsentLayer
                              useCustomCMP
                              serviceId={youtubeService.id}
                              title={consentT(KeysConsent.customTitle, {
                                service: youtubeService.name
                              })}
                              description={consentT(KeysConsent.customVideoDescription)}
                            />
                          </div>
                        ) : (
                          <></>
                        )
                      }
                      showVideoReplacement={!cmpConsent[youtubeService?.id || '']}
                    />
                  </div>
                  <div className="mt-4">
                    {store.camper?.status === 'OnRequest' && (
                      <>
                        <p>{t(Keys.successOnRequestInfo1)}</p>
                        <p>{t(Keys.successOnRequestInfo2)}</p>
                      </>
                    )}
                    {store.camper?.status === 'Available' && <p>{t(Keys.successAvailableInfo)}</p>}
                  </div>
                </CollapseHeadline>
              </ContentContainer>
            </>
          )}
          {store.customer && (
            <ContentContainer borderRadius>
              <CollapseHeadline headerText={t(Keys.vehicleBookedBy)} noCollapse>
                <div className="pt-4">
                  <CustomerSummary customer={store.customer} />
                </div>
              </CollapseHeadline>
            </ContentContainer>
          )}
          {store.travellers && (
            <ContentContainer borderRadius>
              <CollapseHeadline headerText={t(Keys.participants)} noCollapse>
                <div className="pt-4">
                  <TravellersSummary travellers={store.travellers} />
                </div>
              </CollapseHeadline>
            </ContentContainer>
          )}
          <ContentContainer borderRadius>
            <CollapseHeadline headerText={t(Keys.myClimate)} noCollapse>
              <div className="pt-4">
                <MyClimate />
              </div>
            </CollapseHeadline>
          </ContentContainer>
          <div className="back-home-container">
            <Button color="primary" onClick={backToHome}>
              {t(Keys.homepageReturn)}
            </Button>
          </div>
        </Col>
        <FixedElementScroll offset={20}>
          <div className="fixed-scroll-element-container">
            <PriceDetails
              camper={store.camper}
              items={store.booking.bookedItems
                .filter(i => i.itemType === 'INSURANCE')
                .map(i => {
                  return { price: i.price, label: i.description };
                })}
              confirmationStep
              discounts={store.booking.discounts}
            />
          </div>
        </FixedElementScroll>
      </Row>
      <TrustedBadge booking={store.booking} />
    </div>
  );
});

export default Confirmation;
