import {
  ApiTraveler,
  Configuration,
  HTTPHeaders,
  HTTPQuery,
  JSONApiResponse,
  RequiredError
} from '@ibe/api';
import { ApiService } from '@ibe/services';

export class NewsletterApiService extends ApiService {
  constructor(configuration = new Configuration()) {
    super(configuration);
  }

  async subscribeNewsletter(traveler: ApiTraveler): Promise<string> {
    if (traveler === null || traveler === undefined) {
      throw new RequiredError(
        'subscribeNewsletter',
        'Required parameter traveler was null or undefined when calling subscribeNewsletter.'
      );
    }

    const queryParameters: HTTPQuery = {};
    const headerParameters: HTTPHeaders = {};
    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-IBE-Authorization'] = this.configuration.apiKey('X-IBE-Authorization'); // bearerAuth authentication
    }

    const response = await super.request({
      path: `/newsletter/subscribe`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: traveler
    });

    const apiResponse = new JSONApiResponse(response, jsonValue => {
      return jsonValue.message || (jsonValue.data as string);
    });
    return apiResponse.value();
  }
}
