import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons/faPhoneAlt';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons/faCommentAlt';
import { ContentContainer, useTranslation } from '@ibe/components';
import Keys from '../Translations/generated/service-box.de.json.keys';
import fallback from '../Translations/generated/service-box.de.json';

const ServiceBox = (): JSX.Element => {
  const { t } = useTranslation('service-box', fallback);

  return (
    <div className="service-box__outer">
      <ContentContainer borderRadius>
        <div className="service-box">
          <div className="service-box__icons">
            <FontAwesomeIcon icon={faPhoneAlt} />
            <FontAwesomeIcon icon={faCommentAlt} />
          </div>
          <div className="service-box__infos">
            <div className="service-box__headline">{t(Keys.headline)}</div>
            <div className="service-box__phone">{t(Keys.phone)}</div>
            <div className="service-box__text">{t(Keys.text)}</div>
            <div className="service-box__text">{t(Keys.text1)}</div>
            <div className="service-box__text">{t(Keys.text2)}</div>
          </div>
        </div>
      </ContentContainer>
    </div>
  );
};

export default ServiceBox;
