import { FormConfig, BaseFormElement } from '@ibe/components';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import Keys from '../Translations/generated/traveller-builder.de.json.keys';
import { ApiGender } from '@ibe/api';
import { TFunction } from 'react-i18next';
import { AppService, ConfigModel } from '@ibe/services';

export const getTravellerMockData = (): Record<string, string> => {
  return {
    gender: 'MALE',
    firstname: 'ISO',
    lastname: 'Test',
    birthDate: '1980-01-01'
  };
};

export interface FormItemsProps {
  birthDate: string;
  city: string;
  country: string;
  email: string;
  firstname: string;
  houseNumber: string;
  id: string;
  lastname: string;
  nationality: string;
  phone: string;
  salutation: string;
  street: string;
  zipCode: string;
  [a: string]: string;
}

const useFormConfig = (
  index: number,
  minDriverAge: number,
  t: TFunction,
  isNewInvoiceRecipient?: boolean,
  config?: ConfigModel,
  app?: AppService
): FormConfig => {
  return <FormConfig>{
    rows: [
      {
        key: 'row1',
        elements: [
          {
            tag: 'select',
            key: 'gender',
            placeholder: t(Keys.salutation),
            noMenuPortal: true,
            options: [
              { code: ApiGender.FEMALE, description: t(Keys.female) },
              { code: ApiGender.MALE, description: t(Keys.male) }
            ],
            rowGrid: {
              lg: 6
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            infoText: t(Keys.salutationInfoText),
            infoTextRowGrid: {
              lg: 6
            }
          },
          {
            tag: 'input',
            key: 'firstname',
            placeholder: t(Keys.firstname),
            initialValue: '',
            rowGrid: {
              lg: 3
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            }
          },
          {
            tag: 'input',
            key: 'lastname',
            placeholder: t(Keys.lastname),
            initialValue: '',
            rowGrid: {
              lg: 3
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            infoText: t(Keys.nameInfoText),
            infoTextRowGrid: {
              lg: 6
            }
          },
          ...(isNewInvoiceRecipient
            ? [
                {
                  key: 'external1',
                  tag: 'external',
                  childIndex: 0,
                  rowGrid: {
                    xs: 0,
                    lg: 6
                  }
                } as BaseFormElement
              ]
            : []),
          {
            tag: 'dateInput',
            key: 'birthDate',
            displayFormat: config?.displayFormatDate?.[app?.lang || ''],
            placeholder: t(Keys.dateOfBirth),
            icon: faCalendar,
            ignoreTypeSwitchInMobile: true,
            ignoreTypeSwitchInDesktop: true,
            rowGrid: {
              lg: 6
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                },
                ...(index === 0
                  ? [
                      {
                        type: 'isOlderThanAgeOnDateString',
                        customMessage: t(Keys.minDriverAge, { minDriverAge })
                      }
                    ]
                  : []),
                {
                  type: 'hasValidYear',
                  customMessage: t(Keys.wrongYear)
                }
              ]
            },
            infoText: t(Keys.dateOfBirthInfoText),
            infoTextRowGrid: {
              lg: 6
            }
          },
          ...(isNewInvoiceRecipient
            ? [
                {
                  key: 'external2',
                  tag: 'external',
                  childIndex: 1,
                  rowGrid: {
                    xs: 0,
                    lg: 6
                  }
                } as BaseFormElement
              ]
            : [])
        ]
      }
    ]
  };
};

export default useFormConfig;
