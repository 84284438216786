import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { CheckoutStep } from '../Models/CheckoutStep';
import CheckoutStore from '../Pages/Checkout/CheckoutStore';
import { StepType, LoggerFactory } from '@ibe/services';
import { workflowContext } from '../contexts';

const logger = LoggerFactory.get('CheckoutWorkflow.tsx');

const StepSwitch = observer(
  (props: {
    baseUrl: string;
    steps: CheckoutStep[];
    store: CheckoutStore;
    rerouteUrl?: string;
    startPageUrl?: string;
    tcsUrl?: string;
    useCustomCMP?: boolean;
  }): JSX.Element => {
    const workflow = useContext(workflowContext);
    const { steps, store, rerouteUrl, startPageUrl, tcsUrl, useCustomCMP } = props;
    const { step } = useParams<{ step: string }>();

    const currentStep = steps.find(s => s.slug === step);

    workflow.setCurrentStep(currentStep || null);

    if (currentStep?.type === StepType.CAMP_CHECKOUT_CUSTOMER) {
      (async () => {
        await store.doInitPayment();
      })();
    }

    if (currentStep) {
      store.setActiveStep(currentStep.type);
    }

    if (currentStep) {
      const CheckoutComponent = currentStep.component;
      return (
        <CheckoutComponent
          store={store}
          rerouteUrl={rerouteUrl}
          startPageUrl={startPageUrl}
          tcsUrl={tcsUrl}
          useCustomCMP={useCustomCMP}
        />
      );
    }

    return <Redirect to="/404" />;
  }
);

const CheckoutWorkflow = observer(function CheckoutWorkflow(props: {
  baseUrl: string;
  steps: CheckoutStep[];
  store: CheckoutStore;
  rerouteUrl?: string;
  startPageUrl?: string;
  useCustomCMP?: boolean;
}): JSX.Element {
  const workflow = useContext(workflowContext);
  const { baseUrl, steps, store, rerouteUrl, startPageUrl, useCustomCMP } = props;

  const [tcs, setTcs] = useState<string | undefined>();
  const { camper } = store;

  useEffect(() => {
    (async () => {
      if (!!camper) {
        try {
          setTcs(await store.termsAndConditions(camper));
        } catch (err) {
          logger.log(err);
        }
      }
    })();
  }, [camper]);

  workflow.setSteps(steps);

  return (
    <>
      <Switch>
        <Route exact path={baseUrl}>
          <Redirect to={`${baseUrl}/${steps[0].slug}`} />
        </Route>
        <Route path={`${baseUrl}/:step`}>
          <StepSwitch
            steps={steps}
            baseUrl={baseUrl}
            store={store}
            rerouteUrl={rerouteUrl}
            startPageUrl={startPageUrl}
            tcsUrl={tcs}
            useCustomCMP={useCustomCMP}
          />
        </Route>
      </Switch>
    </>
  );
});

export default CheckoutWorkflow;
