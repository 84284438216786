export enum CustomTrackingEvent {
  PAGE_VIEW = 'pageView',
  SEARCH_UPDATE = 'searchUpdate',
  ERROR_OCCURENCE = 'errorOccurrence',
  // CAMPIBE-161 Custom Link Tracking
  COMPARE_ADD = 'all_compare-add',
  COMPARE_OPEN = 'all_compare-open',
  COMPARE_BOOK = 'compare_book',
  LIST_PAGE_DETAILS = 'list_package-details',
  DETAIL_LAYER_OPEN = 'all_details-open',
  DETAIL_LAYER_ATTRIBUTES = 'detail-layer_attributes',
  DETAIL_LAYER_PICTURES = 'detail-layer_pictures',
  DETAIL_LAYER_SUPPLIER = 'detail-layer_supplier',
  DETAIL_LAYER_CONDITIONS = 'detail-layer_conditions'
}
