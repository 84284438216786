import { ApiPrice } from '@ibe/api';

export interface CamperInsuranceData {
  name: string;
  covidProtection: boolean;
  content: string;
  pdf: string;
  info: string;
  recommended: boolean;
  units: Array<CamperInsuranceUnit>;
}

export interface CamperInsuranceUnit {
  id: string;
  name: string;
  age: Age;
  idRangeFrom: string;
  idRangeTo: string;
  excess: boolean;
  inventoryCode: string;
  price: ApiPrice;
}

export enum Age {
  without = 'without',
  toSixtyFour = 'toSixtyFour',
  fromSixyFive = 'fromSixyFive'
}
