import { trackingService } from '../Config/config';
import { CustomTrackingEvent } from './CustomTrackingEvent';
import {
  routeBookingEvent,
  searchDetailEvent,
  errorEvent,
  compareAddEvent,
  compareOpenEvent,
  compareBookEvent,
  listPackageDetailEvent,
  detailsOpenEvent,
  detailsAttributesEvent,
  detailsImagesEvent,
  detailsSupplierEvent,
  detailsConditionsEvent,
  LinkTrackingEvent
} from '@ibe/services';

const linkTrackingCustomEvent = (payload: LinkTrackingEvent): void => {
  document.dispatchEvent(
    new CustomEvent('linkTracking', {
      detail: {
        link: {
          id: payload.linkid,
          type: payload.linktype
        }
      }
    })
  );
};

const trackingSubscriptions = (): void => {
  routeBookingEvent.subscribe(payload =>
    trackingService.track(CustomTrackingEvent.PAGE_VIEW, payload)
  );
  searchDetailEvent.subscribe(payload =>
    trackingService.track(CustomTrackingEvent.SEARCH_UPDATE, payload)
  );
  errorEvent.subscribe(payload =>
    trackingService.track(CustomTrackingEvent.ERROR_OCCURENCE, payload)
  );

  compareAddEvent.subscribe((payload: LinkTrackingEvent) => linkTrackingCustomEvent(payload));
  compareOpenEvent.subscribe((payload: LinkTrackingEvent) => linkTrackingCustomEvent(payload));
  compareBookEvent.subscribe((payload: LinkTrackingEvent) => linkTrackingCustomEvent(payload));
  listPackageDetailEvent.subscribe((payload: LinkTrackingEvent) =>
    linkTrackingCustomEvent(payload)
  );
  detailsOpenEvent.subscribe((payload: LinkTrackingEvent) => linkTrackingCustomEvent(payload));
  detailsAttributesEvent.subscribe((payload: LinkTrackingEvent) =>
    linkTrackingCustomEvent(payload)
  );
  detailsImagesEvent.subscribe((payload: LinkTrackingEvent) => linkTrackingCustomEvent(payload));
  detailsSupplierEvent.subscribe((payload: LinkTrackingEvent) => linkTrackingCustomEvent(payload));
  detailsConditionsEvent.subscribe((payload: LinkTrackingEvent) =>
    linkTrackingCustomEvent(payload)
  );
};

export default trackingSubscriptions;
