import { FormConfig, useTranslation, InputElement, BaseFormElement } from '@ibe/components';
import { TFunction } from 'react-i18next';
import fallback from '../Translations/generated/customer.de.json';
import Keys from '../Translations/generated/customer.de.json.keys';
import useTravellerFormConfig from './useTravellerFormConfig';

export const getCustomerMockData = (): Record<string, string> => {
  return {
    gender: 'MALE',
    firstname: 'ISO',
    lastname: 'Test',
    birthDate: '1980-01-01',
    street: 'Zum Laurenburger Hof',
    houseNumber: '76',
    city: 'Frankfurt',
    zipCode: '60594',
    country: 'DE',
    phone: '0911995940',
    email: 'isojupiter@gmail.com',
    confirmEmail: 'isojupiter@gmail.com'
  };
};

const useFormConfig = (
  isNewInvoiceRecipient: boolean,
  minDriverAge: number,
  builderT: TFunction
): FormConfig => {
  const { t } = useTranslation('customer', fallback);

  return {
    rows: [
      {
        key: 'row1',
        elements: [
          {
            tag: 'select',
            key: 'customer',
            placeholder: t(Keys.payingCustomer),
            noMenuPortal: true,
            boldLabel: true,
            externalDataOptions: 'travellers',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              lg: 6
            }
          },
          ...(isNewInvoiceRecipient
            ? [
                {
                  key: 'external2',
                  tag: 'external',
                  childIndex: 1,
                  rowGrid: {
                    xs: 0,
                    lg: 6
                  }
                } as BaseFormElement,
                ...useTravellerFormConfig(
                  1,
                  minDriverAge,
                  builderT,
                  isNewInvoiceRecipient
                ).rows[0].elements.map((element, index) => {
                  return {
                    ...element,
                    infoText: index === 0 ? t(Keys.addressInfoText) : '',
                    infoTextRowGrid: { lg: 6 }
                  };
                })
              ]
            : [
                {
                  key: 'external2',
                  tag: 'external',
                  childIndex: 1,
                  rowGrid: {
                    xs: 0,
                    lg: 6
                  }
                } as BaseFormElement
              ]),
          {
            tag: 'input',
            key: 'street',
            placeholder: t(Keys.street),
            initialValue: '',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              lg: 4
            }
          },
          {
            tag: 'input',
            key: 'houseNumber',
            placeholder: t(Keys.houseNumber),
            initialValue: '',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              lg: 2
            },
            infoText: isNewInvoiceRecipient ? '' : t(Keys.addressInfoText),
            infoTextRowGrid: {
              lg: 6
            }
          },
          ...(isNewInvoiceRecipient
            ? [
                {
                  key: 'external3',
                  tag: 'external',
                  childIndex: 2,
                  rowGrid: {
                    xs: 0,
                    lg: 6
                  }
                } as BaseFormElement
              ]
            : []),
          {
            tag: 'input',
            key: 'zipCode',
            placeholder: t(Keys.zipCode),
            initialValue: '',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              lg: 3
            }
          },
          {
            tag: 'input',
            key: 'city',
            placeholder: t(Keys.city),
            initialValue: '',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              lg: 3
            }
          },
          {
            key: 'external4',
            tag: 'external',
            childIndex: 3,
            rowGrid: {
              xs: 0,
              lg: 6
            }
          },
          {
            tag: 'select',
            key: 'country',
            placeholder: t(Keys.country),
            noMenuPortal: true,
            externalDataOptions: 'countries',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              lg: 6
            }
          },
          {
            key: 'external5',
            tag: 'external',
            childIndex: 4,
            rowGrid: {
              xs: 0,
              lg: 6
            }
          },
          {
            tag: 'input',
            key: 'email',
            placeholder: t(Keys.email),
            initialValue: '',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                },
                {
                  type: 'email',
                  customMessage: t(Keys.emailError)
                }
              ]
            },
            rowGrid: {
              lg: 3
            }
          },
          {
            tag: 'input',
            key: 'confirmEmail',
            placeholder: t(Keys.confirmEmail),
            initialValue: '',
            disablePaste: true,
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                },
                {
                  type: 'email',
                  customMessage: t(Keys.emailError)
                },
                {
                  type: 'equalEmails',
                  customMessage: t(Keys.confirmEmailError)
                }
              ]
            },
            rowGrid: {
              lg: 3
            },
            infoText: t(Keys.emailInfoText),
            infoTextRowGrid: {
              lg: 6
            }
          } as InputElement,
          {
            tag: 'input',
            key: 'phone',
            placeholder: t(Keys.phone),
            initialValue: '',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              lg: 6
            },
            infoText: t(Keys.phoneNumberInfoText),
            infoTextRowGrid: {
              lg: 6
            }
          }
        ]
      }
    ]
  };
};

export default useFormConfig;
