import { createTheme, CustomTheme } from '@ibe/components';
import { CSSObjectWithLabel, StylesConfig as ReactSelectStyles } from 'react-select';
import styles from './Bootstrap.scss';

const reactSelectStyles: ReactSelectStyles = {
  control: provided =>
    ({
      ...provided,
      boxShadow: 'none',
      border: '0',
      borderRadius: 0,
      borderBottom: '1px solid #ccc',
      '&:hover': {
        border: '0',
        borderRadius: 0,
        borderBottom: '1px solid #279641'
      }
    } as CSSObjectWithLabel),
  option: (provided, state) =>
    ({
      ...provided,
      cursor: state.isDisabled ? 'default' : 'pointer',
      backgroundColor: state.isSelected ? '#2f4673' : 'none',
      color: state.isSelected ? '#fff' : '#333333',
      fontWeight: state.isDisabled ? '700' : '400',
      '&:hover': {
        backgroundColor: state.isDisabled ? '#fff' : state.isSelected ? '#2f4673' : '#b9cff5'
      }
    } as CSSObjectWithLabel),
  menu: provided =>
    ({
      ...provided,
      boxShadow: '0 2px 4px 0 rgb(0 0 0 / 50%)',
      border: '',
      backgroundColor: '#fff',
      zIndex: 1000
    } as CSSObjectWithLabel),
  indicatorsContainer: provided =>
    ({
      ...provided,
      color: '#279641'
    } as CSSObjectWithLabel)
};

const customTheme: CustomTheme = {
  bootstrap: styles,
  reactSelectStyles
};

const theme = createTheme(customTheme);

export default theme;
