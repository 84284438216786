import { CmbConfigModel } from '../Config/CmbConfigModel';
import { fetchJSON } from '@ibe/services';

const loadExternalWidgetConfig = async (): Promise<Partial<CmbConfigModel> | undefined> => {
  const scriptElement = window?.document?.getElementById('cmb');
  if (scriptElement) {
    const src = scriptElement.getAttribute('src');
    if (src) {
      const configuredCmbConfigModel: Partial<CmbConfigModel> = {};
      let url: URL | string;
      let baseUrl: string;
      if (src.startsWith('http')) {
        url = new URL(src);
        baseUrl = url.origin;
      } else {
        url = src;
        baseUrl = '';
      }
      const baseString = typeof url === 'string' ? url : url.href;
      const baseUrlWithContext = baseString.includes('/camperboerse')
        ? baseString.substring(0, baseString.indexOf('/camperboerse'))
        : baseString.includes('/fallback')
        ? baseString.substring(0, baseString.indexOf('/fallback'))
        : baseString.substring(0, baseString.indexOf('/.resources'));

      configuredCmbConfigModel.baseUrl = baseUrlWithContext;
      const externalCmbConfig = (await fetchJSON(
        baseUrlWithContext + '/.rest/config/cmbConfig'
      )) as Partial<CmbConfigModel>;
      configuredCmbConfigModel.configUrl = baseUrl + externalCmbConfig.configUrl;
      if (externalCmbConfig.payoneUrl) {
        configuredCmbConfigModel.payoneUrl = externalCmbConfig.payoneUrl;
      }
      configuredCmbConfigModel.affiliateConsentManagerId =
        externalCmbConfig.affiliateConsentManagerId;
      configuredCmbConfigModel.consentManagerUrl = externalCmbConfig.consentManagerUrl;
      configuredCmbConfigModel.apiUrl = baseUrlWithContext + '/api';
      configuredCmbConfigModel.version = externalCmbConfig.version;
      return configuredCmbConfigModel;
    }
  }
  return undefined;
};

export default loadExternalWidgetConfig;
