import React, { MouseEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  CamperBreadcrumb,
  CamperModule,
  CamperSearchParams,
  CamperSearchResults,
  LoadingOverlay,
  Tenant,
  TravelAdviceService,
  useTranslation
} from '@ibe/components';
import { ApiCamper, ApiFilterType } from '@ibe/api';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';
import { LoggerFactory, SessionStorage } from '@ibe/services';
import { scroller } from 'react-scroll';
import PageUrl from '../Models/PageUrl';
import CheckoutStore from '../Pages/Checkout/CheckoutStore';
import ErrorDisplay from '../Components/ErrorDisplay';
import { WidgetType } from '../pages';
import CamperLoading from '../Components/CamperLoading';
import useCMPConsentContext from '../Pages/Checkout/hooks/useCMPConsent';
import CMPConsentLayer from '../Components/CMPConsentLayer';
import { getCmpServices, YOUTUBE_NOCOOKIE_HOST } from '../Config/config';
import useCmbConfig from '../Hooks/useCmbConfig';
import fallbackHomePage from '../Translations/generated/homePage.de.json';
import KeysHomePage from '../Translations/generated/homePage.de.json.keys';
import fallback from '../Translations/generated/cmp-consent-layer.de.json';
import Keys from '../Translations/generated/cmp-consent-layer.de.json.keys';
import useAssetPath from '../Hooks/useAssetPath';
import { camperSearchResultsKey, EXCLUDED_EXTRA_CODES, extraGroupsSorter } from '../globals';

const logger = LoggerFactory.get('HomePage');

interface Props {
  travelAdviceService: TravelAdviceService;
  store: CheckoutStore;
  widgetType?: WidgetType;
  rerouteUrl?: string;
  breadcrumb?: CamperBreadcrumb;
  searchParams?: Partial<CamperSearchParams>;
  useCustomCMP?: boolean;
  affiliateNumber?: string;
  disableResultListLazyLoading?: boolean;
}

const sessionStorage = new SessionStorage<CamperSearchResults>(camperSearchResultsKey);

const HomePage = observer(function HomePage(props: Props): JSX.Element {
  const {
    travelAdviceService,
    widgetType,
    rerouteUrl,
    breadcrumb,
    searchParams,
    useCustomCMP,
    affiliateNumber,
    disableResultListLazyLoading
  } = props;
  const { t: tHomePage } = useTranslation('homePage', fallbackHomePage);
  const { t } = useTranslation('cmp-consent-layer', fallback);

  const assetBasePath = useAssetPath();
  const cmbConfig = useCmbConfig();
  const { store } = props;
  const history = useHistory();
  const [camperSearchResults, setCamperSearchResults] = useState<CamperSearchResults | undefined>(
    undefined
  );
  const [galleryChangeTrigger, setGalleryChangeTrigger] = useState<boolean>(false);
  const toggleGalleryChangeTrigger = (): void => {
    setGalleryChangeTrigger(value => !value);
  };

  useEffect(() => {
    if (widgetType === 'IBE' || widgetType === 'CMB') {
      setCamperSearchResults(sessionStorage.get());
      sessionStorage.clear();
    }
  }, []);

  const cmpConsent = useCMPConsentContext();

  useEffect(() => {
    toggleGalleryChangeTrigger();
  }, [cmpConsent]);

  const scrollToError = (): void => {
    scroller.scrollTo(store.scrollElementGlobalError, {
      smooth: true,
      delay: 100,
      offset: -430
    });
  };

  useMount(async () => {
    await travelAdviceService.loadTravelAdvices();
  });

  const handleBook = (camper: ApiCamper): void => {
    store.error = null;
    store.setCamper(camper);
    store
      .attemptBooking()
      .then(() => {
        if (store.error) {
          scrollToError();
        } else if (widgetType === 'IBE' && rerouteUrl) {
          window.location.href = rerouteUrl;
        } else {
          history.push(PageUrl.CHECKOUT);
        }
      })
      .catch(err => {
        logger.error(err);
        store.resetBooking().then(() => {
          scrollToError();
        });
      })
      .finally(() => {
        store.setIsLoading(false);
      });
  };

  const googleMapsService = getCmpServices(useCustomCMP).find(
    service => service.name === 'Google Maps'
  );
  const youtubeService = getCmpServices(useCustomCMP).find(
    service => service.name === 'YouTube Video'
  );

  return (
    <div className={`module-container ${!widgetType ? 'container-fluid' : ''}`}>
      {widgetType === 'CMB' && !useCustomCMP && (
        <a
          className="affiliate-cmp-link"
          href="#"
          onClick={(e: MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            if (!!window.UC_UI) {
              window.UC_UI.showSecondLayer();
            }
            return false;
          }}
        >
          {t(Keys.cookieSettings)}
        </a>
      )}
      <ErrorDisplay store={store} />
      <LoadingOverlay
        isLoading={store.isLoading}
        positionFixed
        backgroundColor="#000"
        customSpinner={<CamperLoading />}
      >
        <CamperModule
          affiliateNumber={affiliateNumber}
          customSpinner={<CamperLoading />}
          onBook={handleBook}
          travelAdviceService={travelAdviceService}
          preventInitialSearch={!!camperSearchResults}
          camperSearchResults={camperSearchResults}
          breadcrumb={breadcrumb}
          searchParams={searchParams}
          tenant={store.tenant || undefined}
          galleryChangeTrigger={galleryChangeTrigger}
          MapReplacement={
            !!googleMapsService && (cmbConfig.isSDPLoaded || useCustomCMP) ? (
              <div className="position-relative py-3">
                <CMPConsentLayer
                  useCustomCMP={useCustomCMP}
                  serviceId={googleMapsService.id}
                  title={
                    useCustomCMP
                      ? t(Keys.customTitle, { service: googleMapsService.name })
                      : window.uc?.translations?.translations?.DEFAULT_TITLE?.replace(
                          '%TECHNOLOGY_NAME%',
                          googleMapsService.name
                        )
                  }
                  description={
                    useCustomCMP
                      ? t(Keys.customMapDescription)
                      : window.uc?.translations?.translations?.MAP_DESCRIPTION
                  }
                />
              </div>
            ) : (
              <></>
            )
          }
          VideoReplacement={
            !!youtubeService && (cmbConfig.isSDPLoaded || useCustomCMP) ? (
              <div className="position-relative video-wrapper py-3">
                <CMPConsentLayer
                  useCustomCMP={useCustomCMP}
                  serviceId={youtubeService.id}
                  title={
                    useCustomCMP
                      ? t(Keys.customTitle, { service: youtubeService.name })
                      : window.uc?.translations?.translations?.DEFAULT_TITLE?.replace(
                          '%TECHNOLOGY_NAME%',
                          youtubeService.name
                        )
                  }
                  description={
                    useCustomCMP
                      ? t(Keys.customVideoDescription)
                      : window.uc?.translations?.translations?.VIDEO_DESCRIPTION
                  }
                />
              </div>
            ) : (
              <></>
            )
          }
          // eslint-disable-next-line @typescript-eslint/dot-notation
          showMapReplacement={!cmpConsent[googleMapsService?.id || '']}
          // eslint-disable-next-line @typescript-eslint/dot-notation
          showVideoReplacement={!cmpConsent[youtubeService?.id || '']}
          youtubeOpts={{ host: YOUTUBE_NOCOOKIE_HOST }}
          extraGroupsExternalContent={{
            [store.tenant === Tenant.CB
              ? 'Rückerstattung der Selbstbeteiligung'
              : 'Refund of Deductible']: {
              groupImage: <img src={`${assetBasePath}/allianz-logo.png`} alt="Allianz" />,
              textContent: tHomePage(KeysHomePage.textContent),
              infoLayerContent: tHomePage(KeysHomePage.infoLayerContent),
              validationText: tHomePage(KeysHomePage.validationText),
              isRecommendation: true,
              excludesExtraCodes: [...EXCLUDED_EXTRA_CODES]
            }
          }}
          extraGroupsSorter={extraGroupsSorter}
          footerInfos={{
            identifierClass: 'footer',
            footerClass: 'padding-bottom'
          }}
          filterLabelTranslationMappings={{
            [ApiFilterType.MultipleSelectionFilter]: (label: string) =>
              !!tHomePage((KeysHomePage as Record<string, string>)[label])
                ? tHomePage((KeysHomePage as Record<string, string>)[label])
                : label
          }}
          hideSupplierComparisonCountryCodes={[]}
          disableResultListLazyLoading={disableResultListLazyLoading}
        />
      </LoadingOverlay>
    </div>
  );
});

export default HomePage;
