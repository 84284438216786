import React, { ChangeEvent, useRef } from 'react';
import { Col, CustomInput, Input, Label, Row } from 'reactstrap';
import { CaptureProps } from './types';
import { DatePicker, DatePickerRef, useConfig, useTranslation } from '@ibe/components';
import fallback from '../../../../Translations/generated/customer.de.json';
import Keys from '../../../../Translations/generated/customer.de.json.keys';
import TimePicker, { TimePickerValue } from 'react-time-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons/faCalendar';
import i18next from 'i18next';

export const CaptureDate = (props: CaptureProps): JSX.Element => {
  const {
    captureDataItem: { id, code },
    dispatch,
    value,
    isUnknown,
    labels,
    hasError,
    isFormDirty
  } = props;
  const { t } = useTranslation('customer', fallback);
  const localValue = value as Date | undefined;
  const datePickerRef = useRef<DatePickerRef>(null);

  const handleChange = (date: Date) => {
    dispatch({ type: 'DATE', payload: { id, code, value: date } });
  };

  const handleCheckbox = () => {
    dispatch({
      type: 'DATE',
      payload: { id, code, value: undefined, isUnknown: !isUnknown }
    });
  };

  return (
    <Row className="mb-3">
      <Col md={9}>
        <Label
          className={isFormDirty && hasError && !isUnknown ? 'form__error__top' : ''}
          htmlFor={id}
        >
          {labels[0]}
        </Label>
        <div className="capture-data__calendar">
          <DatePicker
            ref={datePickerRef}
            id={id}
            onChange={handleChange}
            date={!!localValue ? new Date(localValue) : undefined}
            disabled={isUnknown}
            dataTestId=""
          />
          <span
            className="capture-data__calendar__icon"
            onClick={(): void => datePickerRef.current?.openDatePicker()}
          >
            <FontAwesomeIcon icon={faCalendar} />
          </span>
        </div>
        {isFormDirty && hasError && !isUnknown && (
          <div className="form__error mt-1">{t(Keys.errorText)}</div>
        )}
      </Col>
      <Col md={3} className="capture-data__checkbox-column">
        <Label htmlFor={`${id}-checkbox`} className="d-flex align-items-center mb-0">
          <CustomInput
            id={`${id}-checkbox`}
            type="checkbox"
            checked={isUnknown}
            onChange={handleCheckbox}
          />
          <span>{t(Keys.unknown)}</span>
        </Label>
      </Col>
    </Row>
  );
};

export const CaptureTime = (props: CaptureProps): JSX.Element => {
  const {
    captureDataItem: { id, code },
    dispatch,
    value,
    isFormDirty,
    hasError,
    labels,
    remount
  } = props;
  const config = useConfig();
  const { t } = useTranslation('customer', fallback);
  const localValue = value as string | undefined;

  const handleChange = (time: TimePickerValue) => {
    dispatch({ type: 'TIME', payload: { id, code, value: time } });
  };

  return (
    <Row className="mb-3">
      <Col>
        <Label className={isFormDirty && hasError ? 'form__error__top' : ''}>{labels[0]}</Label>
        <TimePicker
          onChange={handleChange}
          value={localValue || ''}
          disableClock
          locale={i18next.language || config.defaultLanguage}
          onClockClose={remount}
        />
        {isFormDirty && hasError && <div className="form__error mt-1">{t(Keys.errorText)}</div>}
      </Col>
    </Row>
  );
};

export const CaptureFlightTime = (props: CaptureProps): JSX.Element => {
  const {
    captureDataItem: { id, code },
    dispatch,
    value,
    isUnknown,
    isFormDirty,
    hasError,
    labels,
    remount
  } = props;
  const config = useConfig();
  const { t } = useTranslation('customer', fallback);
  const localValue = value as [string, string] | undefined;

  const handleChange = (type: 'FLIGHT_NUMBER' | 'TIME', inputValue: string) => {
    dispatch({
      type: 'FLIGHT_TIME',
      payload: {
        id,
        code,
        value:
          type === 'FLIGHT_NUMBER'
            ? [
                inputValue?.length <= 6
                  ? inputValue
                  : !!localValue && !!localValue[0]
                  ? localValue[0]
                  : '',
                !!localValue && !!localValue[1] ? localValue[1] : ''
              ]
            : [!!localValue && !!localValue[0] ? localValue[0] : '', inputValue]
      }
    });
  };

  const handleCheckbox = (): void => {
    dispatch({
      type: 'FLIGHT_TIME',
      payload: {
        id,
        code,
        value: ['', ''],
        isUnknown: !isUnknown
      }
    });
  };

  return (
    <Row className="mb-3">
      <Col md={6}>
        <Label
          className={
            isFormDirty &&
            hasError &&
            (!localValue || (!!localValue && !localValue[0])) &&
            !isUnknown
              ? 'form__error__top'
              : ''
          }
          htmlFor={id}
        >
          {labels[0]}
        </Label>
        <Input
          id={id}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange('FLIGHT_NUMBER', e.target.value)
          }
          value={!!localValue && !!localValue[0] ? localValue[0] : ''}
          disabled={isUnknown}
        />
        {isFormDirty && hasError && !isUnknown && (
          <div className="form__error mt-1">{t(Keys.errorText)}</div>
        )}
      </Col>
      <Col md={3} className="mt-3 mt-md-0">
        <Label
          className={
            isFormDirty &&
            hasError &&
            (!localValue || (!!localValue && !localValue[1])) &&
            !isUnknown
              ? 'form__error__top'
              : ''
          }
        >
          {labels[1]}
        </Label>
        <TimePicker
          onChange={(time: TimePickerValue) => handleChange('TIME', time as string)}
          value={!!localValue && !!localValue[1] ? localValue[1] : ''}
          disabled={isUnknown}
          disableClock
          locale={i18next.language || config.defaultLanguage}
          onClockClose={remount}
        />
      </Col>
      <Col md={3} className="capture-data__checkbox-column">
        <Label htmlFor={`${id}-checkbox`} className="d-flex align-items-center mb-0">
          <CustomInput
            id={`${id}-checkbox`}
            type="checkbox"
            checked={isUnknown}
            onChange={handleCheckbox}
          />
          <span>{t(Keys.unknown)}</span>
        </Label>
      </Col>
    </Row>
  );
};
