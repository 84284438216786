import { LoggerFactory } from '@ibe/services';
import Page from './Models/Page';
import Home from './Pages/Home';
import PageUrl from './Models/PageUrl';
import Health from './Pages/Health';
import Checkout from './Pages/Checkout/Checkout';

const logger = LoggerFactory.get('pages');

export type WidgetType = 'IBE' | 'CHECKOUT' | 'CMB';

export type PagesType =
  | Record<PageUrl, Page>
  | Omit<Record<PageUrl, Page>, PageUrl.CHECKOUT>
  | Omit<Record<PageUrl, Page>, PageUrl.HOME>;

export const pages: PagesType = {
  [PageUrl.HEALTH]: {
    component: Health
  },
  [PageUrl.HOME]: {
    component: Home
  },
  [PageUrl.CHECKOUT]: {
    component: Checkout
  }
};

export const ibeWidgetPages: PagesType = {
  [PageUrl.HEALTH]: {
    component: Health
  },
  [PageUrl.HOME]: {
    component: Home
  }
};

export const checkoutWidgetPages: PagesType = {
  [PageUrl.HEALTH]: {
    component: Health
  },
  [PageUrl.HOME]: {
    component: Checkout
  }
};

export const getPages = (widgetType?: WidgetType): PagesType => {
  switch (widgetType) {
    case 'IBE':
      return ibeWidgetPages;
    case 'CHECKOUT':
      return checkoutWidgetPages;
    default:
      return pages;
  }
};

export const getPageByUrl = (url: PageUrl | string, widgetType?: WidgetType): Page => {
  const pagesType = getPages(widgetType) as Record<PageUrl, Page>;
  if (Object.prototype.hasOwnProperty.call(pagesType, url)) {
    return pagesType[url as PageUrl];
  }
  logger.error(`No page found for url ${url}`);
  return pagesType[PageUrl.HOME];
};
