import dayjs from 'dayjs';
import {
  ApiBooking,
  ApiBookingState,
  ApiCamper,
  ApiCamperExtra,
  ApiCamperRate,
  ApiFilterCategory,
  ApiItem,
  ApiItemType,
  ApiListOptions,
  ApiMultipleSelectionFilter,
  ApiNumberRangeFilter,
  ApiPackagePriceComponentType,
  ApiSingleSelectionFilter,
  ApiTraveler
} from '@ibe/api';
import {
  AppService,
  ErrorEvent,
  LoggerFactory,
  RouteBookingEvent,
  SearchDetailEvent
} from '@ibe/services';
import CheckoutPageUrl from '../Pages/Checkout/CheckoutPageUrl';
import { restoreSearchDetailsFromSessionStorage } from '@ibe/components';
import { TRAVELERS_ADULTS_MIN_AGE } from '../defaultCmbConfig';

declare global {
  interface Window {
    pageData: DataLayer;
  }
}

type TrackingEventDiscriminator = RouteBookingEvent | SearchDetailEvent | ErrorEvent;

/**
 * Handles all IBE Analytics and synchronization with the CMS DataLayer.
 *
 * @author Tobias Ziegler <tobias.ziegler@isotravel.com>
 * @author Jan Lengowski <jan.lengowski@isotravel.com>
 */
class TrackingService {
  private readonly logger = LoggerFactory.get('TrackingService.ts');

  private readonly AGENCY_NUMBER_KEY = 'affiliate-agency-number';

  constructor(private appService: AppService) {}

  track(event: string, payload: TrackingEventDiscriminator): void {
    this.dispatchEvent(event, this.mapAnalyticsData(payload));
  }

  private dispatchEvent(event: string, payload: Partial<DataLayer> | undefined): void {
    if (!payload) return;
    this.logger.log('Tracking Event:', event, payload);
    document.dispatchEvent(new CustomEvent(event, { detail: payload }));
  }

  private mapAnalyticsData(payload: TrackingEventDiscriminator): Partial<DataLayer> | undefined {
    if (payload instanceof RouteBookingEvent) {
      if (
        payload.booking &&
        (payload.pathname === CheckoutPageUrl.TRAVELLER ||
          payload.pathname === CheckoutPageUrl.CUSTOMER ||
          payload.pathname === CheckoutPageUrl.CONFIRMATION)
      ) {
        const data = {
          ...this.mapPageViewData(payload),
          ...this.mapOfferDataFromBooking(payload.booking, payload.isBookable)
        };
        return data;
      } else if (payload.details) {
        const {
          item,
          searchParams,
          listOptions,
          nrResultsAvailable,
          nrResultsOnRequest,
          nrResults
        } = payload.details;
        if (searchParams && listOptions) {
          const searchDetailEvent: SearchDetailEvent = {
            item,
            searchParams,
            listOptions,
            nrResultsAvailable,
            nrResultsOnRequest,
            nrResults
          };
          return {
            ...this.mapPageViewData(payload),
            ...this.mapSearchData(searchDetailEvent)
          };
        } else if (item) {
          return {
            ...this.mapPageViewData(payload),
            ...this.mapOfferData(item, payload.isBookable)
          };
        }
      }
      return this.mapPageViewData(payload);
    } else if (payload instanceof SearchDetailEvent) {
      return this.mapSearchData(payload);
    } else {
      return this.mapErrorOcurrenceData(payload);
    }
  }

  private mapPageViewData(
    data: RouteBookingEvent
  ): Pick<DataLayer, 'page' | 'checkout' | 'insurance' | 'order'> {
    return {
      ...this.mapPageData(data),
      ...this.mapCheckoutData(data),
      ...this.mapInsurance(data),
      ...this.mapOrder(data)
    };
  }

  private mapPageData(data: RouteBookingEvent): Pick<DataLayer, 'page'> {
    return {
      page: {
        name: this.mapPageName(data),
        pageType: this.mapPageType(data),
        ibe: 'camperboerse',
        language: this.appService.lang.substring(0, 2), // only "de" not "de-DE"
        currency: this.appService.currency,
        site: window.location.hostname,
        status: 200,
        agency: sessionStorage.getItem(this.AGENCY_NUMBER_KEY) || ''
      }
    };
  }

  private mapCheckoutData(data: RouteBookingEvent): Pick<DataLayer, 'checkout'> {
    const { booking } = data;

    return {
      checkout: {
        travellers: this.mapTravellers(data.booking),
        country: booking?.client?.address?.countryCode || '',
        city: booking?.client?.address?.city || '',
        postcode: booking?.client?.address?.postalCode || '',
        paymentMethod:
          booking?.payments && booking.payments.length
            ? booking.payments[0].paymentOption?.valueOf() || ''
            : ''
      }
    };
  }

  private mapInsurance(data: RouteBookingEvent): Pick<DataLayer, 'insurance'> {
    const insuranceItem = data.booking?.bookedItems.find(
      (bookedItem: ApiItem) => bookedItem.itemType === ApiItemType.INSURANCE
    );
    return { insurance: { name: insuranceItem?.name, price: insuranceItem?.price.finalPrice } };
  }

  private mapOrder(data: RouteBookingEvent): Pick<DataLayer, 'order'> | {} {
    const { booking } = data;
    if (booking?.bookingState === ApiBookingState.OPEN) return {};

    return {
      order: {
        id: booking?.bookingNumber,
        totalPurchaseValue: booking?.price.finalPrice,
        status: booking?.bookingState,
        currency: booking?.price.currencyCode
      }
    };
  }

  private mapPageType(data: RouteBookingEvent) {
    if (data.pathname === '/' && data.search.includes('productId')) {
      return 'detailpage';
    } else if (data.pathname === '/') {
      return 'searchlist';
    } else if (data.pathname === CheckoutPageUrl.TRAVELLER) {
      return 'checkout-travellers';
    } else if (data.pathname === CheckoutPageUrl.CUSTOMER) {
      return 'checkout-payment';
    } else if (data.pathname === CheckoutPageUrl.CONFIRMATION) {
      return 'checkout-confirmation';
    }

    return '';
  }

  private mapPageName(data: RouteBookingEvent) {
    if (data.pathname === '/' && data.search.includes('productId')) {
      return 'ibe:camper:detailpage';
    } else if (data.pathname === '/') {
      return 'ibe:camper:list';
    } else if (data.pathname === CheckoutPageUrl.TRAVELLER) {
      return 'ibe:camper:travellers';
    } else if (data.pathname === CheckoutPageUrl.CUSTOMER) {
      return 'ibe:camper:payment';
    } else if (data.pathname === CheckoutPageUrl.CONFIRMATION) {
      return 'ibe:camper:confirmation';
    }

    return '';
  }

  private mapTravellers(booking: ApiBooking | undefined) {
    return (
      booking?.travelers.reduce(
        (
          total: { adults: number[]; children: number[]; gender: string[] },
          current: ApiTraveler
        ) => {
          const birthday = dayjs(current.birthDate);
          const endDate = dayjs(booking?.travelEndDate);
          const gender = total.gender.includes(current.gender)
            ? [...total.gender]
            : [...total.gender, current.gender];
          if (endDate.diff(birthday, 'year') >= TRAVELERS_ADULTS_MIN_AGE) {
            return {
              ...total,
              adults: [...total.adults, endDate.diff(birthday, 'year')],
              gender
            };
          } else {
            return {
              ...total,
              children: [...total.children, endDate.diff(birthday, 'year')],
              gender
            };
          }
        },
        { adults: [], children: [], gender: [] }
      ) || { adults: [], children: [], gender: [] }
    );
  }

  private mapOfferDataFromBooking(
    booking: ApiBooking,
    isBookable?: boolean
  ): Pick<DataLayer, 'offer'> | {} {
    const camper = booking.items.find(
      (child: ApiItem) => child.idParent === null && child.itemType == ApiItemType.CAMPER
    );

    const camperItem = camper as ApiCamper;

    const selectedRate =
      camperItem?.rates?.find(rate => rate.isSelected) ||
      (camperItem?.rates ? camperItem.rates[0] : undefined);

    if (camper) {
      const data = this.mapOfferData(camper, isBookable);

      if (data.offer) {
        const { availablePackages, optionalExtras, payLocallyExtras, ...rest } = data.offer;
        return {
          offer: {
            ...rest,
            optionalExtras: this.mapOptionalExtrasIgnoreSelected(selectedRate),
            payLocallyExtras: this.mapPayLocallyExtrasIgnoreSelected(selectedRate)
          }
        };
      }
      return data;
    }
    return {};
  }

  private mapOfferData(item: ApiItem, isBookable?: boolean): Pick<DataLayer, 'offer'> {
    const camper = item as ApiCamper;

    const selectedRate =
      camper.rates?.find(rate => rate.isSelected) || (camper.rates ? camper.rates[0] : undefined);
    const vehicleType =
      camper.features?.find(feature => feature.group === 'vehicle_type')?.code || '';

    const { searchParams } = restoreSearchDetailsFromSessionStorage();

    return {
      offer: {
        name: camper.vehicle.description,
        category: 'camper',
        serviceId: camper.serviceId,
        vehicleCode: camper.vehicle.code,
        vehicleCategory: vehicleType,
        minDriverAge: camper.minDriverAge,
        attributes: this.mapAttributes(camper),
        supplier: camper.supplier.description,
        status: camper.status,
        price: selectedRate?.payableWithBookingPrice?.finalPrice || 0,
        pickup: {
          country: searchParams?.pickupStationParentGeoUnit?.name || '',
          city: camper.pickupStation.address?.city || '',
          name: camper.pickupStation.description || ''
        },
        dropoff: {
          country: searchParams?.dropOffStationParentGeoUnit?.name || '',
          city: camper.dropoffStation.address?.city || '',
          name: camper.dropoffStation.description || ''
        },
        returnSame: camper.pickupStation.code === camper.dropoffStation.code ? 'yes' : 'no',
        rentalPeriod: {
          from: { date: camper.pickupDate, time: '00:00' },
          to: { date: camper.dropoffDate, time: '00:00' },
          duration: this.mapDuration(camper.pickupDate, camper.dropoffDate) || 0,
          timeToDeparture:
            this.mapDuration(new Date().toISOString().split('T')[0], camper.pickupDate) || 0
        },
        package: this.mapPackage(selectedRate),
        includedSpecials: this.mapIncludedSpecials(selectedRate),
        includedExtras: this.mapIncludedExtras(selectedRate),
        mandatoryExtras: this.mapMandatoryExtras(selectedRate),
        optionalExtras: this.mapOptionalExtras(selectedRate),
        payLocallyExtras: this.mapPayLocallyExtras(selectedRate),
        availablePackages: this.mapRates(camper.rates || []),
        onlineBookable: isBookable
      }
    };
  }

  private mapAttributes(item: ApiCamper) {
    return (
      item.features?.map(feature => {
        return { category: feature.group || '', name: feature.description };
      }) || []
    );
  }

  mapRates(rates: ApiCamperRate[]) {
    return rates.map(rate => this.mapRate(rate));
  }

  mapRate(rate: ApiCamperRate | undefined) {
    return {
      code: rate?.code || '',
      name: rate?.name || '',
      price: rate?.packagePrice.price.finalPrice || 0
    };
  }

  mapPackage(rate: ApiCamperRate | undefined) {
    const supplement = rate?.packagePrice?.priceComponents?.find(
      pc => ApiPackagePriceComponentType.PACKAGESUPPLEMENT === pc.type
    );

    let packagePrice = rate?.packagePrice.price.basePrice || 0;
    if (supplement) {
      packagePrice = packagePrice + supplement.price;
    }

    return {
      code: rate?.code || '',
      name: rate?.name || '',
      price: packagePrice
    };
  }

  private mapIncludedSpecials(rate: ApiCamperRate | undefined) {
    if (!rate) return [];

    const specials = rate.specialOfferGroups
      ?.flatMap(offerGroup => offerGroup.offers)
      .filter(offer => offer.isSelected);
    return specials?.map(special => {
      return { code: special.code, name: special.name };
    });
  }

  private mapIncludedExtras(rate: ApiCamperRate | undefined) {
    if (!rate) return [];

    const extras = rate.extras?.filter(extra => extra.included);
    return this.mapExtras(extras || []);
  }

  private mapMandatoryExtras(rate: ApiCamperRate | undefined) {
    if (!rate) return [];

    const extras = rate.extras?.filter(extra => extra.mandatory && !extra.included);
    return this.mapExtras(extras || []);
  }

  private mapOptionalExtras(rate: ApiCamperRate | undefined) {
    if (!rate) return [];

    const extras = rate.extras?.filter(
      extra =>
        !extra.mandatory &&
        extra.selectedCount &&
        extra.selectedCount > 0 &&
        !extra.payableLocally &&
        !extra.included
    );
    return this.mapExtras(extras || []);
  }

  private mapOptionalExtrasIgnoreSelected(rate: ApiCamperRate | undefined) {
    if (!rate) return [];

    const extras = rate.extras?.filter(
      extra => !extra.mandatory && !extra.payableLocally && !extra.included
    );
    return this.mapExtras(extras || []);
  }

  private mapPayLocallyExtras(rate: ApiCamperRate | undefined) {
    if (!rate) return [];

    const extras = rate.extras?.filter(
      extra =>
        !extra.mandatory &&
        extra.selectedCount &&
        extra.selectedCount > 0 &&
        extra.payableLocally &&
        !extra.included
    );
    return this.mapExtras(extras || []);
  }

  private mapPayLocallyExtrasIgnoreSelected(rate: ApiCamperRate | undefined) {
    if (!rate) return [];

    const extras = rate.extras?.filter(
      extra => !extra.mandatory && extra.payableLocally && !extra.included
    );
    return this.mapExtras(extras || []);
  }

  private mapExtras(extras: ApiCamperExtra[]): AnalyticsExtra[] {
    return extras.map(extra => {
      return {
        code: extra.code,
        name: extra.name,
        serviceId: extra.serviceId,
        price: extra.convertedPrice?.finalPrice || extra.price?.finalPrice,
        payLocally: extra.payableLocally ? 'yes' : 'no',
        mandatory: extra.mandatory ? 'yes' : 'no'
      };
    });
  }

  private mapSearchData(data: SearchDetailEvent): Pick<DataLayer, 'search'> {
    const { listOptions, searchParams } = data;
    const { nrResultsAvailable, nrResultsOnRequest, nrResults } = data;

    const availabilityFilter = listOptions.filter.find(
      filter => filter.category === ApiFilterCategory.SERVICEOPTIONS
    ) as ApiMultipleSelectionFilter;

    return {
      search: {
        list: {
          nrResultsAvailable: nrResultsAvailable || 0,
          nrResultsOnRequest: nrResultsOnRequest || 0,
          nrResults: nrResults || 0,
          sortBy: `${listOptions.sorting.sortOption}_${listOptions.sorting.sortDirection}`,
          pagination: listOptions.pagination.currentPage
        },
        pickup: {
          country: searchParams.pickupStationParentGeoUnit?.name || '',
          city: searchParams.pickupStationGeoUnit?.name || ''
        },
        dropoff: {
          country: searchParams.dropOffStationParentGeoUnit?.name || '',
          city: searchParams.dropOffStationGeoUnit?.name || ''
        },
        returnSame:
          searchParams.pickupStationGeoUnit?.name === searchParams.dropOffStationGeoUnit?.name
            ? 'yes'
            : 'no',
        rentalPeriod: {
          from: { date: searchParams.startDate || '', time: '00:00' },
          to: { date: searchParams.endDate || '', time: '00:00' },
          duration: this.mapDuration(searchParams.startDate, searchParams.endDate) || 0,
          timeToDeparture: this.mapDuration(dayjs().toISOString(), searchParams.startDate) || 0
        },
        vehicleCategory: this.mapVehicleCategory(listOptions),
        supplier: this.mapSupplier(listOptions),
        equipment: this.mapEquipment(listOptions),
        nrBeds: this.mapNumberOfBeds(listOptions),
        priceRange: this.mapPriceRange(listOptions),
        travellers: { adults: 2, children: 0 },
        availableOnly: availabilityFilter?.selectedOptions ? 'yes' : 'no'
      }
    };
  }

  private mapDuration(startDate: string | undefined, endDate: string | undefined) {
    if (!startDate || !endDate) return;
    return Math.abs(dayjs(startDate).diff(dayjs(endDate), 'day'));
  }

  private mapVehicleCategory(listOptions: ApiListOptions) {
    const categoryFilter = listOptions.filter.find(
      filter => filter.category === ApiFilterCategory.CATEGORY
    ) as ApiMultipleSelectionFilter;
    return categoryFilter?.selectedOptions
      ? categoryFilter.selectedOptions[0].label || 'all'
      : 'all';
  }

  private mapSupplier(listOptions: ApiListOptions) {
    const supplierFilter = listOptions.filter.find(
      filter => filter.category === ApiFilterCategory.SUPPLIER
    ) as ApiMultipleSelectionFilter;
    return supplierFilter?.selectedOptions
      ? supplierFilter.selectedOptions[0].label || 'all'
      : 'all';
  }

  private mapEquipment(listOptions: ApiListOptions) {
    const equipment = listOptions.filter.find(
      filter => filter.category === ApiFilterCategory.CHARACTERISTICS
    ) as ApiMultipleSelectionFilter;
    const category = listOptions.filter.find(
      filter => filter.category === ApiFilterCategory.SERVICETYPE
    ) as ApiMultipleSelectionFilter;
    const equipped = equipment?.selectedOptions?.map(option => option.label) || [];
    const types = category?.selectedOptions?.map(option => option.label) || [];
    const filters = [...equipped, ...types];
    return filters.length ? filters : ['all'];
  }

  private mapNumberOfBeds(listOptions: ApiListOptions) {
    const bedsFilter = listOptions.filter.find(
      filter => filter.category === ApiFilterCategory.BEDSCOUNT
    ) as ApiSingleSelectionFilter;
    return bedsFilter?.options ? Number(bedsFilter.options[0].value) || 0 : 0;
  }

  private mapPriceRange(listOptions: ApiListOptions) {
    const priceFilter = listOptions.filter.find(
      filter => filter.category === ApiFilterCategory.PRICERANGE
    ) as ApiNumberRangeFilter;
    return {
      min: priceFilter?.minSelected || priceFilter?.min,
      max: priceFilter?.maxSelected || priceFilter?.max
    };
  }

  private mapErrorOcurrenceData(payload: ErrorEvent): Pick<DataLayer, 'error'> {
    return { error: { name: payload.message, id: payload.id } };
  }
}

export default TrackingService;
