import React from 'react';
import { CamperBreadcrumb, CamperSearchParams, TravelAdviceService, useApi } from '@ibe/components';
import { observer, useLocalStore } from 'mobx-react';
import BlankPageLayout from '../Layouts/BlankPageLayout';
import CheckoutStore from './Checkout/CheckoutStore';
import HomePage from '../Modules/HomePage';
import { WidgetType } from '../pages';

const Home = observer(
  (props: {
    store: CheckoutStore;
    widgetType?: WidgetType;
    rerouteUrl?: string;
    breadcrumb?: CamperBreadcrumb;
    searchParams?: Partial<CamperSearchParams>;
    useCustomCMP?: boolean;
    affiliateNumber?: string;
    disableResultListLazyLoading?: boolean;
  }): JSX.Element => {
    const {
      store,
      widgetType,
      rerouteUrl,
      breadcrumb,
      searchParams,
      useCustomCMP,
      affiliateNumber,
      disableResultListLazyLoading
    } = props;
    const api = useApi();
    const travelAdviceService = useLocalStore(() => new TravelAdviceService(api));

    return (
      <BlankPageLayout>
        <HomePage
          travelAdviceService={travelAdviceService}
          store={store}
          widgetType={widgetType}
          rerouteUrl={rerouteUrl}
          breadcrumb={breadcrumb}
          searchParams={searchParams}
          useCustomCMP={useCustomCMP}
          affiliateNumber={affiliateNumber}
          disableResultListLazyLoading={disableResultListLazyLoading}
        />
      </BlankPageLayout>
    );
  }
);

export default Home;
